<template>
  <div class="about text-white mb-80 leading-loose tracking-normal">
    <div>
      <p class="text-center text-5xl">The NFT Liquidity Protoocl</p>
    </div>
    <div class="mt-10 m-0 m-auto md:w-2/4 w-3/4">
      <Trade />
    </div>

    <div class="mt-32">
      <div class="text-5xl text-center mb-32">An evolving NFT platform</div>
      <div class="flex justify-between space-x-24">
        <div
          class="
            w-1/3
            bg-black
            rounded-lg
            px-8
            py-8
            space-y-8
            flex flex-col
            items-center
            text-center
          "
        >
          <div class="text-2xl">NFT Farms</div>
          <div>
            Farm xMUSE with your NFTs, lorema adasd ad adadad ada dad ad ad
          </div>
          <router-link
            to="/farm"
            class="
              w-full
              text-center
              uppercase
              font-primaryB font-bold
              leading-none
              text-xl
              inline-block
              py-4
              px-2
              text-brown
              hover:text-white
              bg-primaryB
              rounded-full
              hover:bg-blue-400 hover:bg-opacity-70
              transition
              duration-0
              focus:outline-none
            "
          >
            Farm
          </router-link>
        </div>
        <div
          class="
            w-1/3
            bg-black
            rounded-lg
            px-8
            py-8
            space-y-8
            flex flex-col
            items-center
            text-center
          "
        >
          <div class="text-2xl">Buy & Sell NFTs</div>
          <div>
            Farm xMUSE with your NFTs, lorema adasd ad adadad ada dad ad ad
          </div>
          <button
            target="_blank"
            class="
              w-full
              text-center
              uppercase
              font-primaryB font-bold
              leading-none
              text-xl
              inline-block
              py-4
              px-2
              text-brown
              hover:text-white
              bg-primaryB
              rounded-full
              hover:bg-blue-400 hover:bg-opacity-70
              transition
              duration-0
              focus:outline-none
            "
          >
            Farm
          </button>
        </div>
        <div
          class="
            w-1/3
            bg-black
            rounded-lg
            px-8
            py-8
            space-y-8
            flex flex-col
            items-center
            text-center
          "
        >
          <div class="text-2xl">Swap NFTs</div>
          <div>
            Farm xMUSE with your NFTs, lorema adasd ad adadad ada dad ad ad
          </div>
          <button
            target="_blank"
            class="
              w-full
              text-center
              uppercase
              font-primaryB font-bold
              leading-none
              text-xl
              inline-block
              py-4
              px-2
              text-brown
              hover:text-white
              bg-primaryB
              rounded-full
              hover:bg-blue-400 hover:bg-opacity-70
              transition
              duration-0
              focus:outline-none
            "
          >
            Farm
          </button>
        </div>
      </div>
    </div>

    <div class="my-36 flex justify-center">
      <a
        href="https://docs.nft20.io"
        class="
          w-1/3
          text-center
          uppercase
          font-primaryB font-bold
          leading-none
          text-xl
          inline-block
          py-4
          px-2
          text-brown
          hover:text-white
          bg-primaryB
          rounded-lg
          hover:bg-blue-400 hover:bg-opacity-70
          transition
          duration-0
          focus:outline-none
        "
        ß
      >
        Developer Docs
      </a>
    </div>

    <div
      class="
        flex flex-col
        md:flex-row
        space-y-16
        md:space-y-0
        text-center
        tracking-normal
        justify-center
        md:justify-between
        px-16
      "
    >
      <div class="space-y-3">
        <div class="items-center">
          <span class="text-6xl font-medium">{{ totalNfts }}</span
          ><span class="text-5xl text-gray-500 font-medium ml-1">+</span>
        </div>
        <div class="text-base">Total NFTs locked</div>
      </div>
      <div class="space-y-3">
        <div class="items-center">
          <span class="text-6xl font-medium">{{ totalPools }}</span
          ><span class="text-5xl text-gray-500 font-medium ml-1">+</span>
        </div>
        <div class="text-base">Total pools</div>
      </div>
      <div class="space-y-3">
        <div class="items-center">
          <span class="text-6xl font-medium">5K</span
          ><span class="text-5xl text-gray-500 font-medium ml-1">+</span>
        </div>
        <div class="text-base">NFTs exchanged this week</div>
      </div>
    </div>

    <div class="text-xl font-light text-left md:text-center my-36">
      <p class="mt-8">
        Join the
        <a
          href="https://discord.gg/kfByfux"
          target="_blank"
          class="text-primaryB"
          >community</a
        >
        and help us find use cases and bring liquidity to NFTs.
      </p>
    </div>
  </div>
</template>
<script>
import BigNumber from "bignumber.js";

import Web3EthAbi from "web3-eth-abi";
import axios from "axios";

import Trade from "@/components/cas2/Trade";
export default {
  name: "Cas",
  components: { Trade },
  data: function () {
    return {
      totalPools: 0,
      totalNfts: 0,
    };
  },
  beforeMount: async function () {},
  methods: {
    getInfo: async function () {
      const pools = await axios.get(
        "https://api.nft20.io/pools?perPage=1000&page=1"
      );

      const nfts = await axios.get(
        "https://api.nft20.io/nfts?perPage=50&page=1&"
      );
      this.totalPools = pools.data.pagination.total;
      this.totalNfts = nfts.data.pagination.total;
    },
  },
  computed: {},
  mounted: async function () {
    this.getInfo();
  },
};
</script>


<style scoped>
p {
  padding: 0.6rem 0rem;
}

/* video {
  -moz-box-shadow: 0 -22px 22px -22px rgba(0, 0, 0, 0.8) inset;
  -webkit-box-shadow: 0 -22px 22px -22px rgba(0, 0, 0, 0.8) inset;
  box-shadow: 0 -100px 100px -100px rgba(0, 0, 0, 0.8) inset;
} */
</style>