<template>
  <fieldset
    style="border-color: #b2aea8"
    class="border rounded-2xl pt-4 pb-3 px-5"
  >
    <label class="block uppercase text-lg mb-6">
      <span v-if="isMain"> from</span>
      <span v-else> to</span>
      <!-- <span class="float-right" v-if="isMain"
        >Balance: 0,31 MEME LTD</span> -->
      </label
    >
    <div class="flex justify-between items-center">
      <div class="w-auto flex justify-start items-center cursor-pointer text-gray-400" @click="$emit('open')">
        <input
          class="cursor pointer text-3xl md:text-45px w-full text-jet font-light bg-transparent border-0 focus:outline-none"
          type="text"
          placeholder="0"
          :value="`${selectedNfts}`"
          disabled
        />

        <!-- NFTs -->
      </div>
      <div class="w-auto flex">
        <div class="dropdown py-0 ml-auto inline-block relative">
          <button
            class="flex w-full items-center focus:outline-none rounded-lg bg-gray-900 bg-opacity-0 hover:bg-opacity-25 p-3"
            @click="$emit('open')"
          >
            <div class="flex items-center cursor-pointer">
              <i
                style=""
                class="rounded-full h-10 w-10 mr-3 flex items-center justify-center shadow-sm"
              >
                <img
                  class="rounded-full object-cover"
                  :src="project.logo"
                  alt=""
                />
              </i>
              </i>
              <span class="uppercase text-sm md:text-xl text-left font-bold">{{
                project.name
              }} <span class="font-light">NFTs</span></span>
            </div>
            <svg
              class="h-2 ml-1"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 12.814 6.907"
              enable-background="new 0 0 12.814 6.907"
              xml:space="preserve"
            >
              <path
                fill="#231F20"
                d="M0.146,0.854c-0.195-0.195-0.195-0.512,0-0.707s0.512-0.195,0.707,0L5.907,5.2l0.499,0.464l0.5-0.464 l5.055-5.054c0.195-0.195,0.512-0.195,0.707,0s0.195,0.512,0,0.707L6.762,6.761C6.715,6.807,6.658,6.844,6.598,6.868 C6.537,6.894,6.473,6.907,6.406,6.907c-0.064,0-0.129-0.014-0.189-0.039C6.154,6.844,6.1,6.807,6.053,6.761L0.146,0.854"
              />
            </svg>
          </button>
        </div>
      </div>
    </div>
  </fieldset>
</template>

<script>
export default {
  name: "NftField",
  props: { isMain: Boolean, swap: Boolean },
  data: function () {
    return {};
  },
  computed: {
    project: function () {
      return this.$store.state.currentProject;
    },
    selectedNfts: function () {
      if (
        this.$store.state.selectedNfts[0] &&
        this.$store.state.selectedNfts[0].length > 0
      ) {
        if (this.project.type == 721) {
          if (this.swap) {
            return 1;

            // return this.$store.state.selectedNfts.length;
          } else if (!this.swap) {
            return this.$store.state.selectedNfts[0].length;
          }
        } else {
          const nftAmount = this.$store.state.selectedNfts[1];
          let nftTotalAmount = 0;

          for (const element of nftAmount) {
            nftTotalAmount += element;
          }

          return nftTotalAmount;
        }
      } else {
        return 0;
      }
    },
  },
  beforeMount: async function () {},
};
</script>
