<template>
  <div class="about text-white mb-80 leading-loose tracking-widest">
    <div class="flex flex-col space-y-10">
      <h1 class="text-3xl font-semibold w-full text-center">
        NFT20 Leaderboard
      </h1>
      <h2 class="text-center text-xl w-8/12 mx-auto"></h2>
    </div>

    <vue-good-table
      v-if="leaderboard"
      :columns="columns"
      :rows="leaderboard"
      :sort-options="{
        enabled: true,
        initialSortBy: { field: 'count', type: 'desc' },
      }"
      :pagination-options="{
        enabled: true,
        mode: 'records',
        perPage: 50,
        position: 'top',
        perPageDropdown: [50, 100],
        dropdownAllowAll: false,
        nextLabel: 'next',
        prevLabel: 'prev',
        rowsPerPageLabel: 'Rows per page',
        ofLabel: 'of',
        pageLabel: 'page', // for 'pages' mode
        allLabel: 'All',
        infoFn: (params) => `my own page ${params.firstRecordOnPage}`,
      }"
    />
  </div>
</template>
<script>
import axios from "axios";
import "vue-good-table/dist/vue-good-table.css";
import { VueGoodTable } from "vue-good-table";

export default {
  name: "Leaderboard",
  components: { VueGoodTable },
  data: function () {
    return {
      leaderboard: null,
      columns: [
        { field: "user", label: "user" },
        { field: "count", label: "Trades", type: "number" },
        { field: "volume_eth", label: "ETH traded", type: "number" },
        { field: "nft_traded", label: "NFT traded", type: "number" },
        {
          field: "pools_interacted_with",
          label: "Pools traded",
          type: "number",
        },
      ],
    };
  },
  beforeMount: async function () {},
  methods: {},
  computed: {},
  mounted: async function () {
    let { data } = await axios.get("https://api.nft20.io/user/leaderboard");
    let leaderboard = [];
    for (const row of data) {
      let item = row;
      item.count = parseInt(item.count);
      item.count = parseInt(item.count);
      item.volume_eth = parseFloat(item.volume_eth);
      item.nft_traded = parseInt(item.nft_traded);
      item.pools_interacted_with = parseInt(item.pools_interacted_with);

      leaderboard.push(item);
    }
    this.leaderboard = leaderboard;

    console.log(this.leaderboard);
  },
};
</script>


<style scoped>
p {
  padding: 0.6rem 0rem;
}

/* video {
  -moz-box-shadow: 0 -22px 22px -22px rgba(0, 0, 0, 0.8) inset;
  -webkit-box-shadow: 0 -22px 22px -22px rgba(0, 0, 0, 0.8) inset;
  box-shadow: 0 -100px 100px -100px rgba(0, 0, 0, 0.8) inset;
} */
</style>