<template>
  <div class="about text-white mb-80 leading-loose tracking-normal">
    <div>
      <h2 class="mt-2 mb-4 text-2xl flex items-center justify-center">
        <span>Buy & Sell Your NFTs with 1-click</span>
        <span class="ml-2 text-4xl">👇</span>
      </h2>
      <!-- <p class="text-center text-2xl ml-4">Buy and Sell NFTs with 1 click</p> -->
    </div>
    <div
      class="
        mt-10
        flex
        md:flex-row
        flex-col
        justify-center
        md:justify-between
        items-center
        md:space-x-24
      "
    >
      <div class="mt-10 m-0 m-auto md:w-2/4 w-3/4">
        <Trade />
      </div>
    </div>

    <!-- <div
      class="text-center text-black mt-24 bg-white rounded-lg tracking-normal leading-6 p-8 space-y-8 mx-8"
    >
      <div class="text-xl font-bold">DISCLAIMER:</div>
      <div class="">
        The final price of the TX might cost a bit less than quoted and you'll
        get the change in ETH.
      </div>
      <div class="font-bold">This is in BETA so use with caution</div>
    </div> -->
  </div>
</template>
<script>
import BigNumber from "bignumber.js";

import Web3EthAbi from "web3-eth-abi";

import Trade from "@/components/cas2/Trade";
export default {
  name: "Cas",
  components: { Trade },
  data: function () {
    return {};
  },
  beforeMount: async function () {},
  methods: {},
  computed: {},
  mounted: async function () {},
};
</script>


<style scoped>
p {
  padding: 0.6rem 0rem;
}

/* video {
  -moz-box-shadow: 0 -22px 22px -22px rgba(0, 0, 0, 0.8) inset;
  -webkit-box-shadow: 0 -22px 22px -22px rgba(0, 0, 0, 0.8) inset;
  box-shadow: 0 -100px 100px -100px rgba(0, 0, 0, 0.8) inset;
} */
</style>