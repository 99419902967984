<template>
  <section class="max-w-screen-lg px-0 py-24">
    <h2></h2>
    <h2
      class="flex text-white items-center uppercase text-3xl font-bold mb-16 px-6 md:px-0"
    >
      <i
        class="bg-blue-500 rounded-full bg-white h-16 w-16 mr-4 flex items-center justify-center shadow-sm"
      >
        <img
          class="rounded-full object-cover"
          v-if="project.logo"
          :src="project.logo"
          alt
        />
      </i>
      <span>Auctions</span>
    </h2>

    <div>
      <div v-if="auctions.length < 1" class="text-2xl text-center text-white">
        No auctions found
      </div>
      <ul
        v-else
        class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8 px-12 md:px-0"
      >
        <li v-for="(auction, index) in auctions" :key="index">
          <AuctionCard :id="parseInt(auction.id)" :img="auction.img" />
        </li>
      </ul>
    </div>
  </section>
</template>

<script>
import AuctionCard from "@/components/AuctionCard";
import axios from "axios";
export default {
  components: { AuctionCard },
  name: "Auctions",
  props: {},
  data: function () {
    return {
      auctions: [],
      listSize: 0,
      pairAddress: null,
    };
  },
  methods: {
    fetchAuctions: async function () {
      if (this.$store.state.web3 == null && this.pairAddress == null) {
        return;
      }
      this.pairAddress = this.$store.state.currentProject.address;
      this.listSize = await this.$store.state.Auction.methods
        .auctionsAmountByPair(this.$store.state.currentProject.address)
        .call();

      let auctionIds = [];
      for (let index = 0; index < this.listSize; index++) {
        // console.log("Aucton INDEX", index, this.$store.state.Auction.methods);
        let id = await this.$store.state.Auction.methods
          .auctionOfPairByIndex(this.pairAddress, index)
          .call();

        // this is to avoid the contract bug not deleting from uniset
        try {
          await this.$store.state.Auction.methods
            .getAuctionByAuctionId(id)
            .call();

          auctionIds.push({
            id: id,
            img: null,
          });
        } catch (e) {
          console.log("bug known");
        }
      }

      let query = "";

      for (const auction of auctionIds) {
        const auctionInfo = await this.$store.state.Auction.methods
          .getAuctionByAuctionId(auction.id)
          .call();
        query += `token_ids=${auctionInfo._tokenId}&`;
      }

      const url = `https://api.opensea.io/api/v1/assets?${query}&asset_contract_address=${this.$store.state.currentProject.nftAddress}&order_direction=desc&offset=0&limit=20`;
      let { data } = await axios.get(url,
          {
            headers: {
              "X-API-KEY": "24f66ac0d61f425291793bc376c54ad8",
            },
          });

      for (const asset of data.assets) {
        for (const auction of auctionIds) {
          let auctionInfo = await this.$store.state.Auction.methods
            .getAuctionByAuctionId(auction.id)
            .call();
          if (auctionInfo._tokenId == asset.token_id) {
            auction.img = asset.image_url;
          }
        }
      }

      // get auction images

      this.auctions = auctionIds;
    },
  },
  computed: {
    project: function () {
      return this.$store.state.currentProject;
    },
    isConnected: function () {
      return this.$store.state.isLoaded;
    },
  },
  watch: {
    isConnected: function (a) {
      if (a) {
        this.fetchAuctions();
      }
    },
  },
  // beforeMount: async function () {
  //   this.fetchAuctions();
  //   // this.auctions = this.$store.state.auctions;
  // },
  mounted: async function () {
    this.fetchAuctions();
    // this.auctions = this.$store.state.auctions;
  },
};
</script>