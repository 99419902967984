<template>
  <div
    v-if="show"
    class="my-10 text-gray-400 md:flex items-center hidden justify-center"
  >
    <div
      class="
        flex
        justify-center
        items-center
        space-x-6
        rounded-lg
        bg-gray-800
        py-4
        px-4
        opacity-100
        w-2/3
      "
    >
      <div class="font-normal" v-if="!showResult">
        <span class="font-bold">PEPESCORE™ -</span> How do you feel about
        {{ project.name }}?
      </div>
      <div class="font-normal" v-if="showResult">
        <span class="font-bold"
          >Thank you for voting! the PEPESCORE™ for {{ project.name }} will show
          tomorrow.</span
        >
      </div>
      <div class="flex space-x-4 items-center" v-if="!showResult">
        <div
          class="hover:opacity-50 opacity-75 cursor-pointer h-12"
          @click="vote(false)"
        >
          <img
            src="@/assets/images/pepe-unhappy3.gif"
            alt=""
            class="object-cover h-12"
          />
        </div>
        <div class="text-sm">OR</div>
        <div
          class="hover:opacity-50 opacity-75 cursor-pointer h-12"
          @click="vote(true)"
        >
          <img
            src="@/assets/images/pepe-happy4.gif"
            alt=""
            class="object-cover h-12"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
var Personal = require("web3-eth-personal");
import axios from "axios";

export default {
  name: "PepeScore",
  components: {},
  props: {
    show: Boolean,
    project: Object,
  },
  data: function () {
    return {
      showResult: false,
    };
  },
  methods: {
    vote: async function (isHappy) {
      if (this.$store.state.account == null) {
        this.$store.dispatch("connectWallet", this);
        return;
      }
      const account = this.$store.state.account;
      var personal = new Personal(Personal.givenProvider);
      const sign = await personal.sign(
        web3.fromUtf8(account + this.project.address + this.project.nftAddress),
        web3.eth.coinbase,
        async (obj, signature) => {
          try {
            let update = await axios.post(
              `https://api.nft20.io/pepeswantstovote`,
              {
                address: account,
                isHappy: isHappy,
                pool: this.project.address,
                nft_address: this.project.nftAddress,
                sig: signature,
                timestamp: Math.floor(Date.now() / 1000),
              }
            );
            console.log(update)
          } catch (e) {
            console.log(e);
          }
        }
      );

      //   console.log(update);
      this.showResult = true;
    },
  },
  beforeMount: function () {},
  watch: {},
  computed: {},
};
</script>