<template>
  <main class="main-container">

  </main>
</template>

<script>
// @ is an alias to /src


import BigNumber from "bignumber.js";
import NFT20Abi from "@/assets/abis/Pair";

import Web3Utils from "web3-utils";

export default {
  name: "Home",
  components: {},
  data: function() {
    return {
      address: null,
      nftId: null,
      project: {}
    };
  },
  methods: {
    initialize: async function() {
      this.address = this.$route.params.pair;
      this.id = this.$route.params.nftId;
      for (const project of this.$store.state.pairs) {
        if (this.address.toLowerCase() == project.address.toLowerCase()) {
          this.project = project;
        }
      }
    }
  },
  mounted: async function() {},
  beforeMount: async function() {
    this.initialize();
  },
  watch: {},
  computed: {},

  destroyed: async function() {}
};
</script>
