<template>
  <div
    class="
      w-11/12
      lg:w-3/4
      rounded-3xl
      p-8
      bg-tertiary
      py-8
      text-grey-900
      uppercase
      text-xl
      font-bold
    "
    :class="{ 'not-expanded': !this.expanded }"
  >
    <div class="flex justify-between items-center">
      <div class="flex space-x-0 items-center justify-between w-full">
        <h2
          class="
            flex
            text-grey-900
            items-center
            uppercase
            text-xl
            font-bold
            w-full
            md:w-5/12
          "
        >
          <i
            class="
              bg-grey-500
              rounded-full
              bg-white bg-opacity-30
              h-16
              w-16
              mr-4
              flex
              items-center
              justify-center
              shadow-sm
            "
          >
            <img
              class="rounded-full object-cover"
              src="@/assets/images/logos/muse.svg"
              alt
            />
          </i>
          <a :href="uniswapPoolAddress" target="_blank">
            <span>{{ symbol }}/ETH LP</span>
            <span v-if="newTag" data-v-5b6fdd1c="" class="ml-2 text-secondary"
              >NEW!</span
            >
          </a>

          <a
            class="tooltip"
            href="https://snapshot.org/#/nft20.eth/proposal/QmP19j9MRApzKumYWnivWS9iE6kpPh935zz7pKznjUSHyn"
            target="_blank"
            v-if="warn"
          >
            <span class="tooltiptext text-sm p-16 font-normal"
              >Pool is over 30 days old, could be replaced with a more actively
              used NFT20 pool any day. Click to read our LP framework</span
            >
            <img src="@/assets/images/warn.svg" alt class="h-5 w-6 ml-2" />
          </a>

          <a :href="uniswapPoolAddress" target="_blank" v-else>
            <img src="@/assets/images/uniswap.svg" alt class="h-5 ml-2" />
          </a>
        </h2>
        <!-- <div class="hidden md:flex flex-col items-center space-x-4">
          <span class="text-gray-500 text-xs">Your wallet:</span>
          <span>
            <TokenBalance
              :erc20="address"
              :account="account"
              symbol="$ULP"
              decimals="18"
            />
          </span>
        </div>-->
        <div class="hidden md:flex flex-col items-start pl-4 w-4/12">
          <span class="text-gray-500 text-xs">Pool rewards:</span>
          <div>
            {{ apy }}%
            <span>APR</span>
          </div>
        </div>

        <div class="hidden md:flex flex-col items-start pl-6 w-3/12">
          <span class="text-gray-500 text-xs"
            >TVL ${{ symbol.toLocaleString() }}-ETH:</span
          >
          <div>${{ totalLiquidity.toFixed(2) }}</div>
        </div>
      </div>
      <div
        class="cursor-pointer"
        @click="toggleCardState()"
        :class="{ chevron: this.expanded }"
      >
        <svg
          class="w-6 h-6"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M19 9l-7 7-7-7"
          />
        </svg>
      </div>
    </div>

    <div class="flex flex-col py-10 card-content">
      <div class="content">
        <slot>
          <!-- <div class="flex mb-4 md:hidden flex-col items-center space-x-4">
            <span class="text-gray-500 text-xs">Your wallet::</span>
            <span>
              <TokenBalance
                :erc20="address"
                :account="account"
                symbol="$ULP"
                decimals="18"
              />
            </span>
          </div>-->
          <div class="mb-4 flex md:hidden flex-col items-start">
            <span class="text-gray-500 text-xs"
              >Pool rewards for ${{ symbol }}-ETH:</span
            >
            <span>
              {{ dailyRewards }}
              <span>MUSE a day</span>
            </span>
          </div>
          <div class="flex md:hidden flex-col items-start mb-8">
            <span class="text-gray-500 text-xs">TVL ${{ symbol }}-ETH:</span>
            <span>${{ totalLiquidity.toFixed(2) }}</span>
          </div>

          <div
            class="
              flex
              md:flex-row
              flex-col
              justify-between
              space-y-8
              md:space-y-0
              gap-8
            "
          >
            <div class="flex flex-col items-start space-y-4 w-full md:w-4/12">
              <div>
                <span class="text-gray-500 text-xs">You Staked:</span>
              </div>
              <div
                style="border-color: #b2aea8"
                class="
                  border
                  rounded-lg
                  pt-4
                  pb-3
                  px-5
                  text-2xl
                  w-full
                  text-jet
                  font-light
                  bg-transparent
                  focus:outline-none
                "
                placeholder="0"
                type="text"
              >
                <Number decimals="18" :number="staked + ''" />
                $LP
                <!-- {{  }} $LP -->
              </div>
              <div
                style="border-color: #b2aea8"
                class="
                  border
                  rounded-lg
                  pt-4
                  pb-3
                  px-5
                  text-sm
                  w-full
                  text-jet
                  font-light
                  bg-transparent
                  focus:outline-none
                "
                placeholder="0"
                type="text"
              >
                Total staked:
                <Number decimals="18" :number="totalStaked + ''" />$LP
              </div>
            </div>
            <div class="flex flex-col items-start space-y-4 w-full md:w-4/12">
              <div>
                <span class="text-gray-500 text-xs">In your wallet:</span>
              </div>
              <div
                style="border-color: #b2aea8"
                class="
                  border
                  rounded-lg
                  pt-4
                  pb-3
                  px-5
                  text-2xl
                  w-full
                  text-jet
                  font-light
                  bg-transparent
                  focus:outline-none
                "
                type="text"
              >
                <Number decimals="18" :number="userLpBalance + ''" />$LP
              </div>
              <button
                class="
                  text-center
                  uppercase
                  font-primary font-bold
                  leading-none
                  text-sm
                  inline-block
                  rounded-lg
                  py-4
                  px-5
                  text-brown
                  hover:text-white
                  bg-secondary
                  hover:bg-blue-900 hover:bg-opacity-70
                  transition
                  duration-0
                  focus:outline-none
                "
                @click="stake"
              >
                <span v-if="!hasTokenApproval">Approve & Stake</span>
                <span v-else>Stake</span>
              </button>
            </div>
            <div class="flex flex-col items-start space-y-4 w-full md:w-4/12">
              <div>
                <span class="text-gray-500 text-xs">Earned $MUSE:</span>
              </div>
              <div
                style="border-color: #b2aea8"
                class="
                  border
                  rounded-lg
                  pt-4
                  pb-3
                  px-5
                  text-2xl
                  w-full
                  text-jet
                  font-light
                  bg-transparent
                  focus:outline-none
                "
                placeholder="0"
                type="text"
              >
                <Number decimals="18" :number="pendingPoints + ''" />
              </div>
              <button
                @click="withdraw(true)"
                class="
                  text-center
                  uppercase
                  font-primary font-bold
                  leading-none
                  text-sm
                  inline-block
                  rounded-lg
                  py-4
                  px-5
                  text-brown
                  hover:text-white
                  bg-secondary
                  hover:bg-blue-900 hover:bg-opacity-70
                  transition
                  duration-0
                  focus:outline-none
                "
              >
                Withdraw Stake
              </button>
              <button
                @click="withdraw(fale)"
                class="
                  text-center
                  uppercase
                  font-primary font-bold
                  leading-none
                  text-sm
                  inline-block
                  rounded-lg
                  py-4
                  px-5
                  text-brown
                  hover:text-white
                  bg-secondary
                  hover:bg-blue-900 hover:bg-opacity-70
                  transition
                  duration-0
                  focus:outline-none
                "
              >
                Withdraw Rewards
              </button>
            </div>
          </div>
        </slot>
      </div>
    </div>
  </div>
</template>

<script>
import TokenBalance from "@/components/TokenBalance.vue";
import Number from "@/components/Number.vue";
import NFT20Abi from "@/assets/abis/Pair";

import BigNumber from "bignumber.js";

export default {
  name: "FarmCardNFT20",
  components: { TokenBalance, Number },
  props: {
    expandAll: Boolean,
    symbol: String,
    address: String,
    poolid: String,
    dailyRewards: Number,
    warn: Boolean,
    newTag: Boolean,
    // img: String,
    // timeLeft: String,
    // price: String,
  },
  data() {
    return {
      expanded: false,
      hasTokenApproval: false,
      staked: 0,
      pendingPoints: 0,
      userLpBalance: -1,
      erc20Contract: null,
      wethContract: null,
      totalLiquidity: 0,
      totalStaked: 0,
      apy: 0,
    };
  },
  methods: {
    toggleCardState: async function () {
      this.expanded = !this.expanded;
    },
    // registerStake: async function () {
    //   let minetx = await this.$store.state.DuckChef.methods
    //     .register(0, 1)
    //     .send({
    //       from: this.$store.state.account,
    //     });
    // },
    // withdraw: async function () {
    //   let minetx = await this.$store.state.DuckChef.methods
    //     .wtihdraw(0, 1)
    //     .send({
    //       from: this.$store.state.account,
    //     });
    // },
    init: async function () {
      // console.log(infos);

      if (this.userLpBalance != -1 || this.$store.state.pairCount == 0) {
        return;
      }

      this.wethContract = new this.$store.state.web3.eth.Contract(
        NFT20Abi,
        "0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2"
      );

      let balance = await this.wethContract.methods
        .balanceOf(this.address)
        .call();

      balance = parseFloat(
        new BigNumber(balance).shiftedBy(-parseInt(18)).toFixed(3)
      );
      this.totalLiquidity = balance * 2 * this.$store.state.ethPrice;

      this.apy = (
        ((this.dailyRewards * this.$store.state.musePrice * 365) /
          this.totalLiquidity) *
        100
      ).toFixed(2);

      this.erc20Contract = new this.$store.state.web3.eth.Contract(
        NFT20Abi,
        this.address
      );

      const totalStaked = await this.erc20Contract.methods
        .balanceOf(this.$store.state.NFT20MasterChef._address)
        .call();
      this.totalStaked = totalStaked;
      this.hasTokenApproval =
        (
          await this.erc20Contract.methods
            .allowance(
              this.$store.state.account,
              this.$store.state.contracts.MasterChefNew
            )
            .call()
        ).length >= 18;

      const staked = await this.$store.state.NFT20MasterChef.methods
        .userInfo(this.poolid, this.$store.state.account)
        .call();
      this.staked = staked.amount;

      this.pendingPoints = await this.$store.state.NFT20MasterChef.methods
        .pendingMuse(this.poolid, this.$store.state.account)
        .call();

      const userLpBalance = await this.erc20Contract.methods
        .balanceOf(this.$store.state.account)
        .call();
      this.userLpBalance = userLpBalance;
    },
    withdraw: async function (withdrawStake) {
      try {
        // if (!this.hasTokenApproval) {
        //   await this.approve();
        // }

        if (withdrawStake) {
          const pendingAmount = await this.$store.state.NFT20MasterChef.methods
            .userInfo(this.poolid, this.$store.state.account)
            .call();
          let minetx = await this.$store.state.NFT20MasterChef.methods
            .withdraw(this.poolid, pendingAmount.amount)
            .send({
              from: this.$store.state.account,
            })
            .on("transactionHash", (hash) => {
              console.log("hash of tx ", hash);
              // pass the hash to notify to track it
              this.$store.state.notify.hash(hash);
            });
        } else {
          let minetx = await this.$store.state.NFT20MasterChef.methods
            .withdraw(this.poolid, 0)
            .send({
              from: this.$store.state.account,
            })
            .on("transactionHash", (hash) => {
              // pass the hash to notify to track it
              this.$store.state.notify.hash(hash);
            });
        }
      } catch (e) {
        console.log(e);
      }
    },
    stake: async function () {
      try {
        if (!this.hasTokenApproval) {
          await this.approve();
        }

        const userLpBalance = await this.erc20Contract.methods
          .balanceOf(this.$store.state.account)
          .call();
        let minetx = await this.$store.state.NFT20MasterChef.methods
          .deposit(this.poolid, userLpBalance)
          .send({
            from: this.$store.state.account,
          })
          .on("transactionHash", (hash) => {
            console.log("hash of tx ", hash);
            // pass the hash to notify to track it
            this.$store.state.notify.hash(hash);
          });
      } catch (e) {
        console.log(e);
      }
    },
    approve: async function () {
      let max =
        "115792089237316195423570985008687907853269984665640564039457584007913129639935";
      let remove = "0";
      let approve = await this.erc20Contract.methods
        .approve(this.$store.state.contracts.MasterChefNew, max)
        .send({
          from: this.$store.state.account,
        })
        .on("transactionHash", (hash) => {
          console.log("hash of tx ", hash);
          // pass the hash to notify to track it
          this.$store.state.notify.hash(hash);
        });

      this.init();
    },
  },
  mounted: async function () {
    this.init();
  },
  watch: {
    isConnected: async function () {
      this.init();
    },
  },

  computed: {
    account: function () {
      return this.$store.state.account;
    },
    isConnected: function () {
      return this.$store.state.account;

      // return this.$store.state.pairCount > 0;
    },
    uniswapPoolAddress: function () {
      return "https://v2.info.uniswap.org/pair/" + this.address;
    },
  },
};
</script>

<style scoped>
.not-expanded .card-content {
  transform: scaleY(0);
  transform-origin: top;
  opacity: 0;
  height: 0;
  padding: 0;
}
.card-content {
  transition: all 150ms ease;
}

.chevron {
  transform: rotate(180deg);
  transition: transform 150ms ease-out;
}

/* Tooltip container */
.tooltip {
  position: relative;
  display: inline-block;
  /*border-bottom: 1px dotted black;*/ /* If you want dots under the hoverable text */
}

/* Tooltip text */
.tooltip .tooltiptext {
  visibility: hidden;
  width: 160px;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 18px;
  border-radius: 6px;
  /*padding: 2rem;*/

  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
  visibility: visible;
}
</style>