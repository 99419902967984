<template>
  <div
    class="
      about
      text-white
      leading-loose
      tracking-normal
      pt-10
      px-6
      md:px-0
      mb-48
    "
  >
    <main v-if="!isLoaded" class="main-container md:mx-0 mx-16">
      <section class="text-white text-lg mb-96">
        <div class="pt-3 pb-3 mb-10">
          <h1 class="text-2xl uppercase text-center font-medium">
            First NFT Flash Loans
          </h1>
          <p class="px-2 md:px-10 text-center mt-6 font-light">
            Loading data from HashMask pool ({{ this.currentId }} /
            {{ this.listSize }})...
          </p>
        </div>
        <div></div>
      </section>
    </main>
    <div v-if="isLoaded" class="flex w-full text-center flex-col space-y-12">
      <h1 class="text-2xl">
        First NFT Flash Loans
        <div
          class="
            items-end
            text-secondary
            mt-16
            bg-gray-600
            w-full
            sm:w-1/2
            md:w-1/3
            mx-auto
            px-8
            pt-9
            pb-8
            rounded-lg
            border-r border-b border-gray-500
            shadow-inner
            cursor-pointer
          "
          v-on:click="makeTX"
        >
          <span class="text-2xl text-gray-800"
            >Claim an estimated {{ sum / 10e18 }} NCT</span
          >
        </div>
      </h1>

      <div clsas="flex flex-row text-xl">
        <div class="text-secondary">This is purely experimental.</div>
        <div>
          This operation will flash loan the 20 most profitable HashMask of the
          pool and claim token from them to send you back wETH (10% profit goes
          to NFT20 DAO).
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import BigNumber from "bignumber.js";
import axios from "axios";
export default {
  name: "Flash",
  components: {},
  data: function () {
    return {
      isLoaded: false,
      loading: false,
      sum: 0,
      ids: [],
      listSize: 0,
      currentId: 0,
    };
  },
  beforeMount: async function () {},
  methods: {
    initialize: function () {
      console.log(this.$store.state.ERC721 != null && !this.loading, "LOAD");
      if (this.$store.state.ERC721 != null && !this.loading) {
        this.loading = true;
        this.flashie();
      }
    },
    makeTX: async function () {
      const NFT20_PAIR = "0xc2bde1a2fa26890c8e6acb10c91cc6d9c11f4a73";
      let pair = await this.$store.state.Pair(NFT20_PAIR);
      console.log(this.ids);
      await pair.methods
        .flashLoan(
          this.ids,
          [],
          "0xcb29dea2590f30a3927fd4a43ef1edc613c78dda",
          "0x0"
        )
        .send({
          from: this.$store.state.account,
        })
        .on("transactionHash", (hash) => {
          console.log("hash of tx ", hash);
          // pass the hash to notify to track it
          this.$store.state.notify.hash(hash);
        });
    },
    flashie: async function () {
      console.log("here");
      const NCT_ADDR = "0x8A9c4dfe8b9D8962B31e4e16F8321C44d48e246E";
      const NFT20_PAIR = "0xc2bde1a2fa26890c8e6acb10c91cc6d9c11f4a73";
      console.log(this.$store.state.ERC721);
      let hashmask = await this.$store.state.ERC721(
        "0xC2C747E0F7004F9E8817Db2ca4997657a7746928" //hashmask contract
      );
      this.listSize = await hashmask.methods
        .balanceOf(
          NFT20_PAIR //Hashmask NFT20 Pair
        )
        .call();
      let tokens = [];
      for (
        0;
        this.currentId < this.listSize;
        this.currentId = this.currentId + 1
      ) {
        const id = await hashmask.methods
          .tokenOfOwnerByIndex(NFT20_PAIR, this.currentId)
          .call();

        const claimable = await this.$store.state.NCT.methods
          .accumulated(id)
          .call();
        console.log(claimable);
        tokens.push({
          id: parseInt(id),
          claimable: parseInt(claimable),
        });
      }

      tokens = tokens.sort((a, b) => b.claimable - a.claimable);
      tokens = tokens.slice(0, 8); // change this for size of claim
      for (const t of tokens) {
        this.sum += t.claimable;
        if (t.claimable != 0) {
          this.ids.push(t.id);
        }
      }
      this.isLoaded = true;
      console.log(tokens);
    },
  },
  watch: {
    isConnected: function () {
      this.flashie();
    },
  },
  computed: {
    isConnected: function () {
      return this.$store.state.isLoaded;
    },
  },
  mounted: async function () {
    this.initialize();
  },
};
</script>


<style scoped>
p {
  padding: 0.6rem 0rem;
}
/* video {
  -moz-box-shadow: 0 -22px 22px -22px rgba(0, 0, 0, 0.8) inset;
  -webkit-box-shadow: 0 -22px 22px -22px rgba(0, 0, 0, 0.8) inset;
  box-shadow: 0 -100px 100px -100px rgba(0, 0, 0, 0.8) inset;
} */
</style>