<template>
  <div class="px-8 md:px-0">
    <div
      v-if="project.factoryVersion != 1"
      class="w-full flex justify-end space-x-4 items-center"
      @click="toggleSwapBtn"
    >
      <h2 class="text-white">Swap NFT to NFT</h2>
      <div
        class="
          w-16
          h-10
          flex
          items-center
          bg-gray-300
          rounded-full
          p-1
          duration-300
          ease-in-out
        "
        :class="{ 'bg-green-400': toggleSwap }"
      >
        <div
          class="
            bg-white
            w-8
            h-8
            rounded-full
            shadow-md
            transform
            duration-300
            ease-in-out
          "
          :class="{ 'translate-x-6': toggleSwap }"
        ></div>
      </div>
    </div>
    <div
      class="bg-tertiary flex flex-col p-6 rounded-5xl mt-6 md:mt-6 shadow-md"
    >
      <div v-if="mainAsset || toggleSwap">
        <NftField
          @open="openSelectNftModal(true)"
          :isMain="true"
          :swap="toggleSwap"
        />
      </div>
      <div v-else>
        <TokenField v-if="!toggleSwap" :isMain="true" />
      </div>
      <div class="flex justify-center">
        <svg
          @click="swapOpposite"
          class="cursor-pointer h-6 my-3 transform rotate-180"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          viewBox="0 0 12.814 18.081"
        >
          <path
            fill="#231F20"
            d="M0.146,12.027c-0.195-0.195-0.195-0.512,0-0.707s0.512-0.195,0.707,0l5.054,5.054V0.5 c0-0.277,0.223-0.5,0.5-0.5s0.5,0.223,0.5,0.5v15.874l5.054-5.054c0.195-0.195,0.512-0.195,0.707,0s0.195,0.512,0,0.707 l-5.906,5.907c-0.046,0.046-0.103,0.083-0.163,0.107c-0.062,0.025-0.126,0.039-0.191,0.039c-0.064,0-0.13-0.014-0.19-0.039 C6.154,18.018,6.1,17.98,6.054,17.935L0.146,12.027"
          />
        </svg>
        <svg
          @click="swapOpposite"
          class="cursor-pointer h-6 my-3"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          viewBox="0 0 12.814 18.081"
        >
          <path
            fill="#231F20"
            d="M0.146,12.027c-0.195-0.195-0.195-0.512,0-0.707s0.512-0.195,0.707,0l5.054,5.054V0.5 c0-0.277,0.223-0.5,0.5-0.5s0.5,0.223,0.5,0.5v15.874l5.054-5.054c0.195-0.195,0.512-0.195,0.707,0s0.195,0.512,0,0.707 l-5.906,5.907c-0.046,0.046-0.103,0.083-0.163,0.107c-0.062,0.025-0.126,0.039-0.191,0.039c-0.064,0-0.13-0.014-0.19-0.039 C6.154,18.018,6.1,17.98,6.054,17.935L0.146,12.027"
          />
        </svg>
      </div>
      <div v-if="!mainAsset || toggleSwap">
        <NftField
          @open="openSelectNftModal(false)"
          :isMain="false"
          :swap="toggleSwap"
        />
      </div>
      <div v-else>
        <TokenField v-if="!toggleSwap" :isMain="false" />
      </div>

      <div v-if="!isApprovedForAll && toggleSwap" class="flex justify-between">
        <button
          type="button"
          class="
            -ml-6
            -mb-6
            mt-8
            border-t border-r
            font-normal
            text-2xl
            px-0
            py-6
            text-center
            rounded-bl-5xl
            text-brown
            hover:text-white
            bg-secondary
            hover:bg-blue-900 hover:bg-opacity-70
            transition
            duration-0
            focus:outline-none
            w-full
          "
          @click="setApproalForAll"
        >
          Approve
        </button>
        <button
          type="button"
          disabled
          class="
            disabled
            cursor-not-allowed
            -mr-6
            -mb-6
            mt-8
            border-t
            font-normal
            text-2xl
            px-0
            py-6
            text-center
            rounded-br-5xl
            text-black text-opacity-30
            bg-black bg-opacity-30
            transition
            duration-0
            focus:outline-none
            w-full
          "
        >
          Swap
        </button>
      </div>
      <button
        v-else
        type="button"
        class="
          -m-6
          mt-8
          border-t
          uppercase
          font-bold
          text-3xl
          px-0
          py-6
          text-center
          rounded-bl-5xl rounded-br-5xl
          text-brown
          hover:text-white
          bg-secondary
          hover:bg-blue-900 hover:bg-opacity-70
          transition
          duration-0
          focus:outline-none
        "
        @click="swap"
      >
        Swap
      </button>
    </div>
    <SelectNFT
      :mainAsset="mainAsset"
      :swap="toggleSwap"
      v-if="showModal"
      :in="openType"
      @close="showModal = false"
    />

    <div class="text-center text-white mt-4 font-light">
      <div class="font-bold" v-if="project.removedFees">
        This pool is waived from the 5% fee
      </div>

      <router-link to="/faqs" class="hover:underline">
        Learn more about NFT20</router-link
      >
    </div>
  </div>
</template> 

<script>
import SelectNFT from "@/components/SelectNFT";
import NftField from "@/components/NftField";
import TokenField from "@/components/TokenField";
// import {
//   ChainId,
//   Fetcher,
//   WETH,
//   Route,
//   Trade,
//   TokenAmount,
//   TradeType,
// } from "@uniswap/sdk";
export default {
  components: { SelectNFT, NftField, TokenField },
  name: "SwapBox",
  props: {},
  data: function () {
    return {
      toggleSwap: false,
      showModal: false,
      mainAsset: true,
      openType: false,
      isApprovedForAll: false,
      // project: {},
    };
  },
  beforeMount: async function () {},
  computed: {
    project: function () {
      return this.$store.state.currentProject;
    },
    selectedNfts: function () {
      return this.$store.state.selectedNfts;
    },
    selectedNftsIn: function () {
      return this.$store.state.selectedNftsIn;
    },
    selectedNftsOut: function () {
      return this.$store.state.selectedNftsOut;
    },
    isConnected: function () {
      return this.$store.state.account;
    },
  },
  watch: {
    isConnected: async function () {
      this.init();
    },
  },
  mounted: async function () {
    this.init();
  },
  created() {
    this.interval = setInterval(() => this.init(), 30000);
  },
  methods: {
    init: async function () {
      let nftContract;
      if (this.project.type == 1155) {
        nftContract = await this.$store.state.ERC1155(this.project.nftAddress);
      } else if (this.project.type == 721) {
        nftContract = await this.$store.state.ERC721(this.project.nftAddress);
      }
      if (this.$store.state.account) {
        this.isApprovedForAll = await nftContract.methods
          .isApprovedForAll(this.$store.state.account, this.project.address)
          .call();
      } else {
        this.isApprovedForAll = false;
      }
    },
    openSelectNftModal: async function (bool) {
      if (this.$store.state.account == null) {
        this.$store.dispatch("connectWallet", this);
        return;
      }
      this.showModal = !this.showModal;
      if (bool) {
        this.openType = true;
      } else {
        this.openType = false;
      }
    },
    approve: async function () {
      // let max =
      //   "115792089237316195423570985008687907853269984665640564039457584007913129639935";
      // let remove = "0";
      // let contract = await this.$store.state.Pair(this.project.address);
      // let minetx = await contract.methods
      //   .approve(this.project.address, max)
      //   .send({
      //     from: this.$store.state.account,
      //   });
    },
    swapOpposite: async function () {
      if (!this.toggleSwap) {
        this.mainAsset = !this.mainAsset;
        this.$store.commit("setSelectedNfts", []);
        this.$store.commit("setDidSelectNfts", false);
      }
    },
    toggleSwapBtn: async function () {
      this.toggleSwap = !this.toggleSwap;
      if (this.toggleSwap) {
        this.mainAsset = true;
      }
    },

    swap: async function (tokenId) {
      if (this.$store.state.account == null) {
        this.$store.dispatch("connectWallet", this);
        return;
      }
      if (this.toggleSwap) {
        await this.init();
        if (!this.isApprovedForAll) {
          await this.setApproalForAll();
        }
        // if (
        //   !this.selectedNftsIn[0] ||
        //   this.selectedNftsIn[0].length != this.selectedNftsOut[0].length ||
        //   !this.selectedNftsOut[0]
        // ) {
        //   alert("Pick the same swap for swap quantity");
        //   return;
        // }
        if (this.project.type == 1155) {
          // console.log(this.selectedNftsIn)

          console.log("in", this.selectedNftsIn[0]);
          console.log("in amt", this.selectedNftsIn[1]);
          console.log("out", this.selectedNftsOut[0]);
          console.log("out amt", this.selectedNftsOut[1]);

          let contract = await this.$store.state.Pair(this.project.address);
          let minetx = await contract.methods
            .swap1155(
              this.selectedNftsIn[0],
              this.selectedNftsIn[1],
              this.selectedNftsOut[0],
              this.selectedNftsOut[1],
              this.$store.state.account
            )
            .send({
              from: this.$store.state.account,
            })
            .on("transactionHash", (hash) => {
              console.log("hash of tx ", hash);
              // pass the hash to notify to track it
              this.$store.state.notify.hash(hash);
            });
        } else if (this.project.type == 721) {
          let contract = await this.$store.state.Pair(this.project.address);
          let minetx = await contract.methods
            .swap721(this.selectedNftsIn[0], this.selectedNftsOut[0], this.$store.state.account)
            .send({
              from: this.$store.state.account,
            })
            .on("transactionHash", (hash) => {
              console.log("hash of tx ", hash);
              // pass the hash to notify to track it
              this.$store.state.notify.hash(hash);
            });
        }
        // add web3 contract code to swap nft to nft here
        // alert("swapping nft to nft");
      } else {
        if (!this.mainAsset) {
          await this.withdrawNFTs();
        } else {
          if (this.project.type == 721) {
            this.deposit721();
          } else if (this.project.type == 1155) {
            this.deposit1155();
          }
          this.$notify({
            group: "foo",
            type: "success",
            title: "Important message",
            text: "Your tx is being mined!",
          });
        }
      }
    },
    withdrawNFTs: async function () {
      // we need to make sure to pass the amount and which NFTs to withdraw
      if (this.$store.state.account == null) {
        this.$store.dispatch("connectWallet", this);
        return;
      }
      // alert(this.$store.state.account);
      let contract = await this.$store.state.Pair(this.project.address);
      if (this.project.factoryVersion == 1) {
        //load ABI for First version
        contract = await this.$store.state.Pair1(this.project.address);
      }
      if (this.project.factoryVersion != 1) {
        let minetx = await contract.methods
          .withdraw(
            this.selectedNfts[0],
            this.selectedNfts[1],
            this.$store.state.account
          )
          .send({
            from: this.$store.state.account,
          });
      } else if (this.project.factoryVersion == 1) {
        console.log(this.selectedNfts)
        let minetx = await contract.methods
          .withdraw(this.selectedNfts[0], this.selectedNfts[1])
          .send({
            from: this.$store.state.account,
          })
          .on("transactionHash", (hash) => {
            console.log("hash of tx ", hash);
            // pass the hash to notify to track it
            this.$store.state.notify.hash(hash);
          });
      }
    },
    deposit721: async function () {
      let contract = await this.$store.state.ERC721(this.project.nftAddress);
      // here we send the nfts (need to do some magic to see selected)
      // to the contract and that's enough to get tokens.
      if (this.$store.state.account == null) {
        this.$store.dispatch("connectWallet", this);
        return;
      }
      let referralAddress = this.$store.state.contracts.Factory;
      if (
        (this.project.removedFees != null &&
          this.project.removedFees == true) ||
        this.project.nftLocked == 0
      ) {
        referralAddress = this.$store.state.account;
      }
      if (this.selectedNfts[0].length == 1) {
        await contract.methods
          .safeTransferFrom(
            this.$store.state.account,
            this.project.address,
            this.selectedNfts[0][0],
            referralAddress
          )
          .send({
            from: this.$store.state.account,
          })
          .on("transactionHash", (hash) => {
            console.log("hash of tx ", hash);
            // pass the hash to notify to track it
            this.$store.state.notify.hash(hash);
          });
      } else if (this.selectedNfts[0].length > 1) {
        await this.init();
        if (!this.isApprovedForAll) {
          await this.setApproalForAll();
        }
        contract = await this.$store.state.Pair(this.project.address);
        let minetx = await contract.methods
          .multi721Deposit(this.selectedNfts[0], this.$store.state.account, referralAddress)
          .send({
            from: this.$store.state.account,
          })
          .on("transactionHash", (hash) => {
            console.log("hash of tx ", hash);
            // pass the hash to notify to track it
            this.$store.state.notify.hash(hash);
          });
        // alert("withdraw multi");
      }
    },
    deposit1155: async function () {
      let contract = await this.$store.state.ERC1155(this.project.nftAddress);
      // here we need to check how many tokens and what ids were picked to then add
      // ids and amounts as it's 1155.
      if (this.$store.state.account == null) {
        this.$store.dispatch("connectWallet", this);
        return;
      }
      let referralAddress = this.$store.state.contracts.Factory;
      if (
        (this.project.removedFees != null &&
          this.project.removedFees == true) ||
        this.project.nftLocked == 0
      ) {
        referralAddress = this.$store.state.account;
      }
      if (this.selectedNfts[0].length > 1) {
        // could be many so need to do calc of how many tokens would get
        let minetx = await contract.methods
          .safeBatchTransferFrom(
            this.$store.state.account,
            this.project.address,
            this.selectedNfts[0],
            this.selectedNfts[1],
            referralAddress
          )
          .send({
            from: this.$store.state.account,
          })
          .on("transactionHash", (hash) => {
            console.log("hash of tx ", hash);
            // pass the hash to notify to track it
            this.$store.state.notify.hash(hash);
          });
      } else {
        let minetx = await contract.methods
          .safeTransferFrom(
            this.$store.state.account,
            this.project.address,
            this.selectedNfts[0][0],
            this.selectedNfts[1][0],
            referralAddress
          )
          .send({
            from: this.$store.state.account,
          })
          .on("transactionHash", (hash) => {
            console.log("hash of tx ", hash);
            // pass the hash to notify to track it
            this.$store.state.notify.hash(hash);
          });
      }
    },
    setApproalForAll: async function () {
      let contract;
      if (this.project.type == 1155) {
        contract = await this.$store.state.ERC1155(this.project.nftAddress);
      } else if (this.project.type == 721) {
        contract = await this.$store.state.ERC721(this.project.nftAddress);
      }
      await contract.methods
        .setApprovalForAll(this.project.address, true)
        .send({
          from: this.$store.state.account,
        })
        .on("transactionHash", (hash) => {
          console.log("hash of tx ", hash);
          // pass the hash to notify to track it
          this.$store.state.notify.hash(hash);
        });

      this.init();
    },
  },
};
</script>