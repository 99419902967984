<template>
  <main class="main-container text-white">
      sddsadsadsa
     <div
          class="
          "
          v-for="project in pools"
          :key="project.address"
        >
          @{{project.twitter_username ? project.twitter_username : project.name}} - {{parseInt(parseFloat(project.nftBasePriceEth) * 1000)/ 1000}}Ξ
        </div>
  </main>
</template>

<script>


export default {
  name: "Tweet",
  components: {},
  data: function() {
    return {

    };
  },
  methods: {

  },
  mounted: async function() {},
  beforeMount: async function() {
  },
  watch: {},
  computed: {
      pools: function() {
          return this.$store.state.pairs
      }
  },

  destroyed: async function() {}
};
</script>
