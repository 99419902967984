<template>
  <div>
    <header class="header pt-4 pb-2 pt-8 md:pb-4 mb-6 relative">
      <div class="max-w-full mx-auto px-8">
        <div class="flex justify-between items-start lg:items-end">
          <div>
            <router-link to="/">
              <img src="@/assets/images/logo1.svg" alt class="h-14 md:h-14" />
            </router-link>
          </div>
          <div class="relative">
            <!-- <div class="relative"> -->
            <div @click.stop="showMenuMobile = !showMenuMobile">
              <button class="lg:hidden menu-button ml-auto">
                <div class="bar"></div>
                <div class="bar"></div>
                <div class="bar"></div>
              </button>
            </div>

            <!-- for now hidden on mobile -->
            <nav
              class="
                lg:static lg:mx-0 lg:block
                absolute
                left-0
                w-auto
                -mx-32
                bg-black
                lg:bg-transparent
                p-4
                lg:p-0
                z-50
                rounded-lg
                lg:opacity-100
                opacity-90
              "
              id="nav"
              v-bind:class="{ hidden: !showMenuMobile }"
            >
              <ul
                class="
                  menu
                  flex flex-col
                  lg:items-center lg:flex-row
                  text-white
                "
              >
                <li class="lg:pl-6 xl:pl-10">
                  <router-link
                    to="/assets"
                    class="
                      text-white
                      captalize
                      font-primary font-normal
                      leading-none
                      lg:text-sm
                      xl:text-base
                      block
                      rounded-full
                      py-2
                      lg:py-5
                      hover:text-yellow-300
                      transition
                      duration-300
                    "
                    >Assets</router-link
                  >
                </li>
                <!-- <li class="lg:pl-6 xl:pl-10">
                  <a
                    href="https://game.nft20.io"
                    target="_blank"
                    class="text-white captalize font-primary font-normal leading-none lg:text-sm xl:text-base block rounded-full py-2 lg:py-5 hover:text-yellow-300 transition duration-300"
                    >NFT Battle Game <span class="ml-1 text-secondary ">NEW!</span></a
                  >
                </li> -->

                <li class="lg:pl-6 xl:pl-10">
                  <router-link
                    to="/trade"
                    class="
                      text-white
                      captalize
                      font-primary font-normal
                      leading-none
                      lg:text-sm
                      xl:text-base
                      block
                      rounded-full
                      py-2
                      lg:py-5
                      hover:text-yellow-300
                      transition
                      duration-300
                    "
                    >Buy & Sell NFTs
                    <span class="ml-1 text-secondary">NEW!</span></router-link
                  >
                </li>

                <li class="lg:pl-6 xl:pl-10">
                  <router-link
                    to="/wallet"
                    class="
                      text-white
                      captalize
                      font-primary font-normal
                      leading-none
                      lg:text-sm
                      xl:text-base
                      block
                      rounded-full
                      py-2
                      lg:py-5
                      hover:text-yellow-300
                      transition
                      duration-300
                    "
                    >My Wallet
                    <span class="ml-1 text-secondary">NEW!</span></router-link
                  >
                </li>
                <li class="lg:pl-6 xl:pl-10">
                  <router-link
                    to="/faqs"
                    class="
                      text-white
                      captalize
                      font-primary font-normal
                      leading-none
                      lg:text-sm
                      xl:text-base
                      block
                      rounded-full
                      py-2
                      lg:py-5
                      hover:text-yellow-300
                      transition
                      duration-300
                    "
                    >FAQ</router-link
                  >
                </li>
                <li class="lg:pl-6 xl:pl-10">
                  <router-link
                    to="/farm"
                    class="
                      text-white
                      captalize
                      font-primary font-normal
                      leading-none
                      lg:text-sm
                      xl:text-base
                      block
                      rounded-full
                      py-2
                      lg:py-5
                      hover:text-yellow-300
                      transition
                      duration-300
                    "
                    >Farm</router-link
                  >
                </li>
                <!-- <li class="lg:pl-6 xl:pl-10">
                  <router-link
                    target="blank"
                    to="/leaderboard"
                    class="text-white captalize font-primary font-normal leading-none lg:text-sm xl:text-base block rounded-full py-2 lg:py-5 hover:text-yellow-300 transition duration-300"
                    >Leaderboard
                  </router-link>
                </li> -->
                <li class="lg:pl-6 xl:pl-10">
                  <a
                    target="blank"
                    href="https://docs.nft20.io"
                    class="
                      text-white
                      captalize
                      font-primary font-normal
                      leading-none
                      lg:text-sm
                      xl:text-base
                      block
                      rounded-full
                      py-2
                      lg:py-5
                      hover:text-yellow-300
                      transition
                      duration-300
                    "
                    >Docs</a
                  >
                </li>
                <!-- <li class="md:pl-3">
                <router-link
                  to="/activity"
                  class="text-white md:text-white captalize font-primary font-normal leading-none text-base block rounded-full py-3 md:py-5 md:px-3 hover:text-yellow-300 transition duration-300"
                  >Activity</router-link
                >
                </li>-->
                <!-- <li class="pt-4 lg:pt-0 lg:pl-0 xl:pl-4 mr-3">
                  <span class="flex items-center space-x-4 rounded-lg py-3 px-3 lg:px-6 text-white hover:text-yellow-300 transition duration-300 cursor-pointer">
                    <span class="uppercase font-primary font-normal leading-none text-sm block">New Proposal</span>
                    <span class="w-3 h-3 bg-green-500 rounded-full"></span>
                  </span>
                </li> -->
                <li class="pt-4 lg:pt-0 w-24 lg:ml-6 xl:ml-10">
                  <a href="https://nft20.io">
                    <div
                      class="
                        h-12
                        text-white
                        uppercase
                        font-primary font-normal
                        leading-none
                        text-sm
                        block
                        rounded-lg
                        lg:rounded-r-none
                        bg-white
                        py-3
                        lg:py-3
                        px-3
                        hover:text-white
                        transition
                        duration-300
                        cursor-pointer
                      "
                    >
                      <img
                        src="@/assets/images/ethereum_icon.png"
                        alt
                        class="h-6 md:h-6 mx-auto"
                      />
                    </div>
                  </a>
                </li>
                <li class="pt-4 lg:pt-0 lg:pl-0 w-24">
                  <a href="https://polygon.nft20.io">
                    <div
                      class="
                        h-12
                        text-white
                        uppercase
                        font-primary font-normal
                        leading-none
                        text-sm
                        block
                        rounded-lg
                        lg:rounded-l-none
                        bg-gray-500
                        py-3
                        lg:py-3
                        px-3
                        hover:bg-white hover:text-yellow-300
                        transition
                        duration-300
                        cursor-pointer
                      "
                    >
                      <img
                        src="@/assets/images/polygon_icon.png"
                        alt
                        class="h-6 md:h-6 pt-1 mx-auto"
                      />
                    </div>
                  </a>
                </li>
                <li class="pt-4 lg:pt-0 lg:pl-6 xl:pl-8">
                  <div
                    v-if="account"
                    target="blank"
                    class="
                      text-white
                      uppercase
                      font-primary font-normal
                      leading-none
                      text-sm
                      block
                      rounded-lg
                      lg:rounded-r-none
                      bg-gray-700
                      py-3
                      lg:py-3
                      px-3
                      hover:text-white
                      transition
                      duration-300
                    "
                  >
                    Score:
                    <span id="score" class>{{ score }}</span>
                  </div>
                </li>
                <li
                  class="pt-4 lg:pt-0 lg:pl-0 lg:pt-0"
                  v-if="account"
                  @click.stop="openModal"
                >
                  <span
                    class="
                      text-white
                      lg:text-white
                      uppercase
                      font-primary font-normal
                      leading-none
                      text-sm
                      block
                      rounded-lg
                      lg:rounded-l-none
                      bg-gray-700
                      py-3
                      lg:py-3
                      px-3
                      lg:px-6
                      hover:text-yellow-300
                      transition
                      duration-300
                      cursor-pointer
                    "
                    >{{ account.substring(0, 8) + "..." }}</span
                  >
                </li>
                <li class="pt-4 lg:pt-0 lg:pl-3">
                  <button
                    v-if="!account"
                    v-on:click="connectWallet()"
                    class="
                      text-center
                      captalize
                      font-primary font-normal
                      leading-none
                      inline-block
                      rounded-lg
                      py-3
                      px-4
                      text-brown
                      hover:text-white
                      bg-secondary
                      hover:bg-blue-400 hover:bg-opacity-70
                      transition
                      duration-0
                      focus:outline-none
                    "
                  >
                    Connect Wallet
                  </button>
                  <!-- <div v-else>
                  <button
                    @click="disconnectWallet"
                    class="text-center uppercase font-primary font-bold leading-none inline-block rounded-lg py-3 px-4 text-brown hover:text-white bg-secondary hover:bg-blue-900 hover:bg-opacity-70 transition duration-0 focus:outline-none"
                  >
                    Disconnect
                  </button>
                  </div>-->
                </li>
                <li class="pt-4 lg:pt-0 lg:pl-0 xl:pl-3 hidden">
                  <a
                    href="https://snapshot.org/#/nft20.eth/proposal/0x6b8756c8b836938389e7a592c52f733482dadff954845bfe5698fc17bd020c10"
                    target="_blank"
                    class="
                      flex
                      items-center
                      space-x-4
                      rounded-lg
                      bg-secondary
                      py-3
                      px-3
                      lg:px-6
                      text-brown
                      hover:text-white hover:bg-blue-400 hover:bg-opacity-70
                      transition
                      duration-300
                      cursor-pointer
                    "
                  >
                    <span
                      class="
                        uppercase
                        font-primary font-normal
                        leading-none
                        text-sm
                        block
                      "
                      >Vote on Proposal</span
                    >
                    <span class="w-3 h-3 bg-green-500 rounded-full"></span>
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </header>
    <transition name="modal" v-if="modalOpened">
      <div class="modal-mask">
        <div class="modal-wrapper" @click="closeModal">
          <div
            class="modal-container bg-tertiary rounded-4xl text-white"
            @click="closeModal"
          >
            <div
              class="modal-header flex items-center justify-between uppercase"
            >
              <slot name="header ">
                <div>Account settings</div>
              </slot>
            </div>

            <div class="modal-body">
              <slot name="body">
                <span class="text-xl w-full border">
                  <button
                    @click="updateName"
                    class="
                      w-full
                      text-center
                      uppercase
                      font-primary font-bold
                      leading-none
                      text-2xl
                      inline-block
                      rounded-lg
                      py-4
                      px-5
                      text-brown
                      hover:text-white
                      bg-secondary
                      hover:bg-blue-900 hover:bg-opacity-70
                      transition
                      duration-0
                      focus:outline-none
                    "
                  >
                    Update name
                  </button>

                  <button
                    @click="disconnectWallet"
                    class="
                      mt-2
                      w-full
                      text-center
                      uppercase
                      font-primary font-bold
                      leading-none
                      text-2xl
                      inline-block
                      rounded-lg
                      py-4
                      px-5
                      text-brown
                      hover:text-white
                      bg-secondary
                      hover:bg-blue-900 hover:bg-opacity-70
                      transition
                      duration-0
                      focus:outline-none
                    "
                  >
                    Disconnect wallet
                  </button>
                </span>
              </slot>
            </div>

            <div class="modal-footer z-50">
              <slot name="footer">
                <div class>
                  <button
                    @click="closeModal"
                    class="
                      mt-2
                      w-full
                      text-center
                      uppercase
                      font-primary font-bold
                      leading-none
                      text-2xl
                      inline-block
                      rounded-lg
                      py-4
                      px-5
                      text-brown
                      hover:text-white hover:bg-blue-900 hover:bg-opacity-70
                      transition
                      duration-0
                      focus:outline-none
                    "
                  >
                    Close
                  </button>
                </div>
              </slot>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
import Address from "@/components/Address.vue";
import axios from "axios";
var Personal = require("web3-eth-personal");

export default {
  name: "Header",
  components: { Address },
  data: function () {
    return {
      showMenuMobile: false,
      modalOpened: false,
      // score: 0,
    };
  },
  props: {},
  asyncComputed: {
    async score() {
      return await axios
        .get(`https://gallery.verynifty.io/api/score?account=${this.account}`)
        .then((response) => response.data);
    },
  },
  computed: {
    isConnected: function () {
      return this.$store.state.web3 != null;
    },
    account: function () {
      return this.$store.state.account;
    },
  },
  mounted: async function () {},
  methods: {
    closeModal: function () {
      this.modalOpened = false;
    },
    openModal: function () {
      this.modalOpened = true;
    },
    connectWallet: async function () {
      this.$store.dispatch("connectWallet", this);
    },
    disconnectWallet: function () {
      this.$store.dispatch("disconnectWallet", this);
    },
    toggleMenu: function () {
      this.showMenuMobile = !this.showMenuMobile;
    },
    destroyed: async function () {
      // this.showMenuMobile = !this.showMenuMobile;
    },
    updateName: async function () {
      // let web3 = this.$store.state.web3;
      var personal = new Personal(Personal.givenProvider);
      let name = prompt("Pick your username");
      const sign = await personal.sign(
        web3.fromUtf8(name),
        web3.eth.coinbase,
        async (obj, signature) => {
          try {
            // let changeNameUrl = "http://localhost:7878/name"
            let changeNameUrl = "https://api.nft20.io/name";
            const saveName = await axios.post(changeNameUrl, {
              name: name,
              signature,
              publicAddress: this.account,
            });
          } catch (e) {
            console.log(e);
          }
        }
      );
    },
  },
  beforeDestroy: async function () {
    // this.$store.commit("cancelWeb3");
  },
};
</script>

<style scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}
.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}
.modal-container {
  /* max-height: calc(100vh - 80px); */
  /* overflow-y: hidden; */
  width: 30%;
  margin: 0px auto;
  padding: 30px 30px;
  /* background-color: #fff; */
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
}
.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}
.modal-body {
  margin: 20px 0;
}
.modal-default-button {
  float: right;
}
@media (max-width: 768px) {
  .modal-container {
    /* max-height: calc(100vh - 80px); */
    /* overflow-y: auto; */
    width: 80%;
  }
}
</style>