<template>
  <main class="main-container md:mx-0 mx-16">
    <section class="text-white text-lg mb-96">
      <div class="pt-3 pb-3 mb-10">
        <h1 class="text-2xl uppercase text-center font-medium">NFT Gallery</h1>
        <p
          class="px-2 md:px-10 text-center mt-6 font-light"
        >Paste here your address or connect your wallet to create an awesome wallpaper of your NFT collection.</p>
      </div>
      <div>
        <div class="flex flex-col space-y-4 items-center mb-6">
          <span>Wallet Address:</span>
          <input
            class="rounded-xl bg-gray-700 bg-opacity-50 focus:bg-opacity-70 text-white focus:outline-none text-center text-base px-4 py-4 w-full md:w-1/2"
            type="text"
            style
            v-model="walletAddress"
            placeholder="Paste wallet address"
          />
        </div>

        <div class="w-full md:w-1/3 text-center mx-auto mt-4">
          <a
            v-on:click="goToGallery()"
            class="cursor-pointer text-center font-primary font-normal leading-none text-base block rounded-lg py-4 px-5 text-brown hover:text-white bg-secondary hover:bg-blue-400 hover:bg-opacity-70 transition duration-0 focus:outline-none"
          >See my collection</a>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
const axios = require("axios").default;

export default {
  name: "ImageGallery",
  data: function() {
    return {
      walletAddress: ""
    };
  },
  mounted: async function() {
    if (this.$store.state.account != null) {
      this.walletAddress = this.$store.state.account;
    }
  },
  computed: {
    isConnected: function() {
      return this.$store.state.account != null;
    }
  },
  watch: {
    isConnected: function() {
      if (this.$store.state.account != null) {
        this.walletAddress = this.$store.state.account;
      }
    }
  },
  methods: {
    goToGallery: function() {
        if (this.walletAddress == null && this.walletAddress == "") {
            alert('The account wallet is not valid.')
        } else {
             this.$router.push({ name: 'UserGallery', params: { address: this.walletAddress } })
        }
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
