<template>
  <div class="text-white mt-10 mb-10">
    <div class="flex w-full text-center flex-col space-y-12">
      <div>
        <h1 class="text-5xl font-light text-center w-full leading-tight pb-4">
          NFT20 Contributors
        </h1>
        <h2 class="mt-1 mb-10 text-2xl font-light text-center">
          A list of cool people making the DAO move forward
        </h2>
      </div>
    </div>
    <div class="grid grid-cols-1 md:grid-cols-3 gap-4 mt-1 px-20 md:px-0">
      <div v-for="p in people" :key="p[0]">
        <a :href="'https://twitter.com/' + p[0]">
          <div
            class="
              hover:bg-yellow-200
              dark:hover:bg-yellow-200
              hover:border-transparent
              |
              transition-colors
              duration-500
              bg-white
              hover:bg-red
              text-black
              py-8
              px-104
              text-center
              rounded-md
              shadow-lg
              transform
              h-64
              mx-auto
              justify-center
              flex flex-col
            "
          >
            <img
              class="w-20 h-20 object-cover rounded-full mx-auto shadow-lg"
              :src="'https://unavatar.now.sh/twitter/' + p[0].toLowerCase()"
            />
            <p class="capitalize text-2xl mt-1">{{ p[0] }}</p>
            <p class="mt-1">{{ p[1] }}</p>
          </div>
        </a>
      </div>
    </div>
    <h2 class="mt-10 mb-10 text-xl font-light text-left md:text-center">
      Join the list by building with us and fill
      <a
        href="https://docs.google.com/forms/d/e/1FAIpQLSe9HdRf4QIChUjW5bTc3IFdf-lyNwOU2o6lB92pQ2V4a1EAGw/viewform?usp=sf_link"
        target="_blank"
        class="underline"
        >this form</a
      >
    </h2>
  </div>
</template>

<script>
export default {
  name: "Contributors",
  components: {},
  data: function () {
    return {
      members: [
        ["TheMainHokage", "Writer & Evangelist"],
        ["NotEnea69", "Mascot & Moderator"],
        ["NotoriousSquiz", "Psyops Agent and Meme Lord"],
        ["bottomd0g", "Evangelist & DeFiance rep"],
        ["rilainkill", "Moderator & Guardian of #0"],
        ["MMS88888", "Pool Maker & Game changer"],
        ["grands_marquis", "jules"],
        ["surfcoderepeat", "Adam"],
        ["HisDudeness20", "Animal Catcher, Rabbit Hole Inspector and Pool Boy"],
        ["LamSlimenstein", "Artist"],
        ["RektmeRev", "Artist & Shroud Lord"],
        ["wafflemakr", "Code wizard"],
        ["cadu_veloso", "Graphic Designer"],
        ["DanWolfling", "Evangelist"],
        ["wvizmanos", "Facebook Guru"],
        ["SirviveCapital", "Cool Catillionaire"],
        ["strobecoral", "Code Monkey"],
        ["BennyNFTSteele", "Evangelist"],
        // ["", "Suggestions Guru"],
      ],
    };
  },
  methods: {},

  mounted: async function () {},
  beforeMount: async function () {},
  watch: {},
  computed: {
    people: function () {
      function shuffleArray(array) {
        for (let i = array.length - 1; i > 0; i--) {
          const j = Math.floor(Math.random() * (i + 1));
          [array[i], array[j]] = [array[j], array[i]];
        }
        return array;
      }

      return shuffleArray(this.members);
    },
  },
};
</script>
