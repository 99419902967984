<template>
  <div id="app">
    <Header />
    <section class="max-w-screen-lg mx-auto px-2 md:px-8 py-8">
      <div
        v-if="isCorrectNetwork != null"
        class="
          text-2xl text-white
          font-light
          text-left
          md:text-center
          w-full
          leading-tight
          mt-10
          mb-10
        "
      >
        Please switch your wallet to the {{ isCorrectNetwork }} and reload the
        page.

        <!--  <button
          class="mt-10 text-center tracking-normal font-primary font-normal leading-none text-2xl block md:inline-block rounded-lg py-4 px-5 text-brown hover:text-white bg-secondary hover:bg-blue-400 hover:bg-opacity-70 transition duration-0 focus:outline-none"
          @click="connectToMatic()"
        >
          Switch network using metamask
        </button> -->
      </div>
      <div v-if="isCorrectNetwork == null">
        <router-view />
      </div>
    </section>

    <notifications
      group="foo-velocity"
      position="bottom right"
      animation-type="velocity"
      :speed="500"
    />
    <Footer />
  </div>
</template>
    
<script>
import ConnectButton from "@/components/wallet/ConnectButton.vue";
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";

export default {
  name: "App",
  components: { ConnectButton, Header, Footer },
  data: function () {
    return {};
  },
  beforeMount: async function () {
    // this makes popup for web3modal, not so good

    // if (this.$web3Modal.providerController.cachedProvider != null) {
    //   console.log(
    //     "Connecting back to: ",
    //     this.$web3Modal.providerController.cachedProvider
    //   );
    //   this.$store.dispatch("connectWallet", this);
    // }

    // this is to start with web3 provider no wallet
    this.$store.dispatch("startWeb3", this);
  },
  computed: {
    isConnected: function () {
      return this.$store.state.web3 != null;
    },
    isCorrectNetwork: function () {
      return this.$store.state.correctNetwork;
    },
  },
  methods: {
    connectWallet: function () {
      this.$store.dispatch("connectWallet", this);
    },
    connectToMatic: async function () {
      await ethereum.request({
        method: "wallet_addEthereumChain",
        params: ["0x1"],
      });
      location.reload();
    },
  },
};
</script>


<style lang="scss">
</style>
