<template>
  <div class="mb-96">
    <div
      class="
        text-center text-white text-xl
        flex flex-col
        space-y-2
        tracking-normal
        leading-relaxed
        px-4
      "
    >
   
    <div
      v-if="isLoaded"
      class="flex flex-col space-y-4 justify-center items-center mt-10"
    >
    

      <div
        class="
          text-center text-white text-xl
          flex flex-col
          space-y-2
          tracking-wide
          leading-loose
        "
      >
        Old (remove your funds as these farms are not active)
      </div>

  <FarmCardNFT20
        symbol="MUSE"
        address="0x20d2c17d1928ef4290bf17f922a10eaa2770bf43"
        poolid="0"
        dailyRewards="0"
      />

      <div v-if="museStakerBug" class="flex justify-center">
        <MuseStake
          symbol="MUSE"
          address="0xb6ca7399b4f9ca56fc27cbff44f4d2e4eef1fc81"
          poolid="0"
          dailyRewards="0"
        />
      </div>

      <div v-if="!museStakerBug" class="flex justify-center">
        <MuseStake2
          symbol="MUSE"
          address="0xb6ca7399b4f9ca56fc27cbff44f4d2e4eef1fc81"
          poolid="0"
          dailyRewards="0"
        />
      </div>
      <FarmCardNFT20
        symbol="COOL20"
        address="0x97ae32f530a68a097c5587101854fd8c4789f09c"
        poolid="18"
        dailyRewards="0"
        :newTag="false"
      />
      <FarmCardNFT20
        symbol="KONGZ20"
        address="0x67513ffFec1F9BC1E7D971D067446205049Ffe71"
        poolid="17"
        dailyRewards="0"
        :newTag="false"
      />
      <FarmCardNFT20
        symbol="TABOY20"
        address="0x3435bacbb79baa5c4019419dc21db790d47595d8"
        poolid="16"
        dailyRewards="0"
        :newTag="false"
      />
      <FarmCardNFT20
        symbol="BAYC20"
        address="0xa39ce99cc323472a27602017eeec4f2ea828a737"
        poolid="11"
        dailyRewards="0"
      />

      <FarmCardNFT20
        symbol="GCG"
        address="0xaa9cd48110ab4f3c1703a9b81faf4d84fe81bbd1"
        poolid="14"
        dailyRewards="0"
        :newTag="true"
      />

      <FarmCardNFT20
        symbol="CAT20"
        address="0x1e8703458ad891fbf78c9320cd78a343fb2f73b3"
        poolid="13"
        dailyRewards="0"
        :newTag="false"
      />

      <!-- not ready -->
      <FarmCardNFT20
        symbol="MEEB20"
        address="0xf2ecb7740dc01a16db0ecc0af12ba8e32511bebf"
        poolid="12"
        dailyRewards="0"
      />

      <FarmCardNFT20
        symbol="MASK20"
        address="0xaa617c8726adfde9e7b08746457e6b90ddb21480"
        poolid="5"
        dailyRewards="0"
      />

      <FarmCardNFT20
        symbol="MCAT20"
        address="0x31c507636a4cab752a8a069b865099924bd5f1a9"
        poolid="9"
        dailyRewards="0"
        :warn="true"
      />

      <!-- BGAN Punks -->
      <FarmCardNFT20
        symbol="GPUNKS20"
        address="0xbb1565072fb4f3244ebce5bc8dfeda6baeb78ad3"
        poolid="8"
        dailyRewards="0"
      />
      <FarmCardNFT20
        symbol="MEME20"
        address="0xE14f1283059afA8d3c9c52EFF76FE91854F5D1B3"
        poolid="4"
        dailyRewards="0"
      />

      <FarmCardNFT20
        symbol="DUDES20"
        address="0x04914cb01eec94e320e3a88b3c7a7e9b1609d13c"
        poolid="6"
        dailyRewards="0"
      />

      <FarmCardNFT20
        symbol="CHUBBIES20"
        address="0x75eFD92Ff443c3D3342eaA64Dd32Be387F5ef328"
        poolid="10"
        dailyRewards="0"
      />

      <FarmCard />
      <FarmCardNFT20
        symbol="Chonk20"
        address="0xe775bad5df325f53278288a41cbc8b32ab2217bc"
        poolid="3"
        dailyRewards="0"
      />

      <FarmCardNFT20
        symbol="NDR20"
        address="0x8d816f2c4132fbba4170adfaac772d3ae52fd1db"
        poolid="1"
        dailyRewards="0"
        :warn="false"
      />
      <FarmCardNFT20
        symbol="Doki20"
        address="0x68b049C8BD1d8b20814B41c73A73aB126C19B42e"
        poolid="2"
        dailyRewards="0"
        :warn="false"
      />

      <FarmCardNFT20
        symbol="ROPE20"
        address="0x95acf4ba2c53e31db1459172332d52baac433bb3"
        poolid="7"
        dailyRewards="0"
        :warn="false"
      />
    </div>
  </div>
</template>

<style scoped>
::placeholder {
  color: white;
  opacity: 0.7;
}
</style>
<script>
import FarmCard from "@/components/FarmCard.vue";
import FarmCardNFT20 from "@/components/FarmCardNFT20.vue";
import MuseStake from "@/components/MuseStake.vue";
import MuseStake2 from "@/components/MuseStake2.vue";

export default {
  name: "Farm",
  components: { FarmCard, FarmCardNFT20, MuseStake, MuseStake2 },
  data: function () {
    return {
      search: "",
      museStakerBug: false,
    };
  },
  methods: {
    onAssetClick: function (contract) {
      this.$router.push({ name: "Asset", params: { pair: contract } });
    },
    onAddAssetClicked: function () {
      this.$router.push({ name: "Create", params: {} });
    },
    getMuseStakerInfo: async function () {
      const userInfo = await await this.$store.state.MuseStaker.methods
        .userInfo(this.$store.state.account)
        .call();

      if (userInfo.deposited > 0) {
        this.museStakerBug = true;
      }
    },
  },
  mounted: function () {
    this.getMuseStakerInfo();
  },
  computed: {
    projects: function () {
      return this.$store.state.pairs;
    },
    isLoaded: function () {
      return this.$store.state.isLoaded;
    },
    filteredList() {
      return this.$store.state.pairs.filter((asset) => {
        return asset.name.toLowerCase().includes(this.search.toLowerCase());
      });
    },
  },
};
</script>
