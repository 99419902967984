var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"about text-white mb-80 leading-loose tracking-widest"},[_vm._m(0),(_vm.leaderboard)?_c('vue-good-table',{attrs:{"columns":_vm.columns,"rows":_vm.leaderboard,"sort-options":{
      enabled: true,
      initialSortBy: { field: 'count', type: 'desc' },
    },"pagination-options":{
      enabled: true,
      mode: 'records',
      perPage: 50,
      position: 'top',
      perPageDropdown: [50, 100],
      dropdownAllowAll: false,
      nextLabel: 'next',
      prevLabel: 'prev',
      rowsPerPageLabel: 'Rows per page',
      ofLabel: 'of',
      pageLabel: 'page', // for 'pages' mode
      allLabel: 'All',
      infoFn: function (params) { return ("my own page " + (params.firstRecordOnPage)); },
    }}}):_vm._e()],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex flex-col space-y-10"},[_c('h1',{staticClass:"text-3xl font-semibold w-full text-center"},[_vm._v(" NFT20 Leaderboard ")]),_c('h2',{staticClass:"text-center text-xl w-8/12 mx-auto"})])}]

export { render, staticRenderFns }