<template>
  <div class="home text-white leading-loose tracking-wide">
    <div class="text-xl md:text-4xl text-center px-4 md:px-0">
      MUSE Original NFTs
      <p class="md:text-xl text-lg mt-3">
        For 3 months in the <a href="https://gallery.verynifty.io">VeryNifty Gallery game</a> over 5000 different NFTs battled for survival, for life and MUSE.

        Only 500 survived to tell the tales, no more vNFTs will ever be minted.

        <p class="md:text-xl text-lg mt-3" >
            This is a reminder of where NFT20 came from, only special members of the community own one of these past warriors that are now retired and peaceful collectibles.
        </p>
      </p>
    </div>
    <div
      class="flex w-full md:flex-row flex-col items-center justify-center md:justify-between md:-mx-3"
    >
      <div class="mt-8 flex px-3 items-center"></div>
    </div>
    <div
      class="flex -m-3 md:px-0 px-2 justify-center md:justify-start mt-8 flex-wrap overflow-y-hidden overflow-x-auto"
    >
      <div
        class="md:w-1/4 w-4/5 p-3"
        v-for="(nft, index) in leaderBoard"
        :key="nft.id"
      >
        <NFT
          :contract="nft.contract"
          :id="nft.id"
          :position="index + 1 + perPage * (page - 1)"
          :leaderboard="true"
        />
      </div>

      <t-pagination
        v-if="showLeaderboard"
        :total-items="totalRecords"
        :per-page="perPage"
        v-model="page"
        class="mt-20 w-4/5 m-auot m-0"
      />

      
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

import axios from "axios";
import NFT from "@/components/NFT.vue";
export default {
  name: "Gallery",
  components: {
    NFT,
  },
  data: function () {
    return {
      leaderBoard: [],
      page: 1,
      perPage: 0,
      totalRecords: 0,
      type: "all",
      types: {
        all: "All",
        "57f0b53926dd62f2e26bc40b30140abea474da94": "vNFT",
        e4605d46fd0b3f8329d936a8b258d69276cba264: "Meme Ltd",
      },
    };
  },
  created: function () {
    this.refreshLeaderBoard();
  },
  methods: {
    changePage: function (pageInfo) {
      console.log(pageInfo);
    },
    refreshLeaderBoard: async function () {
      const result = await axios.get(
        `https://gallery.verynifty.io/api/leaderboard/score/${this.page}?type=${this.type}&perPage=`
      );
      console.log(result);
      this.totalRecords = result.data.pagination.total;
      this.perPage = result.data.pagination.perPage;
      this.leaderBoard = [];
      while (this.leaderBoard.length > 0) {
        this.leaderBoard.pop();
      }
      for (const nft of result.data.data) {
        this.leaderBoard.push(nft);
      }
    },
  },
  beforeMount: function () {
    // this.refreshLeaderBoard();
  },
  mounted: function () {},
  computed: {},
  watch: {
    page: function () {
      this.refreshLeaderBoard();
    },
    type: function () {
      this.refreshLeaderBoard();
    },
  },
};
</script>