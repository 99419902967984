<template>
  <div class="about text-white mb-0 leading-loose tracking-widest pt-10 px-6 md:px-0" >
          <ImageGallery />

      <div class="flex w-full text-center flex-col space-y-12 ">
        <div>
          <h1 class="text-5xl sm:text-6xl md:text-6xl 2xl:text-9xl font-light text-left md:text-center w-full leading-tight pb-10">
            NFT Liquidity Protocol
          </h1>
          <h2 class="mt-4 text-2xl font-light text-left md:text-center">
            Trade, swap & sell NFTs.  The NFT20 protocol offers NFT liquidity pools to help developers build the next generation of NFT apps. 
          </h2>
        </div>

        <div class="mt-10  m-auto md:w-2/4 w-3/4">
        <h2 class="mt-2 mb-6 text-2xl flex items-center justify-center " >
          <span>Buy & Sell Your NFTs with 1-click</span>
           <span class="ml-2 text-4xl">👇</span> </h2>
          <Trade />
        </div>

        <div class="space-y-4 md:space-x-8 pb-40">
          <router-link
            to="/assets"
            class="text-center tracking-normal font-primary font-normal leading-none text-2xl block md:inline-block rounded-lg py-4 px-5 text-brown hover:text-white bg-secondary hover:bg-blue-400 hover:bg-opacity-70 transition duration-0 focus:outline-none"
          >
            NFT20 Pools
          </router-link>
          <a
            href="https://docs.nft20.io"
            target="_blank"
            class="text-center tracking-normal font-primary font-normal leading-none text-2xl block md:inline-block rounded-lg py-4 px-5 text-brown hover:text-white bg-white hover:bg-blue-400 hover:bg-opacity-70 transition duration-0 focus:outline-none"
          >
            Documentation
          </a>
        </div>

        <div class="flex flex-col md:flex-row space-y-16 md:space-y-0 text-center tracking-normal justify-center md:justify-between px-16 pb-60">
          <div class="space-y-3">
            <div class="items-center"><span class="text-6xl font-medium">{{totalNfts}}</span><span class="text-5xl text-gray-500 font-medium ml-1">+</span></div>
            <div class="text-base">Total NFTs locked</div>
          </div>
          <div class="space-y-3">
            <div class="items-center"><span class="text-6xl font-medium">{{totalPools}}</span><span class="text-5xl text-gray-500 font-medium ml-1">+</span></div>
            <div class="text-base">Total pools</div>
          </div>
          <div class="space-y-3">
            <div class="items-center"><span class="text-6xl font-medium">5K</span><span class="text-5xl text-gray-500 font-medium ml-1">+</span></div>
            <div class="text-base">NFTs exchanged this week</div>
          </div>
        </div>

        <div class="text-xl font-light text-left md:text-center">
          <p class="mt-8">
            Join the
            <a
              href="https://discord.gg/kfByfux"
              target="_blank"
              class="text-primary"
              >community</a
            >
            and help us find use cases and bring liquidity to NFTs.
          </p>
        </div>

      </div>
    </div>
    <!--<TrendingPools /> -->
  </div>

</template>
<script>
import TrendingPools from "@/components/TrendingPools.vue";
import Trade from "@/components/cas2/Trade.vue";
import ImageGallery from "@/components/gallery/ImageGallery.vue";
import axios from "axios";
export default {
  name: "About",
  components: { TrendingPools, ImageGallery, Trade },
  data: function () {
    return {
      // score: 0,
      totalPools: 0,
      totalNfts: 0,
    };
  },
  beforeMount: async function () {},
  methods: {
    onAssetClick: function (contract) {
      this.$router.push({ name: "Asset", params: { pair: contract } });
    },
    getInfo: async function () {
      const pools = await axios.get(
        "https://api.nft20.io/pools?perPage=1000&page=1"
      );

      const nfts = await axios.get(
        "https://api.nft20.io/nfts?perPage=50&page=1&"
      );
      this.totalPools = pools.data.pagination.total;
      this.totalNfts = nfts.data.pagination.total;
    },
  },
  computed: {},
  mounted: async function () {
    this.getInfo();
  },
};
</script>


<style scoped>
p {
  padding: 0.6rem 0rem;
}
/* video {
  -moz-box-shadow: 0 -22px 22px -22px rgba(0, 0, 0, 0.8) inset;
  -webkit-box-shadow: 0 -22px 22px -22px rgba(0, 0, 0, 0.8) inset;
  box-shadow: 0 -100px 100px -100px rgba(0, 0, 0, 0.8) inset;
} */
</style>