<template>
  <div
    v-if="isLoaded"
    class="hidden md:block"
    style="
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: -1;
    "
  >
    <Particles
      id="tsparticles"
      :particlesInit="particlesInit"
      :particlesLoaded="particlesLoaded"
      :options="options"
    />
  </div>
</template>

<script>
const axios = require("axios").default;

export default {
  name: "ImageGallery",
  data: function () {
    return {
      isLoaded: false,
      options: {
        autoPlay: true,
        detectRetina: true,
        fpsLimit: 60,
        particles: {
          move: {
            attract: {
              enable: true,
              rotate: {
                x: 600,
                y: 1200,
              },
            },
            decay: 0,
            direction: "none",
            distance: 0,
            enable: true,
            gravity: {
              acceleration: 9.81,
              enable: true,
              maxSpeed: 3,
            },
          },
          number: {
            limit: 0,
            value: 10,
          },
          opacity: {
            random: {
              enable: true,
              minimumValue: 0.1,
              maximumValue: 0.4,
            },
            value: 1,
            animation: {
              count: 0,
              enable: true,
              speed: 1,
              sync: false,
              destroy: "none",
              minimumValue: 0.2,
              startValue: "random",
            },
          },
          reduceDuplicates: true,
          rotate: {
            value: 0,
            animation: {
              enable: true,
              speed: 1,
              sync: false,
              startValue: 0,
            },
            direction: "random",
            path: false,
          },
          shape: {
            options: {
              image: [],
            },
            type: "image",
          },
          size: {
            random: {
              enable: true,
              minimumValue: 30,
            },
            value: 70,
          },
        },
        pauseOnBlur: true,
        pauseOnOutsideViewport: false,
        responsive: [],
        themes: [],
      },
    };
  },
  mounted: async function () {
    const { data } = await axios("https://api.nft20.io/activity");
    let images = [];
    for (const trade of data.data) {
      if (images.length < 40) {
        images = images.concat(trade.nft_image);
      }
    }
    for (const image of images) {
      const img = new Image();
      img.src = image;
      await img.decode();
      // img is ready to use
      let imgObj = {
        src: image,
        width: img.width,
        height: img.height,
      };
      this.options.particles.shape.options.image.push(imgObj);
    }
    this.isLoaded = true;
  },
  computed: {
    isConnected: function () {
      return this.$store.state.web3 != null;
    },
  },
  methods: {
    connectWallet: function () {
      this.$store.dispatch("connectWallet", this);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
