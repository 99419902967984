<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper" @click="$emit('close')">
        <div
          class="modal-container bg-tertiary rounded-4xl text-gray-900"
          @click.stop
        >
          <div
            class="modal-header flex items-center justify-between uppercase text-base tracking-normal font-normal"
          >
            <slot name="header ">
              <div>
                Select
                <span v-if="isProjectSelected"
                  >NFTs
                  <span
                    @click="backButton"
                    class="text-xs opacity-70 uppercase ml-2 p-1 cursor-pointer rounded hover:bg-gray-900 hover:bg-opacity-40 hover:text-white"
                    >back</span
                  ></span
                >
                <span v-else>Project</span>
              </div>
              <div @click="$emit('close')" class="cursor-pointer text-lg">
                <img src="@/assets/images/x.svg" alt="" />
              </div>
            </slot>
          </div>

          <div class="modal-body">
            <slot name="body">
              <span class="text-lg w-full border" v-if="isProjectSelected">
                <input
                  type="text"
                  style="border-color: #b2aea8"
                  class="w-full py-3 px-3 rounded-lg bg-gray-200 border-l border-t rounded focus:outline-none"
                  placeholder="Search NFT"
                  v-model="search"
                />
                <div v-if="isLoading" class="mt-4">Loading...</div>
                <div v-if="!isLoading && filteredNfts.length == 0" class="mt-4">
                  <span
                    @click="backButton"
                    class="text-xs opacity-70 uppercase ml-2 p-1 cursor-pointer rounded hover:bg-gray-900 hover:bg-opacity-40 hover:text-white"
                    >You don't have any matching NFT, click to get back</span
                  >
                </div>
                <div class="mt-4 nfts overflow-auto z-0 flex flex-col" style="">
                  <div
                    v-for="nft in filteredNfts"
                    :key="nft.id"
                    class="flex justify-between items-center py-0 my-6 cursor-pointer hover:border"
                  >
                    <div>
                      <a
                        :href="`https://opensea.io/assets/${nft.asset_contract.address}/${nft.token_id}`"
                        target="_blank"
                      >
                        <video
                          class="object-cover w-32 sm:w-40"
                          controls
                          muted
                          autoplay
                          v-if="nft.animation_url && !nft.image_thumbnail_url"
                          :src="nft.animation_url"
                        ></video>
                        <img
                          v-else
                          :src="nft.image_thumbnail_url"
                          alt=""
                          class="object-cover w-32 sm:w-40"
                        />
                      </a>
                    </div>
                    <div class="text-base font-normal">
                      <div class="font-bold">
                        NFT #{{ nft.token_id.slice(0, 8) }}
                      </div>
                      <div class="-mt-2 mb-1">
                        <span
                          class="text-sm"
                          v-if="nft.asset_contract.schema_name == `ERC1155`"
                        >
                          {{ erc1155Availablevalues[nft.token_id] }} Available
                        </span>
                      </div>
                      <div class="cursor-pointer hover:underline">
                        <a
                          :href="`https://opensea.io/assets/${nft.asset_contract.address}/${nft.token_id}`"
                          target="_blank"
                          >View details
                        </a>
                      </div>
                    </div>
                    <div
                      class="flex input px-2 rounded-lg mr-4 py-2"
                      v-if="nft.asset_contract.schema_name == `ERC1155`"
                    >
                      <button
                        class="focus:outline-none"
                        type="button"
                        @click="
                          move(
                            nft,
                            'minus',
                            nft.token_id,
                            erc1155Availablevalues[nft.token_id]
                          )
                        "
                      >
                        <img
                          src="@/assets/images/left_arrow.svg"
                          class="h-3"
                          alt=""
                        />
                      </button>
                      <input
                        type="number"
                        min="0"
                        data-schema="ERC1155"
                        :max="erc1155Availablevalues[nft.token_id]"
                        v-model.number="erc1155values[nft.token_id]"
                        class="w-10 erc1155"
                      />
                      <button
                        class="focus:outline-none"
                        type="button"
                        @click="
                          move(
                            nft,
                            'plus',
                            nft.token_id,
                            erc1155Availablevalues[nft.token_id]
                          )
                        "
                      >
                        <img
                          src="@/assets/images/right_arrow.svg"
                          class="h-3"
                          alt=""
                        />
                      </button>
                    </div>
                    <div v-else-if="nft.asset_contract.schema_name == `ERC721`">
                      <input
                        class="mr-6"
                        type="checkbox"
                        ref="radio721"
                        :id="nft.token_id"
                        :name="nft.token_id"
                        data-schema="ERC721"
                        :data-id="nft.token_id"
                        :value="nft.token_id"
                        v-model="erc721values"
                      />
                    </div>
                  </div>
                </div>
              </span>
              <span v-else>
                <input
                  type="text"
                  style="border-color: #b2aea8"
                  class="w-full py-3 px-3 rounded-lg bg-gray-200 border-l border-t rounded focus:outline-none"
                  placeholder="Search Collectible"
                  v-model="searchProject"
                />
                <div class="mt-4 nfts overflow-auto z-0 flex flex-col" style="">
                  <div
                    v-for="(project, i) in filteredProjects"
                    :key="i"
                    class=""
                  >
                    <div
                      @click="selectProject(project.nftAddress)"
                      class="flex space-x-4 items-center mt-6 cursor-pointer hover:bg-gray-900 hover:bg-opacity-25 py-4 px-4 rounded-l-lg"
                    >
                      <i
                        class="bg-blue-500 rounded-full bg-white h-16 w-16 mr-4 flex items-center justify-center shadow-sm"
                        ><img
                          :src="project.logo"
                          alt=""
                          class="rounded-full object-cover"
                      /></i>
                      <!-- <img :src="token.logo_url" alt="" class="h-10" /> -->
                      <div>{{ project.name }}</div>
                    </div>
                  </div>
                </div>
              </span>
            </slot>
          </div>

          <div class="modal-footer z-50" v-if="isProjectSelected">
            <slot name="footer">
              <div class="">
                <button
                  @click="select"
                  class="w-full text-center uppercase font-primary font-bold leading-none text-2xl inline-block rounded-lg py-4 px-5 text-brown hover:text-white bg-secondary hover:bg-blue-900 hover:bg-opacity-70 transition duration-0 focus:outline-none"
                >
                  Select
                </button>
              </div>
            </slot>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
const axios = require("axios").default;

export default {
  name: "SelectNFTModal",
  components: {},
  props: { userAction: String },
  data: function () {
    return {
      nfts: [],
      isProjectSelected: false,
      isLoading: false,
      projectsAddresses: [
        // // Put the pool address of project there
        // "0x098eb57a36eccde36e8cc91036ac3597e4f07c95", //wicked craniums
        // "0x67bdcd02705cecf08cb296394db7d6ed00a496f9", // acclimated cats
        // "0xf961a1fa7c781ecd23689fe1d0b7f3b6cbb2f972", // Wrapped Mooncat
        // "0xe89d4c65db4c859a83ba7f100154fa2d172b60b0", //"ALiens"
        // "0x9d59eba4deaee09466ba9d4073bf912bc72982b0", //Kongs
        // "0x2dab4ce3490bb50b2ea4c07ab1b6a9cfe29d89b3", //coolcats
        // "0xcccbf11ac3030ee8cd7a04cfe15a3718df6dd030", // Bastard punks,
        // "0xf4b099b821f9e3873a0d5693ea2e327e1cc2b1c6", //dreamloops
        // "0x22fb8d3b3211ee039d0858eccad24df6ea3d09c1", //punks comcs"
        // "0xc475f8d8078a57e6f3725b9b78ca75402f81cc0a", //ducks
        // "0xc2bde1a2fa26890c8e6acb10c91cc6d9c11f4a73", // HASHMASK
        // "0xfb62d159a238e4a51d0496d01a8b48b27c6409db", //bonsai
        // "0xfde43cd91f7f127dbbbc263394519669296396db", //"GCG CATS"
        // "0x4a861fdbfff083b4c87be25ebba96c3751336e1d", //Punk Babies
        // "0x7c15561590fc9eb063b3803b55165633eef207ec", //"BAYC"
        // "0x60acd58d00b2bcc9a8924fdaa54a2f7c0793b3b2", // MEME
        // "0x48bef6bd05bd23b5e6800cf0406e524b517af250", // Chubbies
        // "0x2313e39841fb3809da0ff6249c2067ca84795846", // Nifty Dudes
        // "0xb3cdc594d8c8e8152d99f162cf8f9edfdc0a80a2", //Rope
        // "0xd7b5ad0a361cd05965c1e0f4f2f1d8005ed8c10e", //crypto trunks
        // "0x61be6fac719f871a24d9bdcdf97b6c94772c7e28", //meebits
        // "0x67bdcd02705cecf08cb296394db7d6ed00a496f9", //acclimated mooncats
      ],
      projects: [],
      projectSelected: "null",
      checkedNfts: [],
      erc1155values: {},
      erc1155Availablevalues: {},
      search: "",
      searchProject: "",
      dataSchema: null,
      schema: null,
      nftAddress: null,
      poolAddress: null,
      erc721values: [],
      ether: "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee",
    };
  },
  methods: {
    select: async function () {
      var ids = [],
        qty = [];

      if (this.erc721values.length > 0) {
        this.schema = 721;
        this.nftAddress = this.projectSelected;

        const data = await axios.get(
          `https://api.nft20.io/nfttopool/${this.nftAddress}`
        );

        this.poolAddress = data.data.data[0].address;

        // always 1 because only 1 erc721 can be deposited at the time

        ids = this.erc721values;
        for (var i = 0; i < ids.length; i++) {
          qty.push(1);
        }
      } else if (this.schema == 1155) {
        //   erc1155 part of select
        for (var property in this.erc1155values) {
          if (!this.erc1155values.hasOwnProperty(property)) {
            continue;
          }
          if (this.erc1155values[property] > 0) {
            ids.push(property);
            qty.push(parseInt(this.erc1155values[property]));
          }
        }
      }
      // pasrse data to add to store
      let totalQty = 0;
      for (let q in qty) {
        totalQty += parseInt(qty);
      }

      // here we commit the information of in part of the swap

      // we get the pool data and commit it to then calculate prices, etc and if uni v3
      let pool = await axios.get(
        `https://api.nft20.io/pools?perPage=200&network=0&nft=${this.nftAddress}`
      );

      pool = pool.data.data[0];

      const data = {
        schema: this.schema,
        action: this.userAction,
        nft_address: this.nftAddress,
        pool_address: this.poolAddress,
        poolObject: pool,
        nfts: {
          ids: ids,
          qty: qty,
        },
        nfts_raw: this.nfts,
        totalQty: totalQty,
      };
      // here we set price because it makes more sense for user to always pick nft to get price, wether buying or selling.
      let price = 0;
      if (this.userAction == "deposit") {
        console.log("deposit");

        this.depositAction(data);
      } else if (this.userAction == "withdraw") {
        console.log("withdraw");

        this.withdrawAction(data);
      }

      this.$emit("close");
    },
    depositAction: async function (obj) {
      // add deposit params of nfts to then parse
      this.$store.commit("setCasDeposit", obj);
    },
    withdrawAction: async function (obj) {
      //add withdraw object for nfts to then parse
      this.$store.commit("setCasWithdraw", obj);
    },
    selectProject: async function (project) {
      this.isProjectSelected = true;
      this.projectSelected = project;

      this.isLoading = true;
      await this.getNfts();
      const erc1155values = {};
      this.nfts.forEach((nft) => {
        erc1155values[nft.token_id] = 0;
      });
      this.erc1155values = erc1155values;
      this.isLoading = false;
    },
    getNfts: async function () {
      const account = this.$store.state.account;
      if (this.userAction == "deposit") {
        // asset_contract_address=0x7c40c393dc0f283f318791d746d894ddd3693572

        console.log("DEPOSITINGGG");
        let { data } = await axios.get(
          `https://eth-mainnet.g.alchemy.com/nft/v3/7o_A2UJVRVUs9JrNiN5Hnz0u-8QTItj_/getNFTsForOwner?owner=${account}&contractAddresses[]=${this.projectSelected}&withMetadata=true&pageSize=100`
        );
        console.log(data);
        this.nfts = data.ownedNfts;
        for (let index = 0; index < this.nfts.length; index++) {
          this.nfts[index].token_id = this.nfts[index].tokenId
          this.nfts[index].image_thumbnail_url = this.nfts[index].image.thumbnailUrl
          this.nfts[index].asset_contract = {}
          this.nfts[index].asset_contract.address = this.nfts[index].contract.address
          this.nfts[index].asset_contract.schema_name = this.nfts[index].contract.tokenType

        }
      } else if (this.userAction == "withdraw") {
        const getPool = await axios.get(
          `https://api.nft20.io/nfttopool/${this.projectSelected}`
        );
        const poolAddress = getPool.data.data[0].address;
        const url = `https://api.nft20.io/nfts?perPage=1000&page=1&pool=${poolAddress}`;
        let { data } = await axios.get(url);
        data.data;
        let nfts = data.data.filter((asset) => {
          return parseInt(asset.availabe_quantity) > 0;
        });
        // nfts = data.data;
        for (var i = 0; i < nfts.length; i++) {
          nfts[i].asset_contract = {};
          nfts[i].asset_contract.address = nfts[i]["nft_contract"];

          if (
            nfts[i].nft_contract == "0xe4605d46fd0b3f8329d936a8b258d69276cba264"
          ) {
            nfts[i].asset_contract.schema_name = "ERC1155";
          } else {
            nfts[i].asset_contract.schema_name = "ERC721";
          }
          nfts[i].name = nfts[i]["nft_title"];
          nfts[i].image_thumbnail_url = nfts[i]["nft_image"];
          nfts[i].token_id = nfts[i]["nft_id"];
          delete nfts[i].nft_contract;
          delete nfts[i].nft_title;
          delete nfts[i].nft_image;
          delete nfts[i].nft_id;
        }
        this.nfts = nfts;
      }
      // logic to make erc1155 count numbers
      for (const nft of this.nfts) {
        let account;
        if (this.userAction == "withdraw") account = nft.pool;
        if (this.userAction == "deposit") account = this.$store.state.account;
        if (nft.asset_contract.schema_name == "ERC1155") {
          let contract = await this.$store.state.ERC1155(
            nft.asset_contract.address
          );
          let ids = [];
          let addresses = [];
          addresses.push(account);
          ids.push(nft.token_id);
          let res = await contract.methods
            .balanceOfBatch(addresses, ids)
            .call({ from: this.$store.state.account });
          for (let index = 0; index < res.length; index++) {
            this.erc1155Availablevalues[ids[index]] = parseInt(res[index]);
          }
        }
      }
    },
    move: async function (nft, what, key, max) {
      this.schema = 1155;
      this.nftAddress = nft.asset_contract.address;
      const data = await axios.get(
        `https://api.nft20.io/nfttopool/${this.nftAddress}`
      );
      this.poolAddress = data.data.data[0].address;
      // whne using the erc1155, unchek previously mixed 721
      if (this.$refs.radio721) {
        this.checkedNfts = null;
        for (const input of this.$refs.radio721) {
          input.checked = false;
        }
      }
      if (what == "plus") {
        if (this.erc1155values[key] < max) {
          this.erc1155values[key]++;
        }
      } else {
        if (this.erc1155values[key] > 0) {
          this.erc1155values[key]--;
        }
      }
    },

    backButton: async function () {
      this.projectSelected = null;
      this.isProjectSelected = false;
    },
    getProjects: async function () {
      let { data } = await axios.get(
        "https://api.nft20.io/pools?perPage=500&network=0"
      );

      const projects = data.data;

      let filterProjects = [];
      for (const p of projects) {
        if (parseInt(p.lp_usd_balance) > 600) {
          filterProjects.push(p.address);
        }
      }
      this.projectsAddresses = filterProjects;

      // sort so it is most used to lowest used order
      // filterProjects = filterProjects.sort(function (a, b) {
      //   return (
      //     parseInt(b.total_nft_transfers) - parseInt(a.total_nft_transfers)
      //   );
      // });

      // console.log("getasdad ", filterProjects);

      for (const addy of this.projectsAddresses) {
        this.projects.push(
          this.$store.state.pairs.find((pair) => pair.address === addy)
        );
      }
    },
  },
  beforeMount: async function () {},
  mounted: async function () {
    this.getProjects();

    // if a user already picked nfts to deposit, we only return nfts from the same pool for the swap
    if (
      this.$store.state.casDeposit.totalQty > 0 &&
      this.userAction == "withdraw"
    ) {
      this.selectProject(this.$store.state.casDeposit.nft_address);
    }
  },
  watch: {},
  computed: {
    filteredNfts() {
      return this.nfts.filter((asset) => {
        return asset.token_id.toLowerCase().includes(this.search.toLowerCase());
      });
    },
    filteredProjects() {
      return this.projects.filter((project) => {
        return project.name
          .toLowerCase()
          .includes(this.searchProject.toLowerCase());
      });
    },
  },
  created: async function () {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
::placeholder {
  /* padding: 0px 1rem; */
  /* maybe remove this */
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Hide the browser's default checkbox */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
}
/* On mouse-over, add a grey background color */
.container:hover input ~ .checkmark {
  background-color: #ccc;
}
/* When the checkbox is checked, add a blue background */
.container input:checked ~ .checkmark {
  background-color: #2196f3;
}
/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}
/* Show the checkmark when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}
/* Style the checkmark/indicator */
.container .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.input {
  background-color: #afaca2;
  cursor: pointer;
}
/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
  background-color: #afaca2;
  color: white;
  border-radius: 6px;
  text-align: center;
}
input[type="number"]:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
}
.nfts {
  max-height: calc(340px);
}
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}
.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}
.modal-container {
  /* max-height: calc(100vh - 80px); */
  /* overflow-y: hidden; */
  width: 40%;
  margin: 0px auto;
  padding: 30px 30px;
  /* background-color: #fff; */
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
}
.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}
.modal-body {
  margin: 20px 0;
}
.modal-default-button {
  float: right;
}
@media (max-width: 768px) {
  .modal-container {
    /* max-height: calc(100vh - 80px); */
    /* overflow-y: auto; */
    width: 80%;
  }
}
</style>