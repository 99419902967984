<template>
  <div class="about text-white mb-0 leading-loose tracking-widest px-6 md:px-0">
    <div class="flex w-full text-center flex-col space-y-12">
      <div class="flex items-center justify-between">
        <div class="text-2xl font-semibold">Dashboard</div>
        <div
          class="rounded-lg text-white lg:text-white uppercase font-primary font-normal leading-none tracking-normal text-sm block bg-gray-700 py-2 lg:py-2 px-3 lg:px-6 hover:text-yellow-300 transition duration-300 cursor-pointer shadow-inner border-b border-r border-gray-600"
        >
          <span class="text-lg font-bold">500</span> MUSE
        </div>
      </div>
      <div class="flex tracking-normal">
        <div
          target="blank"
          class="text-gray-900 uppercase font-primary font-normal leading-none text-sm block rounded-l-lg bg-tertiary py-3 md:py-3 md:px-3 hover:text-gray-900 transition duration-300 cursor-pointer border-r border-gray-900"
        >
          Token Balance
        </div>

        <div class="md:pl-0">
          <span
            class="text-white lg:text-white uppercase font-primary font-normal leading-none text-sm block rounded-r-lg bg-gray-500 py-3 lg:py-3 px-3 lg:px-6 hover:text-yellow-300 transition duration-300 cursor-pointer"
            >NFTs in Wallet</span
          >
        </div>
      </div>
      <div id="main">
        <div class="flex space-x-10">
          <div class="w-1/4">
            <div class="flex justify-between items-start">
              <div class="flex items-center text-left">
                <i
                  data-v-1ec2ca20=""
                  class="bg-grey-500 rounded-full bg-white bg-opacity-30 h-11 w-11 mr-4 flex items-center justify-center shadow-sm"
                  ><img
                    data-v-1ec2ca20=""
                    src="https://pbs.twimg.com/profile_images/1359146238264963072/O7AOMif__400x400.jpg"
                    alt=""
                    class="rounded-full object-cover shadow-xl"
                /></i>
                <div class="">
                  <div class="uppercase text-lg font-normal">Rope20</div>
                  <div class="text-xs">Rope</div>
                </div>
              </div>
              <div class="text-lg">900</div>
            </div>
            <div class="mt-8">
              <img
                src="https://placehold.it/225x319"
                alt=""
                class=""
              />
            </div>
          </div>
          <div class="w-1/4">
            <div class="flex justify-between items-start">
              <div class="flex items-center text-left">
                <i
                  data-v-1ec2ca20=""
                  class="bg-grey-500 rounded-full bg-white bg-opacity-30 h-14 w-14 mr-4 flex items-center justify-center shadow-sm"
                  ><img
                    data-v-1ec2ca20=""
                    src="https://pbs.twimg.com/profile_images/1359146238264963072/O7AOMif__400x400.jpg"
                    alt=""
                    class="rounded-full object-cover shadow-xl"
                /></i>
                <div>
                  <div>Rope20</div>
                  <div>Rope</div>
                </div>
              </div>
              <div>900</div>
            </div>
            <div class="mt-6 rounded-xl">
              <img
                src="https://placehold.it/400/400"
                alt=""
                class="rounded-2xl"
              />
            </div>
          </div>
          <div class="w-1/4">
            <div class="flex justify-between items-start">
              <div class="flex items-center text-left">
                <i
                  data-v-1ec2ca20=""
                  class="bg-grey-500 rounded-full bg-white bg-opacity-30 h-14 w-14 mr-4 flex items-center justify-center shadow-sm"
                  ><img
                    data-v-1ec2ca20=""
                    src="https://pbs.twimg.com/profile_images/1359146238264963072/O7AOMif__400x400.jpg"
                    alt=""
                    class="rounded-full object-cover shadow-xl"
                /></i>
                <div>
                  <div>Rope20</div>
                  <div>Rope</div>
                </div>
              </div>
              <div>900</div>
            </div>
            <div class="mt-6 rounded-xl">
              <img
                src="https://placehold.it/400/400"
                alt=""
                class="rounded-2xl"
              />
            </div>
          </div>
          <div class="w-1/4">
            <div class="flex justify-between items-start">
              <div class="flex items-center text-left">
                <i
                  data-v-1ec2ca20=""
                  class="bg-grey-500 rounded-full bg-white bg-opacity-30 h-14 w-14 mr-4 flex items-center justify-center shadow-sm"
                  ><img
                    data-v-1ec2ca20=""
                    src="https://pbs.twimg.com/profile_images/1359146238264963072/O7AOMif__400x400.jpg"
                    alt=""
                    class="rounded-full object-cover shadow-xl"
                /></i>
                <div>
                  <div>Rope20</div>
                  <div>Rope</div>
                </div>
              </div>
              <div>900</div>
            </div>
            <div class="mt-6 rounded-xl">
              <img
                src="https://placehold.it/400/400"
                alt=""
                class="rounded-2xl"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Account",
  components: {},
  data: function () {
    return {
      // score: 0,
    };
  },
  beforeMount: async function () {},
  methods: {},
  computed: {},
  mounted: async function () {},
};
</script>


<style scoped>
p {
  padding: 0.6rem 0rem;
}
/* video {
  -moz-box-shadow: 0 -22px 22px -22px rgba(0, 0, 0, 0.8) inset;
  -webkit-box-shadow: 0 -22px 22px -22px rgba(0, 0, 0, 0.8) inset;
  box-shadow: 0 -100px 100px -100px rgba(0, 0, 0, 0.8) inset;
} */
</style>