<template>
  <div class="mt-10 text-white">
    <p class="text-l">Trending Pools</p>
    <div
      class="text-grey-900 mt-6 grid grid-cols-1 md:px-0 px-12 md:grid-cols-4 gap-8"
    >
      <div
        class="bg-opacity-100 bg-tertiary rounded-5xl p-6 cursor-pointer shadow-md"
        v-for="project in pools"
        :key="project.address"
        v-on:click="onAssetClick(project.address)"
      >
        <AssetCard :project="project" />
      </div>
    </div>
  </div>
</template>


<script>
const axios = require("axios").default;

import AssetCard from "@/components/AssetCard.vue";
export default {
  name: "TrendingPools",
  components: { AssetCard },

  props: {},
  computed: {},
  data: function () {
    return {};
  },
  beforeMount: async function () {},
  methods: {},
  mounted: async function () {},
  computed: {
    pools: function () {
      return this.$store.state.trendingPairs.slice(0, 4);
    },
  },
};
</script>