<template>
  <div class="about text-white mb-80 leading-loose tracking-tight">
    <h1 v-if="!user" class="text-center text-2xl">NFT20 Trade History</h1>
    <div v-if="!user" class="text-center">
      For additional statistics, visit
      <a
        class="underline"
        href="https://stats.nft20.io/public/dashboard/08412657-a9a3-49b0-8e37-280019433f2a"
        >our live dashboard</a
      >.
    </div>

    <div class="flex justify-center items-center flex-col space-y-16">
      <div id="table" class="w-full mt-12">
        <div v-if="!user" class="flex items-center">
          <div  >History</div>
          <div>
            <svg
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 12.814 6.907"
              enable-background="new 0 0 12.814 6.907"
              xml:space="preserve"
              class="h-2 ml-2"
            >
              <path
                fill="white"
                d="M0.146,0.854c-0.195-0.195-0.195-0.512,0-0.707s0.512-0.195,0.707,0L5.907,5.2l0.499,0.464l0.5-0.464 l5.055-5.054c0.195-0.195,0.512-0.195,0.707,0s0.195,0.512,0,0.707L6.762,6.761C6.715,6.807,6.658,6.844,6.598,6.868 C6.537,6.894,6.473,6.907,6.406,6.907c-0.064,0-0.129-0.014-0.189-0.039C6.154,6.844,6.1,6.807,6.053,6.761L0.146,0.854"
              ></path>
            </svg>
          </div>
        </div>
        <div class="flex items-center justify-center w-full">
          <div class="container">
            <table
              class="
                w-full
                flex flex-row flex-no-wrap
                sm:bg-primary-dark
                rounded-lg
                overflow-hidden
                sm:shadow-lg
                my-2
              "
            >
              <thead class="text-dark">
                <tr
                  class="
                    bg-secondary
                    table-row
                    rounded-l-lg
                    sm:rounded-none
                    mb-2
                    sm:mb-0
                  "
                >
                  <th
                    class="
                      py-4
                      px-4
                      md:px-6
                      text-left text-sm
                      md:text-base
                      leading-4
                    "
                    width="20%"
                  >
                    Asset
                  </th>
                  <th
                    class="
                      py-4
                      px-4
                      md:px-6
                      text-left text-sm
                      md:text-base
                      leading-4
                    "
                    width="20%"
                  >
                    Event
                  </th>
                  <th
                    class="
                      py-4
                      px-4
                      md:px-6
                      text-left text-sm
                      md:text-base
                      leading-4
                    "
                    width="23%"
                  >
                    From
                  </th>
                  <th
                    class="
                      py-4
                      px-4
                      md:px-6
                      text-left text-sm
                      md:text-base
                      leading-4
                    "
                    width="17%"
                  >
                    NFTs QTY
                  </th>
                  <th
                    class="
                      py-4
                      px-4
                      md:px-6
                      text-left text-sm
                      md:text-base
                      leading-4
                    "
                    width="20%"
                  >
                    Time
                  </th>
                </tr>
              </thead>
              <tbody class="flex-none bg-tertiary text-dark">
                <tr
                  v-for="(trade, index) in trades"
                  :key="index"
                  class="
                    border-dark border-b
                    table-row
                    mb-2
                    sm:mb-0
                    overflow-x-auto
                    text-xs
                    md:text-base
                  "
                >
                  <td class="py-4 px-4 md:px-6">
                    <a
                      :href="`https://nft20.io/asset/${trade.address}`"
                      target="_blank"
                      class="hover:text-blue-400"
                      >{{ trade.symbol }}</a
                    >
                  </td>

                  <td class="py-4 px-4 md:px-6 text-xs md:text-base">
                    <div class="flex space-x-2 items-center">
                      <img
                        v-if="trade.type == 'Withdraw'"
                        src="@/assets/images/withdraw.svg"
                        alt=""
                        class="h-5"
                      />

                      <img
                        v-if="trade.type == 'Deposit'"
                        src="@/assets/images/deposit.svg"
                        alt=""
                        class="h-5"
                      />
                      <img
                        v-if="trade.type == 'Swap'"
                        src="@/assets/images/swap.svg"
                        alt=""
                        class="h-5"
                      />

                      <div class="capitalize">
                        <a
                          :href="`https://etherscan.io/tx/${trade.transactionhash}`"
                          target="_blank"
                          class="hover:text-blue-400"
                          >{{ trade.type }}</a
                        >
                      </div>
                      <img
                        v-if="
                          trade.to ==
                          '0xa42f6cada809bcf417deefbdd69c5c5a909249c0'
                        "
                        src="@/assets/images/uniswap_dark.svg"
                        alt=""
                        class="h-5"
                      />
                    </div>
                  </td>
                  <td class="py-4 px-5 md:px-6 text-xs md:text-base truncate">
                    <!-- {{ trade.from.slice(0, 2) }} -->
                    <a
                      :href="`https://etherscan.io/address/${trade.from}`"
                      class="hover:text-blue-400"
                      target="_blank"
                    >
                      {{ trade.from.slice(0, 10) }}..</a
                    >
                  </td>
                  <td class="py-4 px-4 md:px-6 hover:font-medium">
                    {{ trade.total_transfers }}
                    <span v-if="parseInt(trade.volume_usd) > 0"
                      >(${{ parseInt(trade.volume_usd) }})</span
                    >
                  </td>
                  <td class="py-4 px-4 md:px-6 text-xs md:text-base leading-3">
                    {{ timeAgo(trade.timestamp) }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div v-if="!user" class="flex flex-col justify-center items-center space-y-6">
        <div>
          <router-link
            to="/assets"
            class="
              cursor-pointer
              text-center
              uppercase
              font-primary font-bold
              leading-none
              text-lg
              inline-block
              rounded-lg
              py-4
              px-5
              text-brown
              hover:text-white
              bg-secondary
              hover:bg-blue-900 hover:bg-opacity-70
              transition
              duration-0
              focus:outline-none
            "
            >Trade NFTs</router-link
          >
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// @ is an alias to /src

const axios = require("axios").default;
import dayjs from "dayjs";
const relativeTime = require("dayjs/plugin/relativeTime");
dayjs.extend(relativeTime);

export default {
  name: "Activity",
  components: {},
  data: function () {
    return {
      trades: [],
    };
  },
  props: {
    user: String,
  },
  beforeMount: async function () {
    this.init();
  },
  methods: {
    init: async function () {
      let query = "https://api.nft20.io/activity?network=0";
      console.log(this.user)
      if (this.user) {
        query = "https://api.nft20.io/activity?network=0&user=" + this.user;
      }
      const { data } = await axios(query);
      this.trades = data.data;
    },
    timeAgo: function (time) {
      const timeAgo = dayjs().to(dayjs(time));
      return timeAgo;
    },
  },
  computed: {},
};
</script>

<style scoped>
p {
  padding: 0.6rem 0rem;
}
table {
  display: inline-table !important;
  table-layout: fixed;
  width: 100%;
}
</style>