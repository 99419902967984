<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper" @click="$emit('close')">
        <div
          class="modal-container bg-tertiary rounded-4xl text-gray-900"
          @click.stop
        >
          <div
            class="
              modal-header
              flex
              items-center
              justify-between
              uppercase
              text-base
              tracking-normal
              font-normal
            "
          >
            <slot name="header " class="text-xl" v-show="!loading"
              >{{ collection_detail.collection_name }} #{{ nft.nft_id }}</slot
            >
            <div class="text-xl cursor-pointer" @click="$emit('close')">X</div>
          </div>

          <div class="modal-body">
            <slot name="body">
              <div class="text-xl font-semibbold" v-if="loading">
                Loading...
              </div>
              <div class="text-xl font-semibbold" v-if="!loading">
                <div class="grid grid-cols-2 gap-4">
                  <div>
                    <img
                      alt="Placeholder"
                      class="block h-auto w-full rounded-sm object-cover"
                      :src="nft.nft_image"
                    />
                  </div>
                  <div>
                    <ul class="text-l f">
                      <li
                        v-for="trait in nft_detail.nft_trait"
                        :key="trait.trait_type"
                        class="mb-2"
                      >
                        {{ trait.trait_type }}: {{ trait.value }}
                      </li>
                    </ul>
                  </div>
                  <div>
                    <button
                      v-if="
                        pool.address !=
                          '0x7a911c71144f4d5a00e4216b1c5b12d9571e9336' &&
                        isAction == 'buy'
                      "
                      @click="buy"
                      class="
                        mt-2
                        w-full
                        text-center
                        uppercase
                        font-primary font-bold
                        leading-none
                        text-2xl
                        inline-block
                        rounded-lg
                        py-4
                        px-5
                        text-brown
                        hover:text-white
                        bg-secondary
                        hover:bg-blue-900 hover:bg-opacity-70
                        transition
                        duration-0
                        focus:outline-none
                      "
                    >
                      Buy for {{ parseFloat((price / 1e18).toFixed(4)) }} ETH
                    </button>
                    <div v-else>
                      <button
                        v-if="!isApprovedForAll && !approvalPending"
                        @click="setApprovalForAll"
                        class="
                          mt-2
                          w-full
                          text-center
                          uppercase
                          font-primary font-bold
                          leading-none
                          text-2xl
                          inline-block
                          rounded-lg
                          py-2
                          px-5
                          text-brown
                          hover:text-white
                          bg-secondary
                          hover:bg-blue-900 hover:bg-opacity-70
                          transition
                          duration-0
                          focus:outline-none
                        "
                      >
                        <span> Approve </span>
                      </button>
                      <div class="text-center font-semibold">
                        <span v-if="approvalPending"> Approving tx ... </span>
                      </div>
                      <button
                        v-if="!isApprovedForAll"
                        class="
                          disabled
                          cursor-not-allowed
                          text-black text-opacity-30
                          bg-gray-400
                          mt-2
                          w-full
                          text-center
                          uppercase
                          font-primary font-bold
                          leading-none
                          text-2xl
                          inline-block
                          rounded-lg
                          py-2
                          px-5
                          transition
                          duration-0
                          focus:outline-none
                        "
                      >
                        Sell for {{ parseFloat((price / 1e18).toFixed(4)) }} ETH
                      </button>

                      <button
                        @click="sell"
                        v-if="isApprovedForAll"
                        class="
                          mt-2
                          w-full
                          text-center
                          uppercase
                          font-primary font-bold
                          leading-none
                          text-2xl
                          inline-block
                          rounded-lg
                          py-2
                          px-5
                          transition
                          duration-0
                          focus:outline-none
                          text-brown
                          hover:text-white
                          bg-secondary
                          hover:bg-blue-900 hover:bg-opacity-70
                        "
                      >
                        Sell for {{ parseFloat((price / 1e18).toFixed(4)) }} ETH
                      </button>
                    </div>

                    <div
                      class="my-2 text-base"
                      v-if="
                        pool != '0x7a911c71144f4d5a00e4216b1c5b12d9571e9336'
                      "
                    >
                      * Quoted price could be higher from what it will cost you
                      because of slippage. You'll get back the difference if
                      any.
                    </div>
                    <div class="underline w-full text-center">
                      <a
                        :href="`https://opensea.io/assets/${nft.nft_contract}/${nft.nft_id}`"
                        target="_blank"
                      >
                        See on opensea
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </slot>
          </div>
          <div class="modal-footer">
            <slot name="footer">
              <div class="flex space-x-4 mt-10"></div>
            </slot>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
const axios = require("axios").default;

export default {
  name: "GalleryItemDetailModal",
  components: {},
  props: {
    nft: Object,
    pool: Object,
    isAction: String,
  },
  data: function () {
    return {
      nft_detail: null,
      collection_detail: null,
      loading: true,
      isApprovedForAll: false,
      approvalPending: false,
    };
  },
  methods: {
    buy: async function () {
      // this.$emit("close"); //not sure

      let minetx = await this.$store.state.NFT20Cas.methods
        .ethForNft(
          this.nft.nft_contract,
          [this.nft.nft_id],
          [1],
          this.$store.state.account,
          this.pool.lp_fee,
          this.pool.uniV3
        )
        .send({
          from: this.$store.state.account,
          value: this.price, ///addd up here if we add fee on buying
        })
        .on("transactionHash", (hash) => {
          console.log("hash of tx ", hash);
          // pass the hash to notify to track it
          this.$store.state.notify.hash(hash);
        });
    },
    sell: async function () {
      // this.$emit("close"); //not sure

      let isErc721 = true;
      if (this.pool.type !== 721) isErc721 = false;

      let minetx = await this.$store.state.NFT20Cas.methods
        .nftForEth(
          this.nft.nft_contract,
          [this.nft.nft_id],
          [1],
          isErc721,
          this.pool.lp_fee,
          this.pool.uniV3
        )
        .send({
          from: this.$store.state.account,
        })
        .on("transactionHash", (hash) => {
          console.log("hash of tx ", hash);
          // pass the hash to notify to track it
          this.$store.state.notify.hash(hash);
        });
    },
    setApprovalForAll: async function () {
      let contract;
      if (this.pool.type == 1155) {
        contract = await this.$store.state.ERC1155(this.nft.nft_contract);
      } else if (this.pool.type == 721) {
        contract = await this.$store.state.ERC721(this.nft.nft_contract);
      }

      this.approvalPending = true;
      await contract.methods
        .setApprovalForAll(this.$store.state.contracts.NFT20Cas, true)
        .send({
          from: this.$store.state.account,
        })
        .on("transactionHash", (hash) => {
          console.log("hash of tx ", hash);
          // pass the hash to notify to track it
          this.$store.state.notify.hash(hash);
        });

      this.approvalPending = false;

      this.init();
    },
    init: async function () {
      // this means is a sell so we add approval ,etc
      if (this.isAction == "sell") {
        let nftContract;
        if (this.pool.type == 1155) {
          nftContract = await this.$store.state.ERC1155(this.nft.nft_contract);
        } else if (this.pool.type == 721) {
          nftContract = await this.$store.state.ERC721(this.nft.nft_contract);
        }
        if (this.$store.state.account) {
          this.isApprovedForAll = await nftContract.methods
            .isApprovedForAll(
              this.$store.state.account,
              this.$store.state.contracts.NFT20Cas
            )
            .call();

          console.log("is approved ", this.isApprovedForAll);
        } else {
          this.isApprovedForAll = false;
        }
      }
    },
  },
  beforeMount: async function () {
    let nft_detail = await axios.get(
      "https://api.nft20.io/nft/" +
        this.nft.nft_contract +
        "/" +
        this.nft.nft_id
    );

    this.nft_detail = nft_detail.data.nft;
    this.collection_detail = nft_detail.data.collection;

    if (this.isAction == "buy") {
      this.price = await this.g_getBuyPrice(this.pool.address, 1);
    } else if (this.isAction == "sell") {
      this.price = await this.g_getSellPrice(this.pool.address, 1);
    }

    this.loading = false;
  },
  mounted: async function () {
    await this.init();
  },
  watch: {},
  computed: {},
  created: async function () {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}
.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}
.modal-container {
  /* max-height: calc(100vh - 80px); */
  /* overflow-y: hidden; */
  width: 40%;
  margin: 0px auto;
  padding: 30px 30px;
  /* background-color: #fff; */
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
}
.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}
.modal-body {
  margin: 20px 0;
}
.modal-default-button {
  float: right;
}
@media (max-width: 768px) {
  .modal-container {
    /* max-height: calc(100vh - 80px); */
    /* overflow-y: auto; */
    width: 80%;
  }
}

.loader {
  border: 3px solid #f3f3f3; /* Light grey */
  border-top: 3px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>