<template>
  <div class="group flex flex-col p-0">
    <div v-if="isSeller" @click="cancelAuction" class="cursor-pointer py-2">
      X
    </div>
    <figure>
      <a
        :href="`https://opensea.io/assets/${project.nftAddress}/${this.auctionInfo._tokenId}`"
        target="_blank"
      >
        <img class="w-full rounded-sm object-cover" alt :src="img" />
      </a>
    </figure>
    <ul class="uppercase text-sm mt-4 text-white">
      <li class="mb-1">
        <dl class="flex justify-between items-center">
          <dt class="font-normal">Current Price</dt>
          <dd class="flex items-center space-x-2">
            <span class="font-bold text-xl">
              {{ price }}
            </span>
            <span> ${{ project.symbol }} </span>
          </dd>
        </dl>
      </li>
      <li>
        <dl class="flex justify-between">
          <dt class="font-normal">Time left</dt>
          <dd class v-if="hasLoadedTimer">
            <span class="font-bold">
              <vac :end-time="endTime">
                <span slot="process" slot-scope="{ timeObj }">
                  {{
                    `${timeObj.d} days and ${timeObj.h}:${timeObj.m}:${timeObj.s}`
                  }}
                </span>
                <span slot="finish">Finished!</span>
              </vac>
            </span>
          </dd>
        </dl>
      </li>
    </ul>
    <button
      type="button"
      class="
        mt-6
        font-normal
        text-xl
        px-0
        py-4
        text-center
        rounded-lg
        text-brown
        hover:text-white
        focus:outline-none
        bg-secondary
        hover:bg-blue-300 hover:bg-opacity-70
        transition
        duration-0
      "
      @click="buyNow"
    >
      Buy Now
    </button>
  </div>
</template>

<script>
const axios = require("axios").default;
import BigNumber from "bignumber.js";

export default {
  name: "AuctionCard",
  props: {
    id: Number,
    img: String,
  },
  data: function () {
    return {
      hasLoadedTimer: false,
      endTime: 0,
      auctionInfo: {},
      // img: "",
      timeLeft: 0,
      price: 0,
      hasTokenApproval: false,
      isSeller: false,
    };
  },
  methods: {
    buyNow: async function () {
      // auction id , price

      if (this.$store.state.account == null) {
        this.$store.dispatch("connectWallet", this);
        return;
      }

      let contract = await this.$store.state.Pair(this.project.address);

      const hasTokenApproval =
        (
          await contract.methods
            .allowance(
              this.$store.state.account,
              this.$store.state.contracts.Auction
            )
            .call()
        ).length >= 18;
      if (!hasTokenApproval) {
        await this.approve();
      }
      let minetx = await this.$store.state.Auction.methods
        .bid(this.id, this.auctionInfo._currentPrice)
        .send({
          from: this.$store.state.account,
        })
        .on("transactionHash", (hash) => {
          // pass the hash to notify to track it
          this.$store.state.notify.hash(hash);
        });
    },
    cancelAuction: async function () {
      let minetx = await this.$store.state.Auction.methods
        .cancelAuctionByAuctionId(this.id)
        .send({
          from: this.$store.state.account,
        })
        .on("transactionHash", (hash) => {
          // pass the hash to notify to track it
          this.$store.state.notify.hash(hash);
        });
    },
    fetchAuctionInfo: async function () {
      if (!this.isConnected && !this.hasLoadedTimer) {
        return;
      }

      let contract = await this.$store.state.Pair(this.project.address);

      this.auctionInfo = await this.$store.state.Auction.methods
        .getAuctionByAuctionId(this.id)
        .call();

      this.hasLoadedTimer = true;

      const timeLeft =
        this.auctionInfo._startedAt * 1000 + this.auctionInfo._duration * 1000;
      this.endTime = timeLeft;
      // get image ,etc from oopensea, this is sample

      this.price = new BigNumber(this.auctionInfo._currentPrice)
        .shiftedBy(-parseInt(18))
        .toFixed(0, 1);

      this.isSeller = this.auctionInfo._seller == this.$store.state.account;

      this.hasTokenApproval =
        (
          await contract.methods
            .allowance(
              this.$store.state.account,
              this.$store.state.contracts.Auction
            )
            .call()
        ).length >= 18;
    },
    approve: async function () {
      let contract = await this.$store.state.Pair(this.project.address);

      let max =
        "115792089237316195423570985008687907853269984665640564039457584007913129639935";
      let remove = "0";
      let approve = await contract.methods
        .approve(this.$store.state.contracts.Auction, max)
        .send({
          from: this.$store.state.account,
        })
        .on("transactionHash", (hash) => {
          // pass the hash to notify to track it
          this.$store.state.notify.hash(hash);
        });

      this.init();
    },
  },
  computed: {
    project: function () {
      return this.$store.state.currentProject;
    },
    isConnected: function () {
      return this.$store.state.pairCount > 0;
    },
  },
  watch: {
    isConnected: function (a) {
      if (a) {
        this.fetchAuctionInfo();
      }
    },
  },
  mounted: async function () {
    this.fetchAuctionInfo();
  },
  created: async function () {},
  // beforeMount: async function () {
  //   this.fetchAuctionInfo();
  // },
};
</script>