<template>
  <main class="main-container md:mx-0 mx-8">
    <section class="text-white text-lg mb-96">
      <div class="pt-3 pb-3 mb-10" >
        <h1 class="text-2xl uppercase text-center font-medium">
          Create a Pool
        </h1>
        <p class="px-2 md:px-10 text-center mt-6 font-light">
          Paste the address of the NFT contract and pay the TX fee, it will
          automatically create a pool to tokenize your NFTs.
        </p>
      </div>
      <div class="flex flex-col bg-tertiary md:w-1/2 mx-auto p-8 rounded-xl">
        <div class="flex flex-col space-y-4 items-center mb-6">
          <div class="flex justify-between w-full">
            <span class="text-dark">NFT Contract Address:</span>
            <router-link
              to="/assets"
              class="
                flex
                cursor-pointer
                text-sm text-white
                items-center
                space-x-2
                rounded
                bg-tertiary
                p-2
              "
            >
              <img src="@/assets/images/x.svg" alt />
            </router-link>
          </div>
          <input
            class="
              rounded-xl
              bg-gray-200 bg-opacity-40
              focus:bg-opacity-100
              text-dark
              border-l border-t border-gray-400
              focus:outline-none
              text-center text-base
              px-4
              py-4
              w-full
              inner-shadow
            "
            type="text"
            style
            v-model="contractAddress"
            v-on:input="updateContractInfos"
            placeholder="Paste Contract address"
          />
        </div>

        <div
          v-if="hasLoadedNft"
          class="
            flex flex-col
            space-y-4
            w-full
            mx-auto
            bg-tertiary
            text-dark
            rounded-xl
          "
        >
          <div class="flex flex-col md:flex-row justify-between gap-8">
            <li class="flex flex-col items-start">
              <p class="uppercase text-sm font-normal pb-2">Symbol:</p>
              <input
                class="
                  rounded-md
                  bg-gray-200 bg-opacity-40
                  border-l border-t border-gray-400
                  focus:bg-opacity-100
                  text-dark
                  focus:outline-none
                  text-left text-sm
                  md:text-base
                  px-4
                  py-2
                  w-full
                "
                type="text"
                style
                v-model="nft.symbol"
                placeholder="Paste Contract address"
              />
            </li>
            <li class="flex flex-col items-start">
              <p class="uppercase text-sm font-normal pb-2">Name:</p>
              <input
                class="
                  rounded-lg
                  bg-gray-200 bg-opacity-40
                  border-l border-t border-gray-400
                  focus:bg-opacity-100
                  text-dark
                  focus:outline-none
                  text-left text-sm
                  md:text-base
                  px-4
                  py-2
                  w-full
                "
                type="text"
                style
                v-model="nft.name"
                placeholder="Paste Contract address"
              />
            </li>
          </div>
          <div class="text-sm opacity-70">
            * you can change your exchange name and token symbol
          </div>
          <li class="flex justify-start items-center pt-4">
            <p class="uppercase text-sm font-normal pr-2">NFT Type:</p>
            <p class="font-bold text-sm">{{ nft.type }}</p>
          </li>
        </div>

        <div
          v-if="hasLoadedNft && !exists"
          class="w-full text-center mx-auto mt-12"
        >
          <a
            v-on:click="onCreatePoolClicked()"
            class="
              cursor-pointer
              text-center
              font-primary font-normal
              leading-none
              text-base
              block
              rounded-lg
              py-4
              px-5
              text-brown
              hover:text-white
              bg-secondary
              hover:bg-blue-900 hover:bg-opacity-70
              transition
              duration-0
              focus:outline-none
            "
            >Create NFT exchange</a
          >
        </div>
        <div
          v-if="hasLoadedNft && exists"
          class="w-full text-center mx-auto mt-12"
        >
          <a
            v-on:click="poolExists()"
            class="
              cursor-pointer
              text-center
              font-primary font-normal
              leading-none
              text-base
              block
              rounded-lg
              py-4
              px-5
              text-brown
              hover:text-white
              bg-secondary
              hover:bg-blue-900 hover:bg-opacity-70
              transition
              duration-0
              focus:outline-none
            "
            >Visit {{ nft.name }} pool</a
          >
        </div>
      </div>

      <div v-if="isUnsupported" class="mt-10 text-2xl">
        This NFT is not supported, contact us if it seems like an error.
      </div>
    </section>
  </main>
</template>

<script>
// @ is an alias to /src

const axios = require("axios").default;

export default {
  name: "Create",
  components: {},
  data: function () {
    return {
      contractAddress: "",
      nft: null,
      hasLoadedNft: false,
      isUnsupported: false,
      exists: false,
    };
  },
  beforeMount: async function () {
    // this.id = this.$route.params.id;
    // for (const project of this.$store.state.projects) {
    //   if (this.id == project.id) {
    //     this.project = project;
    //   }
    // }
  },
  methods: {
    poolExists: function () {
      this.$router.push({
        name: "Asset",
        params: { pair: this.exists.address },
      });
    },
    updateContractInfos: async function () {
      const ZERO_ADDRESS = "0x0000000000000000000000000000000000000000";
      this.exists = false;
      if (this.contractAddress != "" && this.contractAddress.startsWith("0x")) {
        let existingPool;
        for (const project of this.$store.state.pairs) {
          console.log(project)
          if (
            this.contractAddress.toLowerCase() == project.nftAddress.toLowerCase()
          ) {
            existingPool = project;
          }
        }
        if (existingPool != null) {
          this.exists = existingPool;
        }
        //Addlenght of ethereum address to the check
        let openseaResult = await axios.get(
          "https://api.opensea.io/api/v1/asset_contract/" + this.contractAddress,
          {
            headers: {
              "X-API-KEY": "24f66ac0d61f425291793bc376c54ad8",
            },
          }
        );
        this.nft = openseaResult.data;
        this.nft.symbol = `${this.nft.symbol}20`;
        if (this.nft.schema_name == "ERC721") {
          this.nft.type = 721;
        } else if (this.nft.schema_name == "ERC1155") {
          this.nft.type = 1155;
        } else {
          this.isUnsupported = true;
        }

        // for testnet
        // this.nft = {};
        // this.nft.type = 721;
        // this.nft.symbol = "Zinc";
        // this.nft.name = "Zinc";
        // this.nft.address = "0x6B5E013ba22F08ED46d33Fa6d483Fd60e001262e";
        // end for testnet

        // Add check if already exists throug contract call
        // this.hasLoadedNft =
        //   (await this.$store.state.Factory.methods
        //     .nftToAirToken(this.contractAddress)
        //     .call()) !== ZERO_ADDRESS;

        this.hasLoadedNft = true;
      } else {
        this.hasLoadedNft = false;
      }
    },
    onCreatePoolClicked: async function () {
      if (this.$store.state.account == null) {
        this.$store.dispatch("connectWallet", this);
        return;
      }
      console.log(
        "Creating pool for",
        this.nft.name,
        this.nft.address,
        this.nft.type
      );
      let createpooltx = await this.$store.state.Factory.methods
        .nft20Pair(
          this.nft.name,
          this.nft.symbol,
          this.nft.address,
          this.nft.type
        )
        .send({
          from: this.$store.state.account,
        })
        .on("transactionHash", (hash) => {
          // pass the hash to notify to track it
          this.$store.state.notify.hash(hash);
        });
    },
  },
  computed: {},
};
</script>
