<template>
  <div>
    <div
      class="
        text-center
        md:text-left
        w-full
        md:w-full
        flex
        justify-center
        items-center
        flex-col
        space-y-12
      "
    >
      <!-- in -->
      <div
        class="
          text-xl
          font-light
          w-full
          flex flex-col
          space-y-6
          text-gray-900
          bg-tertiary
          py-8
          px-8
          rounded-3xl
          tracking-normal
          text-left
        "
      >
        <div>
          <span class="uppercase text-lg font-normal">
            <span>You deposit </span>

            <!-- <span
              v-if="
                $store.state.casDeposit.schema == null ||
                $store.state.casDeposit.schema == 20
              "
              >ETH</span
            >
            <span v-else>NFTs</span>-->
          </span>
        </div>
        <div class="flex justify-between items-center">
          <div class="flex space-x-2">
            <div
              @click="openSelectNftModal(true, 'deposit')"
              class="
                cursor-pointer
                hover:bg-gray-900 hover:bg-opacity-25
                px-4
                py-2
                rounded-md
                flex
              "
              :class="{
                'bg-secondary':
                  $store.state.casDeposit.schema == 721 ||
                  $store.state.casDeposit.schema == 1155,
              }"
            >
              <div>NFT</div>

              <svg
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 12.814 6.907"
                enable-background="new 0 0 12.814 6.907"
                xml:space="preserve"
                class="w-2 md:w-3 ml-2 md:ml-4"
              >
                <path
                  fill="#231F20"
                  d="M0.146,0.854c-0.195-0.195-0.195-0.512,0-0.707s0.512-0.195,0.707,0L5.907,5.2l0.499,0.464l0.5-0.464 l5.055-5.054c0.195-0.195,0.512-0.195,0.707,0s0.195,0.512,0,0.707L6.762,6.761C6.715,6.807,6.658,6.844,6.598,6.868 C6.537,6.894,6.473,6.907,6.406,6.907c-0.064,0-0.129-0.014-0.189-0.039C6.154,6.844,6.1,6.807,6.053,6.761L0.146,0.854"
                ></path>
              </svg>
            </div>
            <div
              @click="chooseEth('deposit')"
              class="
                cursor-pointer
                hover:bg-gray-900 hover:bg-opacity-25
                px-4
                py-2
                rounded-md
              "
              :class="{
                'bg-secondary': $store.state.casDeposit.schema == 20,
              }"
            >
              ETH
            </div>
          </div>

          <div class="flex space-x-4 items-end">
            <span class="text-45px"> <SwapDetails :action="'deposit'" /> </span>

            <div>
              <span v-if="$store.state.casDeposit.schema == 20">ETH</span>
              <span
                v-else-if="
                  $store.state.casDeposit.schema == 721 ||
                  $store.state.casDeposit.schema == 1155
                "
                >NFTs</span
              >
            </div>
          </div>
        </div>
      </div>

      <!-- icon -->
      <div class="flex">
        <svg
          class="cursor-pointer h-6 transform rotate-180"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          viewBox="0 0 12.814 18.081"
        >
          <path
            fill="#ffffff"
            d="M0.146,12.027c-0.195-0.195-0.195-0.512,0-0.707s0.512-0.195,0.707,0l5.054,5.054V0.5 c0-0.277,0.223-0.5,0.5-0.5s0.5,0.223,0.5,0.5v15.874l5.054-5.054c0.195-0.195,0.512-0.195,0.707,0s0.195,0.512,0,0.707 l-5.906,5.907c-0.046,0.046-0.103,0.083-0.163,0.107c-0.062,0.025-0.126,0.039-0.191,0.039c-0.064,0-0.13-0.014-0.19-0.039 C6.154,18.018,6.1,17.98,6.054,17.935L0.146,12.027"
          />
        </svg>
        <svg
          class="cursor-pointer h-6"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          viewBox="0 0 12.814 18.081"
        >
          <path
            fill="#ffffff"
            d="M0.146,12.027c-0.195-0.195-0.195-0.512,0-0.707s0.512-0.195,0.707,0l5.054,5.054V0.5 c0-0.277,0.223-0.5,0.5-0.5s0.5,0.223,0.5,0.5v15.874l5.054-5.054c0.195-0.195,0.512-0.195,0.707,0s0.195,0.512,0,0.707 l-5.906,5.907c-0.046,0.046-0.103,0.083-0.163,0.107c-0.062,0.025-0.126,0.039-0.191,0.039c-0.064,0-0.13-0.014-0.19-0.039 C6.154,18.018,6.1,17.98,6.054,17.935L0.146,12.027"
          />
        </svg>
      </div>

      <!-- out -->
      <div
        class="
          text-xl
          font-light
          w-full
          flex flex-col
          space-y-6
          text-gray-900
          bg-tertiary
          py-8
          px-8
          rounded-3xl
          tracking-normal
          text-left
        "
      >
        <div>
          <span class="uppercase text-lg font-normal">
            <span>You receive </span>
            <!-- 
            <span
              v-if="
                $store.state.casWithdraw.schema == null ||
                $store.state.casWithdraw.schema == 20
              "
              >ETH</span
            >
            <span v-else>NFTs</span> -->
          </span>
        </div>
        <div class="flex justify-between items-center">
          <div class="flex space-x-2">
            <div
              @click="openSelectNftModal(true, 'withdraw')"
              class="
                cursor-pointer
                hover:bg-gray-900 hover:bg-opacity-25
                px-4
                py-2
                rounded-md
                flex
              "
              :class="{
                'bg-secondary':
                  $store.state.casWithdraw.schema == 721 ||
                  $store.state.casWithdraw.schema == 1155,
              }"
            >
              <div>NFT</div>

              <svg
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 12.814 6.907"
                enable-background="new 0 0 12.814 6.907"
                xml:space="preserve"
                class="w-2 md:w-3 ml-2 md:ml-4"
              >
                <path
                  fill="#231F20"
                  d="M0.146,0.854c-0.195-0.195-0.195-0.512,0-0.707s0.512-0.195,0.707,0L5.907,5.2l0.499,0.464l0.5-0.464 l5.055-5.054c0.195-0.195,0.512-0.195,0.707,0s0.195,0.512,0,0.707L6.762,6.761C6.715,6.807,6.658,6.844,6.598,6.868 C6.537,6.894,6.473,6.907,6.406,6.907c-0.064,0-0.129-0.014-0.189-0.039C6.154,6.844,6.1,6.807,6.053,6.761L0.146,0.854"
                ></path>
              </svg>
            </div>
            <div
              @click="chooseEth('withdraw')"
              class="
                cursor-pointer
                hover:bg-gray-900 hover:bg-opacity-25
                px-4
                py-2
                rounded-md
              "
              :class="{
                'bg-secondary': $store.state.casWithdraw.schema == 20,
              }"
            >
              ETH
            </div>
          </div>

          <div class="flex space-x-4 items-end">
            <span class="text-45px">
              <SwapDetails :action="'withdraw'" />
            </span>

            <div>
              <span v-if="$store.state.casWithdraw.schema == 20">ETH</span>
              <span
                v-else-if="
                  $store.state.casWithdraw.schema == 721 ||
                  $store.state.casWithdraw.schema == 1155
                "
                >NFTs</span
              >
            </div>
          </div>
        </div>
      </div>

      <ExecuteSwap />
    </div>

    <SelectNftModal
      v-if="showNftModal"
      @close="showNftModal = false"
      :userAction="inputType"
    />
  </div>
</template>

<script>
import BigNumber from "bignumber.js";
import SelectNftModal from "@/components/cas2/SelectNftModal.vue";
import SwapDetails from "@/components/cas2/SwapDetails.vue";
import ExecuteSwap from "@/components/cas2/ExecuteSwap.vue";

import Web3EthAbi from "web3-eth-abi";
export default {
  name: "Trade2",
  components: { SelectNftModal, SwapDetails, ExecuteSwap },
  data: function () {
    return {
      showNftModal: false,
      inputType: "in",
      show: true,
    };
  },
  beforeMount: async function () {},
  methods: {
    chooseEth: async function (action) {
      let commitState;
      if (action == "deposit") commitState = "setCasDeposit";
      if (action == "withdraw") commitState = "setCasWithdraw";
      const data = {
        schema: "20",
        action: action,
        nft_address: null,
        pool_address: null,
        nfts: null,
        totalQty: null,
      };
      this.$store.commit(commitState, data);
    },
    openSelectNftModal: async function (bool, type) {
      this.inputType = type;
      if (this.$store.state.account == null) {
        this.$store.dispatch("connectWallet", this);
        return;
      }

      this.showNftModal = !this.showNftModal;
    },
    clearSelect: async function () {
      this.$store.commit("setCasDeposit", {});
      this.$store.commit("setCasWithdraw", {});
    },
  },
  computed: {},
  mounted: async function () {},
};
</script>
