<template>
  <main class="main-container relative">
    <section class>
      <div class="grid grid-cols-1 md:grid-cols-2 gap-8 md:gap-40">
        <div class>
          <h2
            class="
              flex
              text-white
              items-center
              uppercase
              text-3xl
              font-bold
              mb-8
              justify-start
              px-6
              md:px-0
            "
          >
            <i
              class="
                bg-blue-500
                rounded-full
                bg-white
                h-16
                w-16
                mr-4
                flex
                items-center
                justify-center
                shadow-sm
              "
            >
              <img
                class="rounded-full object-cover"
                v-if="project.logo"
                :src="project.logo"
                alt
              />
            </i>
            <div class="flex items-start space-x-4">
              <div>{{ project.name }}</div>

              <div>
                <a
                  :href="`https://etherscan.io/token/${project.address}`"
                  target="_blank"
                >
                  <img
                    src="@/assets/images/logos/etherscan-logo-light-circle.svg"
                    alt=""
                    class="h-4"
                  />
                </a>
              </div>
            </div>
          </h2>
          <div class="md:ml-20">
            <ul class="uppercase text-base mb-8 max-w-xs mx-auto md:mx-0">
              <!-- <li>
                <dl class="flex justify-between">
                  <dt class="font-medium text-white">Total Value Locked:</dt>
                  <dd class="text-white">
                    $
                    <span class="font-bold text-xl text-white">1000</span>
                  </dd>
                </dl>
              </li> -->
              <li>
                <dl class="flex justify-between">
                  <dt class="font-normal text-white">NFTs in Pool:</dt>
                  <dd>
                    <span class="font-medium text-base text-white">
                      {{ project.nftLocked }}
                    </span>
                  </dd>
                </dl>
              </li>
              <li>
                <dl class="flex justify-between">
                  <dt class="font-normal text-white uppercase">
                    ${{ project.symbol }} Supply:
                  </dt>
                  <dd>
                    <span class="font-medium text-base text-white">
                      {{ project.supply }}
                    </span>
                  </dd>
                </dl>
              </li>
              <li>
                <dl class="flex justify-between">
                  <dt class="font-normal text-white">NFTs in pool:</dt>
                  <dd>
                    <span
                      class="
                        font-medium
                        text-base text-white
                        hover:text-yellow-300
                        transition
                        duration-300
                        underline
                      "
                    >
                      <a href="#nfts">Check</a>
                    </span>
                  </dd>
                </dl>
              </li>
              <li>
                <dl class="flex justify-between">
                  <dt class="font-normal text-white">
                    Trade ${{ project.symbol }}:
                  </dt>
                  <dd>
                    <span
                      class="
                        font-medium
                        text-base text-white
                        hover:text-yellow-300
                        transition
                        duration-300
                        underline
                      "
                    >
                      <a target="_blank" :href="project.uniswap">Uniswap</a>
                    </span>
                  </dd>
                </dl>
              </li>
              <li>
                <dl class="flex justify-between">
                  <dt class="font-normal text-white">Liquidity:</dt>
                  <dd>
                    <span class="font-medium text-base text-white"
                      >${{ project.totalLiquidity.toFixed(0) }}</span
                    >
                    <span class="text-white"></span>
                  </dd>
                </dl>
              </li>
              <li>
                <dl class="flex justify-between">
                  <dt class="font-normal text-white">Floor price:</dt>
                  <dd>
                    <span
                      v-if="project.nftBasePrice > 0"
                      class="font-medium text-base text-white"
                      >${{ project.nftBasePrice.toFixed(0) }} ({{
                        project.nftBasePriceEth.toFixed(2)
                      }}ETH)</span
                    >
                    <span
                      v-if="project.nftBasePrice == 0"
                      class="font-medium text-base text-white"
                      >TBD</span
                    >
                    <span class="text-white"></span>
                  </dd>
                </dl>
              </li>

              <li class="hidden">
                <dl class="flex justify-between">
                  <dt class="font-normal text-white">PEPESCORE™</dt>
                  <dd>
                    <span class="font-medium text-base text-white">0</span>
                  </dd>
                </dl>
              </li>
              <li class="mt-4" v-if="project.guide">
                <a :href="project.guide" target="_blank" class="">
                  <dl class="flex justify-between">
                    <dt class="font-normal text-white hover:underline">
                      <span>{{ project.name }} Pool Tutorial</span>

                      <span class="ml-2 text-secondary animate-pulse"
                        >NEW!</span
                      >
                    </dt>
                    <dd></dd>
                  </dl>
                </a>
              </li>
            </ul>
            <div class="w-full flex justify-center md:justify-start">
              <button
                @click="showModal = true"
                class="
                  text-primary
                  uppercase
                  font-primary font-bold
                  leading-none
                  bg-white
                  text-xl
                  inline-block
                  rounded-full
                  py-5
                  px-8
                  hover:bg-blue-400 hover:bg-opacity-70
                  hover:text-white
                  focus:outline-none
                "
              >
                Create Auction
              </button>
            </div>
          </div>
        </div>

        <SwapBox />
      </div>
    </section>
    <!-- <button class="bg-primary text-3xl" @click="flashloan">FLASHLOAN</button> -->
    <CheckoutCart :pool="project" />
    <!-- <ChartView :nft="project.nftAddress" /> -->

    <GalleryView :pool="project" />

    <Auctions />

    <Modal
      v-if="showModal"
      @close="showModal = false"
      :title="`Create A Dutch Auction`"
    >
      <div slot="body" class="flex flex-col space-y-8">
        <div class="flex flex-col space-y-0 text-lg">
          <p>
            Create a dutch auction to sell your NFT for ${{ project.symbol }}.
          </p>
          <p>You can cancel the auction at any time if your NFT is not sold.</p>
        </div>
        <div class="flex flex-col space-y-6">
          <div class="flex flex-col items-start space-y-1">
            <span>NFT ID:</span>
            <input
              class="
                rounded-xl
                bg-bg-main bg-opacity-10
                focus:bg-opacity-15
                text-gray-500
                focus:outline-none
                px-4
                py-4
                w-4/5
              "
              type="text"
              v-model="auction.tokenId"
              placeholder="Contract address"
            />
          </div>
          <div class="flex flex-col items-start space-y-1 mb-6">
            <span
              >Starting price in ${{ project.symbol }}
              <span class="text-sm"
                >(The initial, and high price you're willing to sell at)</span
              >:</span
            >
            <input
              class="
                rounded-xl
                bg-bg-main bg-opacity-10
                focus:bg-opacity-15
                text-gray-500
                focus:outline-none
                px-4
                py-4
                w-40
              "
              type="text"
              v-model="auction.startPrice"
              placeholder="0"
            />
          </div>
          <div class="flex flex-col items-start space-y-1 mb-6">
            <span
              >End Price in ${{ project.symbol }}
              <span class="text-sm"
                >(The lowest price you're willing to sell)</span
              >:</span
            >
            <input
              class="
                rounded-xl
                bg-bg-main bg-opacity-10
                focus:bg-opacity-15
                text-gray-500
                focus:outline-none
                px-4
                py-4
                w-40
              "
              type="text"
              v-model="auction.endPrice"
              placeholder="0"
            />
          </div>
          <div class="flex flex-col items-start space-y-1 mb-6">
            <span>Duration (in hours: example 24 for 1 day):</span>
            <input
              class="
                rounded-xl
                bg-bg-main bg-opacity-10
                focus:bg-opacity-15
                text-gray-500
                focus:outline-none
                px-4
                py-4
                w-40
              "
              type="text"
              style
              v-model="auction.duration"
              placeholder="0"
            />
          </div>

          <div class="text-sm my-4">
            There is a 5% fee that goes to the MUSE holders when the sale is
            completed.
          </div>
        </div>
      </div>
      <div slot="footer" class="mt-10">
        <button
          class="
            text-2xl
            ext-center
            uppercase
            font-primary font-bold
            leading-none
            inline-block
            rounded-lg
            py-4
            px-5
            text-brown
            hover:text-white
            bg-secondary
            hover:bg-blue-900 hover:bg-opacity-70
            transition
            duration-0
            focus:outline-none
          "
          @click="createAuction"
        >
          Create Auction
        </button>
      </div>
    </Modal>
  </main>
</template>

<script>
// @ is an alias to /src
import Auctions from "@/components/Auctions.vue";
import SwapBox from "@/components/SwapBox.vue";
import Modal from "@/components/Modal.vue";
import GalleryView from "@/components/GalleryView.vue";
import CheckoutCart from "@/components/CheckoutCart.vue";
import BigNumber from "bignumber.js";
import NFT20Abi from "@/assets/abis/Pair";
import Web3Utils from "web3-utils";

import ChartView from "@/components/ChartView.vue";

export default {
  name: "Home",
  components: {
    Auctions,
    SwapBox,
    Modal,
    GalleryView,
    CheckoutCart,
    ChartView,
  },
  data: function () {
    return {
      address: "",
      showModal: false,
      auction: {
        startPrice: "",
        tokenId: "",
        endPrice: "",
        duration: "",
      },
      isApprovedForSpend: false,
      infos: {},
      tokenSupply: 0,
      wethContract: null,
    };
  },
  methods: {
    createAuction: async function () {
      if (this.$store.state.account == null) {
        this.$store.dispatch("connectWallet", this);
        return;
      }
      // params needed
      // _nft20Pair, _tokenId, _startingPrice, _endingPrice, _duration;
      if (
        parseInt(this.auction.startPrice) <= parseInt(this.auction.endPrice)
      ) {
        alert("Starting price should be higher than end price");
        return;
      }
      if (parseInt(this.auction.duration) < 1) {
        alert("auction should be at least an hour long");
        return;
      }
      if (this.project.type == 721) {
        await this.approve721(this.auction.tokenId);
      } else if (this.project.type == 1155) {
        await this.approve1155();
      }
      let minetx = await this.$store.state.Auction.methods
        .createAuction(
          this.project.address,
          this.auction.tokenId,
          Web3Utils.toWei(this.auction.startPrice, "ether"),
          Web3Utils.toWei(this.auction.endPrice, "ether"),
          (this.auction.duration * 60 * 60).toString() //calculate in seconds
        )
        .send({
          from: this.$store.state.account,
        })
        .on("transactionHash", (hash) => {
          console.log("hash of tx ", hash);
          // pass the hash to notify to track it
          this.$store.state.notify.hash(hash);
        });
    },
    initialize: async function () {
      this.address = this.$route.params.pair;
      if (this.contract == null && this.stateLoaded) {
        for (const project of this.$store.state.pairs) {
          if (this.address.toLowerCase() == project.address.toLowerCase()) {
            // this.project = project;
            this.$store.commit("setCurrentProject", project);
            console.log("Set current project");
          }
        }
        let contract = await this.$store.state.Pair(this.project.address);
        this.infos = await contract.methods.getInfos().call();
        this.tokenSupply = await contract.methods.totalSupply().call();
        this.tokenSupply = new BigNumber(this.tokenSupply)
          .shiftedBy(-parseInt(18))
          .toFixed(0, 1);
        // get TVL
      }
    },
    approve721: async function (id) {
      let contract = await this.$store.state.ERC721(this.project.nftAddress);
      this.isApprovedForSpend = await contract.methods
        .isApprovedForAll(
          this.$store.state.account,
          this.$store.state.contracts.Auction
        )
        .call();
      if (!this.isApprovedForSpend) {
        await contract.methods
          .setApprovalForAll(this.$store.state.contracts.Auction, true)
          .send({
            from: this.$store.state.account,
          })
          .on("transactionHash", (hash) => {
            console.log("hash of tx ", hash);
            // pass the hash to notify to track it
            this.$store.state.notify.hash(hash);
          });
      }
    },
    approve1155: async function () {
      let contract = await this.$store.state.ERC1155(this.project.nftAddress);
      this.isApprovedForSpend = await contract.methods
        .isApprovedForAll(
          this.$store.state.account,
          this.$store.state.contracts.Auction
        )
        .call();
      // here we need to check how many tokens and what ids were picked to then add
      // ids and amounts as it's 1155.
      if (!this.isApprovedForSpend) {
        let minetx = await contract.methods
          .setApprovalForAll(this.$store.state.contracts.Auction, true)
          .send({
            from: this.$store.state.account,
          })
          .on("transactionHash", (hash) => {
            console.log("hash of tx ", hash);
            // pass the hash to notify to track it
            this.$store.state.notify.hash(hash);
          });
      }
    },

    flashloan: async function () {
      let contract = await this.$store.state.Pair(this.project.address);

      console.log("contract ", contract);
      let minetx = await contract.methods
        .flashLoan(
          [
            15885, 15648, 14643, 14544, 14436, 14189, 13848, 13180, 10926,
            10158,
          ],
          [1, 1, 1],
          "0x1d730a2A0Deaa51392a0A3DAe6c18C7c2cFdC645",
          "0x"
        )
        .send({
          from: this.$store.state.account,
        })
        .on("transactionHash", (hash) => {
          console.log("hash of tx ", hash);
          // pass the hash to notify to track it
          this.$store.state.notify.hash(hash);
        });
    },
  },
  mounted: async function () {
    console.log(this.project);

    // alert(this.project.uniV3);
  },
  beforeMount: async function () {
    this.initialize();
  },
  watch: {
    stateLoaded: function (state, old) {
      if (state) {
        this.initialize();
      }
    },
  },
  computed: {
    projects: function () {
      return this.$store.state.projects;
    },
    stateLoaded: function () {
      return this.$store.state.isLoaded;
    },
    project: function () {
      return this.$store.state.currentProject;
    },
  },
  destroyed: async function () {
    this.$store.commit("setSelectedNfts", []);
    this.$store.commit("setDidSelectNfts", false);
    this.$store.commit("setCasWithdraw", {});
    this.$store.commit("setCasDeposit", {});
  },
};
</script>