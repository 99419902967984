<template>
  <div
    class="rounded flex justify-center items-center border-2 p-4 border-gray-900 flex-col shadow-xl bg-white bg-opacity-25 hover:border-blue-400 cursor-pointer"
    v-if="!table"
  >
    <!-- <div v-if="!isOwner" class="absolute left-0 top-0 p-4 text-1xl">
        Onwed by
        <Address :address="owner" />
      </div>
      <div v-if="isOwner" class="absolute left-0 top-0  p-4 text-3xl">3rd</div>
        -->
    <div class="flex w-full justify-between items-center">
      <div class="text-3xl" v-if="leaderboard">
        {{ addPositionSuffix(position) }}
      </div>
      <div
        v-if="isVnftAlive && leaderboard && isForSale"
        class="text-3xl cursor-pointer"
      >
        <a
          :href="`https://opensea.io/assets/0x57f0b53926dd62f2e26bc40b30140abea474da94/${id}`"
          @click.stop
          title="Buy on OpenSea"
          target="_blank"
          ><img
            style="
              width: 120px;
              border-radius: 5px;
              box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.25);
            "
            src="https://storage.googleapis.com/opensea-static/opensea-brand/buy-button-blue.png"
            alt="Buy on OpenSea badge"
        /></a>
      </div>
      <div
        v-if="!isVnftAlive && leaderboard"
        class="text-3xl cursor-pointer"
        @click.stop="fatality"
      >
        ☠️
      </div>
    </div>
    <div class="flex items-center w-4/5 mt-4">
      <div class="px-1">HP:</div>
      <div class="px-2">{{ hp }}</div>
      <div class="w-full">
        <div class="border rounded border-dark h-6">
          <div
            class="bg-primary py-2 h-full flex text-sm text-dark"
            :style="{ width: `${hp}%` }"
          ></div>
        </div>
      </div>
    </div>

    <img
      v-bind:src="image"
      alt="NFT"
      class="object-contain mb-6 mt-16"
      style="height: 220px"
    />
    <p class="mb-4 text-xl flex flex-col text-center">
      <span class="text-xl">vNFT #{{ id }} </span>
      <span>{{ name }}</span>
    </p>

    <div class="flex space-x-16 text-xl">
      <div class="">
        Score
        <span>{{ score }}</span>
      </div>
      <div class="">
        Level
        <span>{{ level }}</span>
      </div>
    </div>
  </div>
  <tr v-else class="cursor-pointer">
    <td class="px-4 py-2 border border-gray-900" v-if="leaderboard">
      {{ addPositionSuffix(position) }}
    </td>

    <td class="border border-gray-900 px-4 py-2">
      <img
        v-bind:src="image"
        alt="NFT"
        class="object-contain h-24 m-0 m-auto"
        style=""
      />
    </td>
    <td class="border border-gray-900 px-4 py-2">{{ name }} ( #{{ id }})</td>
    <td class="border border-gray-900 px-4 py-2">{{ score }}</td>
    <td class="border border-gray-900 px-4 py-2">{{ level }}</td>
  </tr>
</template>

<script>
const axios = require("axios").default;
import Address from "@/components/Address.vue";
export default {
  name: "NFT",
  components: { Address },
  props: {
    contract: String,
    position: Number,
    id: String,
    onClicked: Function,
    leaderboard: Boolean,
    table: Boolean,
  },
  data: function () {
    return {
      name: "",
      image: "",
      addressOrigin: "",
      idOrigin: "",
      isVnftAlive: false,
      timeUntilStarving: 0,
      timeVnftBorn: 0,
      expectedReward: 0, 
      fatalityReward: 0,
      score: 0,
      level: 0,
      isOwner: false,
      owner: "",
      isForSale: false,
      hp: 0,
    };
  },
  methods: {
    fetchInfos: async function () {
      const vnft = await axios.get(
        `https://gallery.verynifty.io/api/nft/${this.id}`
      );
      this.expectedReward = parseInt(vnft.data.expectedReward);
      this.timeUntilStarving = parseInt(vnft.data.timeUntilStarving);
      this.timeVnftBorn = vnft.data.timeBorn;
      this.score = parseInt(vnft.data.score);
      this.level = parseInt(vnft.data.level);
      this.owner = vnft.data.owner;
      this.isVnftAlive = !vnft.data.isdead;

      this.fatalityReward = parseInt(vnft.data.fatalityReard);

      this.image = vnft.data.image_url;
      const forSale = await axios.get(
        `https://gallery.verynifty.io/api/opensea/${this.id}`
      );
      this.isForSale = forSale.data == this.id;
      this.name = vnft.data.name;
      this.hp = vnft.data.hp_lastseen;
    },

    addPositionSuffix: function (number) {
      // creates 1st 2nd 3rd suffixes
      var j = number % 10,
        k = number % 100;
      if (j == 1 && k != 11) {
        return number + "st";
      }
      if (j == 2 && k != 12) {
        return number + "nd";
      }
      if (j == 3 && k != 13) {
        return number + "rd";
      }
      return number + "th";
    },
  },
  beforeMount: function () {
    this.fetchInfos();
  },
  mounted: function () {},
  watch: {},
  computed: {},
};
</script>

