<template>
<div>
  <div class="full-width">
     <div v-if="!isConnected" class="text-center text-xl w-12/12 mx-auto mt-20 mb-20 text-white text-2xl">
       Please connect your wallet
      </div>
    </div>
    <div v-if="isConnected" class=" full-width">
        <h2
            class="
              flex
              text-white
              items-center
              uppercase
              text-3xl
              font-bold
              mb-8
              justify-start
              px-6
              md:px-0
            "
          >
            <i
              class="
                bg-blue-500
                rounded-full
                bg-white
                h-20
                w-20
                mr-4
                flex
                items-center
                justify-center
                shadow-sm
              "
            >
              <img
                class="rounded-full object-cover"
                :src="`https://robohash.org/${address}?set=4`"
                alt
              />
            </i>
            <div class="flex items-start space-x-4">
              <div>{{ address | shorterAddress }}</div>

              <div>
                <a
                  :href="`https://etherscan.io/address/${address}`"
                  target="_blank"
                >
                  <img
                    src="@/assets/images/logos/etherscan-logo-light-circle.svg"
                    alt=""
                    class="h-4"
                  />
                </a>
              </div>
            </div>
          </h2>
            <div
            v-on:click="selectedTab = 'Wallet'"
            class="
              cursor-pointer
              text-center
              uppercase
              font-primary font-bold
              leading-none
              text-lg
              inline-block
              rounded-lg
              py-4
              px-5
              text-brown
              hover:text-white
              hover:bg-blue-900 hover:bg-opacity-70
              transition
              duration-0
              focus:outline-none
            "
            :class="{'bg-secondary': selectedTab == 'Wallet'}"
            >Collection</div>
              <div
              v-on:click="selectedTab = 'Activity'"
            class="
            ml-5
              cursor-pointer
              text-center
              uppercase
              font-primary font-bold
              leading-none
              text-lg
              inline-block
              rounded-lg
              py-4
              px-5
              text-brown
              hover:text-white
              hover:bg-blue-900 hover:bg-opacity-70
              transition
              duration-0
              focus:outline-none
            "
            :class="{'bg-secondary': selectedTab == 'Activity'}"
            >Activity</div>
          <div>
            <div v-if="selectedTab == 'Wallet'" class="mt-4">

      <div class=" md:grid grid-rows-3 md:grid-flow-col gap-8 ">
      <div class="row-span-3 leftCol md:h-3/6 mb-4 md:mb-0">
        <CollectionList
          :collections="collections"
          @selected-collection="collectionSelected"
        />
      </div>
      <div class="row-span-2 col-span-4">
        <CollectionBanner v-if="collection != null" :collection="collection" />

        <NFTList :nfts="nfts" :collections="collections" :owner="isOwner" />
      </div>
    </div>
        </div>
  </div>
    </div>

  <div v-if="selectedTab == 'Activity'">
              <Activity v-if="address && address != ''" :user="address" /> 

    </div>
    </div>
  </div>
</template>

<script>
import NFTList from "@/components/wallet/NFTList.vue";
import CollectionBanner from "@/components/wallet/CollectionBanner.vue";
import CollectionList from "@/components/wallet/CollectionList.vue";
import Activity from "@/views/Activity.vue";

import axios from "axios";

export default {
  name: "Wallet",
  components: { NFTList, CollectionList, CollectionBanner, Activity },
  data: function () {
    return {
      address: "",
      internal_nfts: [],
      collections: [],
      collection: null,
      startedLoading: false,
      isLoaded: false,
      selectedTab: "Wallet",
      isOwner: false
    };
  },
  beforeMount: async function () {},
  methods: {
    initialize: async function () {
      if (!this.stateLoading && this.stateLoaded && this.isConnected) {
        if (this.$route.query.address != null) {
          this.address = this.$route.query.address.toLowerCase();
        } else {
          this.address = this.$store.state.account.toLowerCase();
          this.$router.replace({
            path: this.$route.path,
            query: { address: this.address },
          });
        }
        this.isOwner = this.address.toLowerCase() == this.$store.state.account.toLowerCase();
        this.stateLoading = true;
        this.startedLoading = true;
        await this.populateCollection();
        this.isLoaded = true;
      }
    },
    populateCollection: async function () {
      let NFTS = [];
      let offset = 0;
      let fetching = true;
      while (fetching) {
        let { data } = await axios.get(
          `https://api.opensea.io/api/v1/assets?owner=${this.address}&order_direction=desc&offset=${offset}&limit=50`,
          {
            headers: {
              "X-API-KEY": "24f66ac0d61f425291793bc376c54ad8",
            },
          }
        );
        NFTS = NFTS.concat(data.assets);
        offset += 50;
        if (data.assets.length == 0 || data.assets.length % 50 != 0) {
          fetching = false;
        }
      }
      let tmpCollections = {};
      let index = 0;
      for (const nft of NFTS) {
        nft.nft_contract = nft.asset_contract.address;
        nft.nft_image = nft.image_preview_url;
        nft.nft_id = nft.token_id;
        nft.index = index++;
        if (tmpCollections[nft.asset_contract.address] == null) {
          let pool = this.$store.state.pairs.find(
            (pool) => pool.nftAddress == nft.asset_contract.address
          );
          tmpCollections[nft.asset_contract.address] = Object.assign(
            nft.collection,
            nft.asset_contract
          );
          tmpCollections[nft.asset_contract.address].twentyPool = pool;
          tmpCollections[nft.asset_contract.address].owned = 1;
        } else {
          tmpCollections[nft.asset_contract.address].owned += 1;
        }
      }
      this.collections = Object.values(tmpCollections).sort(
        (b, a) => a.owned - b.owned
      );
      this.internal_nfts = NFTS;
    },
    collectionSelected: async function (collection) {
      this.collection = collection;
    },
  },
  computed: {
    nfts: function () {
      if (this.collection != null) {
        return this.internal_nfts.filter(
          (element) => element.asset_contract.address == this.collection.address
        );
      }
      return this.internal_nfts;
    },
    stateLoaded: function () {
      return this.$store.state.isLoaded;
    },
    twentyPools: function () {
      return this.$store.state.pairs;
    },
    isConnected: function () {
      return this.$store.state.account;
    },
  },
  filters: {
    shorterAddress: function (address) {
      if (!address) {
        return "";
      }
      return (
        address.substring(0, 6) +
        "..." +
        address.substring(address.length - 5, address.length)
      );
    },
  },
  watch: {
    stateLoaded: function (state, old) {
      if (state) {
        this.initialize();
      }
    },
    isConnected: async function () {
      this.initialize();
    },
  },
  mounted: async function () {
    this.initialize();
  },
};
</script>


<style scoped>
p {
  padding: 0.6rem 0rem;
}
.full-width {
  padding: 0px 30px;
  width: 100vw;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
}
.leftCol {
  overflow-y: scroll;
  top: 0;
  bottom: 0;
}
@media (min-width: 768px) {
  .leftCol {
    height: 40vw;
  }
}
</style>