<template>
  <main class="main-container text-white p-4">
    <div class="flex flex-col space-y-8">
      <h1 class="text-3xl font-semibold w-full text-center">
        Wrapped CryptoCats
      </h1>
      <h2 class="text-center text-xl w-8/12 mx-auto">
        Turn your
        <a
          href="https://cryptocats.thetwentysix.io/#"
          target="_blan"
          class="text-secondary hover:text-primary"
          >CryptoCats</a
        >
        into ERC721 and trade on Opensea and NFT20.
      </h2>
    </div>
    <div v-if="!isWalletConnected">
      <div class="flex flex-col w-auto mx-auto items-center space-y-8">
        <h2 class="text-center text-xl w-12/12 mx-auto mt-20">
          Connect your wallet to wrap/unwrap your crypto cats.
        </h2>
        <button
          v-on:click="connectWallet()"
          class="
            w-full
            sm:w-3/12
            block
            text-center
            captalize
            font-primary font-normal
            leading-none
            rounded-lg
            py-3
            mt-4
            mb-4
            px-4
            text-brown
            hover:text-white
            bg-secondary
            hover:bg-blue-400 hover:bg-opacity-70
            transition
            duration-0
            focus:outline-none
          "
        >
          Connect Wallet
        </button>
      </div>
    </div>
    <div v-if="isWalletConnected">
      <div>
        <div class="mt-12 flex space-x-0">
          <div
            class="hover:text-secondary text-xl cursor-pointer"
            @click="showWrapped = false"
            :class="{ 'text-secondary': !showWrapped }"
          >
            <span
              v-if="showWrapped"
              class="
                text-gray-900
                uppercase
                font-primary font-normal
                leading-none
                text-sm
                block
                rounded-l-lg
                bg-tertiary
                hover:bg-gray-500
                py-3
                px-3
                hover:text-white
                transition
                duration-300
                cursor-pointer
                border-r border-gray-900
              "
              >My Crypto Cats</span
            >
            <span
              v-if="!showWrapped"
              class="
                text-white
                uppercase
                font-primary font-normal
                leading-none
                text-sm
                block
                rounded-l-lg
                bg-gray-500
                hover:bg-gray-500
                py-3
                px-3
                hover:text-white
                transition
                duration-300
                cursor-pointer
                border-r border-gray-900
              "
              >My Crypto Cats</span
            >
          </div>
          <div
            class="hover:text-secondary text-xl cursor-pointer"
            @click="showWrapped = true"
            :class="{ 'text-secondary': showWrapped }"
          >
            <span
              v-if="showWrapped"
              class="
                text-white
                uppercase
                font-primary font-normal
                leading-none
                text-sm
                block
                rounded-r-lg
                bg-gray-500
                hover:bg-gray-500
                py-3
                px-3
                hover:text-white
                transition
                duration-300
                cursor-pointer
              "
              >My Wrapped Cats</span
            >
            <span
              v-if="!showWrapped"
              class="
                text-gray-900
                uppercase
                font-primary font-normal
                leading-none
                text-sm
                block
                rounded-r-lg
                bg-tertiary
                hover:bg-gray-500
                py-3
                px-3
                hover:text-white
                transition
                duration-300
                cursor-pointer
              "
              >My Wrapped Cats</span
            >
          </div>
        </div>
      </div>
      <div v-if="isLoading" class="mt-6 text-xl">
        Loading cats {{ this.counter }}/625 ..
      </div>
      <div id="unwrappedCats" v-if="!showWrapped" class="mt-10">
        <div class="grid grid-cols-2 sm:grid-cols-4 gap-6">
          <div v-for="cat in userCats" :key="cat.id">
            <div
              class="
                flex flex-col
                items-center
                space-y-4
                rounded-lg
                p-4
                bg-white
              "
            >
              <a
                :href="`https://cryptocats.thetwentysix.io/#cbp=cats/${cat.id}.html`"
                target="_blank"
              >
                <img
                  :src="`https://cryptocats.thetwentysix.io/contents/images/cats/${cat.id}.png`"
                  alt
                  class="h-48 sm:h-40"
                />
              </a>

              <div class="text-dark">ID: {{ cat.id }}</div>

              <div class="w-full">
                <button
                  @click="wrap(cat.id)"
                  class="w-full focus:outline-none cursor-pointer"
                >
                  <span
                    class="
                      w-full
                      text-center
                      captalize
                      font-primary font-normal
                      leading-none
                      inline-block
                      rounded-md
                      py-3
                      px-4
                      text-brown
                      hover:text-white
                      bg-secondary
                      hover:bg-blue-400 hover:bg-opacity-70
                      transition
                      duration-0
                      focus:outline-none
                    "
                    >Wrap</span
                  >
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="wrappedCats" v-if="showWrapped" class="mt-10">
        <div class="grid grid-cols-2 sm:grid-cols-4 gap-6">
          <div v-for="cat in userWrappedCats" :key="cat.id">
            <div
              class="
                flex flex-col
                items-center
                space-y-4
                rounded-lg
                p-4
                bg-white
              "
            >
              <a
                :href="`https://cryptocats.thetwentysix.io/#cbp=cats/${cat.id}.html`"
                target="_blank"
              >
                <img
                  :src="`https://cryptocats.thetwentysix.io/contents/images/cats/${cat.id}.png`"
                  alt
                  class="h-48 sm:h-40"
                />
              </a>

              <div class="text-dark">ID: {{ cat.id }}</div>

              <div class="w-full">
                <button
                  @click="unwrap(cat.id)"
                  class="w-full focus:outline-none cursor-pointer"
                >
                  <span
                    class="
                      w-full
                      text-center
                      captalize
                      font-primary font-normal
                      leading-none
                      inline-block
                      rounded-md
                      py-3
                      px-4
                      text-brown
                      hover:text-white
                      bg-secondary
                      hover:bg-blue-400 hover:bg-opacity-70
                      transition
                      duration-0
                      focus:outline-none
                    "
                    >Unwrap</span
                  >
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="mt-32">
      This was created in a few hours of code. We are working on a new site for
      a standalone
      <a href="https://wrappedcats.com" target="_blank">WrappedCats.com</a> ,
      any help from the community to speed up the process is appreciated.

      <!-- <div class="mt-4">
        If you trade in OpenSea there is a 2.5% fee, we will send 1.5% to the
        original deployer address of CryptoCats (the creators) and 1% for us to
        keep developing this further, if the community wants we can lower/raise
        the fees on Opensea as per request.
      </div> -->
    </div>
  </main>
</template>

<script>
// @ is an alias to /src
// 0x088c6ad962812b5aa905ba6f3c5c145f9d4c079f cryptocats contract
export default {
  name: "CryptoCats",
  components: {},
  data: function () {
    return {
      catsCore: null,
      wrappedCats: null,
      userWrappedCats: [],
      userCats: [],
      showWrapped: false,
      initialized: false,
      isLoading: null,
      counter: 0,
    };
  },
  methods: {
    initialize: async function () {
      //set up cats core
      //set wrapped cats
      if (this.initialized || this.$store.state.account == null) {
        return;
      }
      this.initialized = true;
      this.isLoading = true;
      await this.getUserCoreCats();
      await this.getUserWrappedCats();
      this.isLoading = false;
    },
    getUserWrappedCats: async function () {
      let listSize = await this.$store.state.WrappedCats.methods
        .balanceOf(this.$store.state.account)
        .call();
      for (let index = 0; index < listSize; index++) {
        let id = await this.$store.state.WrappedCats.methods
          .tokenOfOwnerByIndex(this.$store.state.account, index)
          .call();

        this.userWrappedCats.push({
          id: id,
          img: `https://cryptocats.thetwentysix.io/contents/images/cats/${index}.png`,
        });
      }
    },
    getUserCoreCats: async function () {
      let listSize = await this.$store.state.CatsCore.methods
        ._totalSupply()
        .call();
      let coreCats = [];
      for (var i = 1; i <= listSize; i++) {
        const wallet = await this.$store.state.CatsCore.methods
          .catIndexToAddress(i)
          .call();
        // if owner then push to array
        if (wallet.toLowerCase() == this.$store.state.account.toLowerCase()) {
          this.userCats.push({
            id: i,
            img: `https://cryptocats.thetwentysix.io/contents/images/cats/${i}.png`,
          });
        }

        this.counter += 1;
      }
    },
    unwrap: async function (id) {
      // add unwrap methods
      await this.$store.state.WrappedCats.methods
        .unwrap(id)
        .send({
          from: this.$store.state.account,
        })
        .on("transactionHash", (hash) => {
          console.log("hash of tx ", hash);
          // pass the hash to notify to track it
          this.$store.state.notify.hash(hash);
        });

      alert(`Your cat id ${id} was unwrapped successfully!`);

      this.$forceUpdate();
      this.initialized = false;
      this.userWrappedCats = [];
      this.userCats = [];
      await this.initialize();
    },
    wrap: async function (id) {
      alert("You'll need to approve 2 txs");
      // // add wrap methods
      let catDetails = await this.$store.state.CatsCore.methods
        .catsForSale(id)
        .call();

      // check is for sale to contract address and if no then run it like approve
      if (
        catDetails.sellOnlyTo.toLowerCase() !==
        this.$store.state.contracts.WrappedCats.toLowerCase()
      ) {
        console.log("Sending tx to offer for sale");
        await this.$store.state.CatsCore.methods
          .offerCatForSaleToAddress(
            id,
            0,
            this.$store.state.contracts.WrappedCats
          )
          .send({ from: this.$store.state.account })
          .on("transactionHash", (hash) => {
            console.log("hash of tx ", hash);
            // pass the hash to notify to track it
            this.$store.state.notify.hash(hash);
          });
      }

      console.log("wrapping");
      // wrap the cat
      await this.$store.state.WrappedCats.methods
        .depositCatAndMintToken(id)
        .send({ from: this.$store.state.account })
        .on("transactionHash", (hash) => {
          console.log("hash of tx ", hash);
          // pass the hash to notify to track it
          this.$store.state.notify.hash(hash);
        });

      alert(`Your cat id ${id} was wrapped successfully!`);
      this.initialized = false;
      this.userWrappedCats = [];
      this.userCats = [];

      await this.initialize();
    },
    connectWallet: async function () {
      this.$store.dispatch("connectWallet", this);
    },
  },
  mounted: async function () {},
  beforeMount: async function () {
    this.initialize();
  },
  watch: {
    isWalletConnected: function (state, old) {
      if (state) {
        this.initialize();
      }
    },
  },
  computed: {
    isWalletConnected: function () {
      return this.$store.state.account != null;
    },
  },
};
</script>
