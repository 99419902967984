<template>
  <div
    class="
      about
      text-white
      leading-loose
      tracking-normal
      pt-10
      px-6
      md:px-0
      mb-48
    "
  >
    <div class="flex w-full text-center flex-col space-y-12">
      <h1 class="text-2xl">
        Fees not turned into $MUSE yet by NFT20 based on current NFT prices
        (updates live):
        <div
          class="
            items-end
            text-secondary
            mt-16
            bg-gray-600
            w-full
            sm:w-1/2
            md:w-1/3
            mx-auto
            px-8
            pt-9
            pb-8
            rounded-lg
            border-r border-b border-gray-500
            shadow-inner
          "
        >
          <span class="text-2xl text-gray-800">$ </span
          ><span class="text-5xl font-normal text-gray-800">{{
            feesEarned.toFixed(2)
          }}</span>
        </div>
      </h1>

      <div clsas="flex flex-row text-xl">
        <div class="text-secondary">CONNECT TO METAMASK TO SEE IT.</div>
        <div>All fees earned by NFT20 are distributed to MUSE holders.</div>
      </div>
      <table class="table-auto">
        <thead>
          <tr>
            <th>Name</th>
            <th>Balance 20's</th>
            <th>$value</th>
            <th>Liquidity</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="p in projects" :key="p.name">
            <td>{{ p.name }}</td>
            <td>{{ p.balance }} {{ p.symbol }}</td>
            <td>${{ p.amount_usd }}</td>
            <td>${{ p.liquidity_usd }}</td>
          </tr>
        </tbody>
      </table>
      <div></div>
    </div>
  </div>
</template>
<script>
import BigNumber from "bignumber.js";
import axios from "axios";
export default {
  name: "Fees",
  components: {},
  data: function () {
    return {
      pools: [],
      feesEarned: 0,
      loaded: false,
      projects: [],
    };
  },
  beforeMount: async function () {},
  methods: {
    getData: async function () {
      if (this.loaded || !this.$store.state.web3) {
        return;
      }
      this.loaded = true;
      this.feesEarned = 0;
      const factory = "0x0f4676178b5c53ae0a655f1b19a96387e4b8b5f2";

      let pools = await axios.get(
        "https://api.nft20.io/pools?perPage=5000&page=1"
      );

      pools = pools.data.data;
      console.log(pools);
      for (const pool of pools) {
        if (pool.nft_usd_price > 0) {
          let pricePerToken;
          if (pool.name == "AAH") {
            pricePerToken = pool.nft_usd_price / 1052631.5;
          } else {
            pricePerToken = pool.nft_usd_price / 100;
          }
          const contract = await this.$store.state.Pair(pool.address);

          let balance = await contract.methods.balanceOf(factory).call();
          balance = new BigNumber(balance)
            .shiftedBy(-parseInt(18))
            .toFixed(0, 1);

          this.feesEarned += balance * pricePerToken;
          if (pricePerToken > 0) {
            this.projects.push({
              amount_usd: parseInt(balance * pricePerToken),
              balance: balance,
              name: pool.name,
              symbol: pool.symbol,
              liquidity_usd: parseInt(pool.lp_usd_balance),
            });
          }
          this.projects.sort(function (b, a) {
            return a.amount_usd - b.amount_usd;
          });
        }
      }

      //   console.log("balance ", balance.toString());
    },
  },
  watch: {
    isConnected: function () {
      this.getData();
    },
  },
  computed: {
    isConnected: function () {
      return this.$store.state.web3 != null;
    },
  },
  mounted: async function () {
    this.getData();
  },
};
</script>


<style scoped>
p {
  padding: 0.6rem 0rem;
}
/* video {
  -moz-box-shadow: 0 -22px 22px -22px rgba(0, 0, 0, 0.8) inset;
  -webkit-box-shadow: 0 -22px 22px -22px rgba(0, 0, 0, 0.8) inset;
  box-shadow: 0 -100px 100px -100px rgba(0, 0, 0, 0.8) inset;
} */
</style>