<template>
  <div>
    <span v-if="warning" class="text-xs font-semibold text-red-900">
      {{ details }}
    </span>
    <span v-else> {{ details }} </span>
  </div>
</template>
 
<script>
const axios = require("axios").default;

export default {
  components: {},
  props: {
    action: String,
  },
  data: function () {
    return {
      showDetailsModal: false,
      warning: false,
      ether: "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee",
    };
  },

  methods: {},
  asyncComputed: {
    details: async function () {
      this.warning = false;

      let details;

      //   alert(this.action);
      // setup what to change
      this.action == "deposit"
        ? (details = this.$store.state.casDeposit)
        : (details = this.$store.state.casWithdraw);

      if (details.schema == "20") {
        if (details.action == "deposit") {
          if (this.$store.state.casWithdraw.totalQty > 0) {
            let price = await this.g_getBuyPrice(
              this.$store.state.casWithdraw.pool_address,
              this.$store.state.casWithdraw.totalQty
            );

            this.$store.commit("setCasPrice", price);

            //   let price = 10;
            return (price / 10 ** 18).toFixed(5);
          } else {
            this.warning = true;
            return "Pick an NFT below to calculate price";
          }
        } else if (details.action == "withdraw") {
          let price = await this.g_getSellPrice(
            this.$store.state.casDeposit.pool_address,
            this.$store.state.casDeposit.totalQty
          );

          this.$store.commit("setCasPrice", price);

          //   let price = 10;
          return (price / 10 ** 18).toFixed(5);
        }
      } else {
        return details.totalQty;
      }
    },
  },
};
</script>

<style>
</style>