var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"\n      text-center\n      md:text-left\n      w-full\n      md:w-full\n      flex\n      justify-center\n      items-center\n      flex-col\n      space-y-12\n    "},[_c('div',{staticClass:"\n        text-xl\n        font-light\n        w-full\n        flex flex-col\n        space-y-6\n        text-gray-900\n        bg-tertiary\n        py-8\n        px-8\n        rounded-3xl\n        tracking-normal\n        text-left\n      "},[_vm._m(0),_c('div',{staticClass:"flex justify-between items-center"},[_c('div',{staticClass:"flex space-x-2"},[_c('div',{staticClass:"\n              cursor-pointer\n              hover:bg-gray-900 hover:bg-opacity-25\n              px-4\n              py-2\n              rounded-md\n              flex\n            ",class:{
              'bg-secondary':
                _vm.$store.state.casDeposit.schema == 721 ||
                _vm.$store.state.casDeposit.schema == 1155,
            },on:{"click":function($event){return _vm.openSelectNftModal(true, 'deposit')}}},[_c('div',[_vm._v("NFT")]),_c('svg',{staticClass:"w-2 md:w-3 ml-2 md:ml-4",attrs:{"version":"1.1","xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 12.814 6.907","enable-background":"new 0 0 12.814 6.907","xml:space":"preserve"}},[_c('path',{attrs:{"fill":"#231F20","d":"M0.146,0.854c-0.195-0.195-0.195-0.512,0-0.707s0.512-0.195,0.707,0L5.907,5.2l0.499,0.464l0.5-0.464 l5.055-5.054c0.195-0.195,0.512-0.195,0.707,0s0.195,0.512,0,0.707L6.762,6.761C6.715,6.807,6.658,6.844,6.598,6.868 C6.537,6.894,6.473,6.907,6.406,6.907c-0.064,0-0.129-0.014-0.189-0.039C6.154,6.844,6.1,6.807,6.053,6.761L0.146,0.854"}})])]),_c('div',{staticClass:"\n              cursor-pointer\n              hover:bg-gray-900 hover:bg-opacity-25\n              px-4\n              py-2\n              rounded-md\n            ",class:{
              'bg-secondary': _vm.$store.state.casDeposit.schema == 20,
            },on:{"click":function($event){return _vm.chooseEth('deposit')}}},[_vm._v(" ETH ")])]),_c('div',{staticClass:"flex space-x-4 items-end"},[_c('span',{staticClass:"text-45px"},[_c('SwapDetails',{attrs:{"action":'deposit'}})],1),_c('div',[(_vm.$store.state.casDeposit.schema == 20)?_c('span',[_vm._v("ETH")]):(
                _vm.$store.state.casDeposit.schema == 721 ||
                _vm.$store.state.casDeposit.schema == 1155
              )?_c('span',[_vm._v("NFTs")]):_vm._e()])])])]),_c('div',{staticClass:"flex"},[_c('svg',{staticClass:"cursor-pointer h-6 transform rotate-180",attrs:{"version":"1.1","xmlns":"http://www.w3.org/2000/svg","x":"0px","y":"0px","viewBox":"0 0 12.814 18.081"}},[_c('path',{attrs:{"fill":"#ffffff","d":"M0.146,12.027c-0.195-0.195-0.195-0.512,0-0.707s0.512-0.195,0.707,0l5.054,5.054V0.5 c0-0.277,0.223-0.5,0.5-0.5s0.5,0.223,0.5,0.5v15.874l5.054-5.054c0.195-0.195,0.512-0.195,0.707,0s0.195,0.512,0,0.707 l-5.906,5.907c-0.046,0.046-0.103,0.083-0.163,0.107c-0.062,0.025-0.126,0.039-0.191,0.039c-0.064,0-0.13-0.014-0.19-0.039 C6.154,18.018,6.1,17.98,6.054,17.935L0.146,12.027"}})]),_c('svg',{staticClass:"cursor-pointer h-6",attrs:{"version":"1.1","xmlns":"http://www.w3.org/2000/svg","x":"0px","y":"0px","viewBox":"0 0 12.814 18.081"}},[_c('path',{attrs:{"fill":"#ffffff","d":"M0.146,12.027c-0.195-0.195-0.195-0.512,0-0.707s0.512-0.195,0.707,0l5.054,5.054V0.5 c0-0.277,0.223-0.5,0.5-0.5s0.5,0.223,0.5,0.5v15.874l5.054-5.054c0.195-0.195,0.512-0.195,0.707,0s0.195,0.512,0,0.707 l-5.906,5.907c-0.046,0.046-0.103,0.083-0.163,0.107c-0.062,0.025-0.126,0.039-0.191,0.039c-0.064,0-0.13-0.014-0.19-0.039 C6.154,18.018,6.1,17.98,6.054,17.935L0.146,12.027"}})])]),_c('div',{staticClass:"\n        text-xl\n        font-light\n        w-full\n        flex flex-col\n        space-y-6\n        text-gray-900\n        bg-tertiary\n        py-8\n        px-8\n        rounded-3xl\n        tracking-normal\n        text-left\n      "},[_vm._m(1),_c('div',{staticClass:"flex justify-between items-center"},[_c('div',{staticClass:"flex space-x-2"},[_c('div',{staticClass:"\n              cursor-pointer\n              hover:bg-gray-900 hover:bg-opacity-25\n              px-4\n              py-2\n              rounded-md\n              flex\n            ",class:{
              'bg-secondary':
                _vm.$store.state.casWithdraw.schema == 721 ||
                _vm.$store.state.casWithdraw.schema == 1155,
            },on:{"click":function($event){return _vm.openSelectNftModal(true, 'withdraw')}}},[_c('div',[_vm._v("NFT")]),_c('svg',{staticClass:"w-2 md:w-3 ml-2 md:ml-4",attrs:{"version":"1.1","xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 12.814 6.907","enable-background":"new 0 0 12.814 6.907","xml:space":"preserve"}},[_c('path',{attrs:{"fill":"#231F20","d":"M0.146,0.854c-0.195-0.195-0.195-0.512,0-0.707s0.512-0.195,0.707,0L5.907,5.2l0.499,0.464l0.5-0.464 l5.055-5.054c0.195-0.195,0.512-0.195,0.707,0s0.195,0.512,0,0.707L6.762,6.761C6.715,6.807,6.658,6.844,6.598,6.868 C6.537,6.894,6.473,6.907,6.406,6.907c-0.064,0-0.129-0.014-0.189-0.039C6.154,6.844,6.1,6.807,6.053,6.761L0.146,0.854"}})])]),_c('div',{staticClass:"\n              cursor-pointer\n              hover:bg-gray-900 hover:bg-opacity-25\n              px-4\n              py-2\n              rounded-md\n            ",class:{
              'bg-secondary': _vm.$store.state.casWithdraw.schema == 20,
            },on:{"click":function($event){return _vm.chooseEth('withdraw')}}},[_vm._v(" ETH ")])]),_c('div',{staticClass:"flex space-x-4 items-end"},[_c('span',{staticClass:"text-45px"},[_c('SwapDetails',{attrs:{"action":'withdraw'}})],1),_c('div',[(_vm.$store.state.casWithdraw.schema == 20)?_c('span',[_vm._v("ETH")]):(
                _vm.$store.state.casWithdraw.schema == 721 ||
                _vm.$store.state.casWithdraw.schema == 1155
              )?_c('span',[_vm._v("NFTs")]):_vm._e()])])])]),_c('ExecuteSwap')],1),(_vm.showNftModal)?_c('SelectNftModal',{attrs:{"userAction":_vm.inputType},on:{"close":function($event){_vm.showNftModal = false}}}):_vm._e()],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('span',{staticClass:"uppercase text-lg font-normal"},[_c('span',[_vm._v("You deposit ")])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('span',{staticClass:"uppercase text-lg font-normal"},[_c('span',[_vm._v("You receive ")])])])}]

export { render, staticRenderFns }