<template>
  <div style="">
    <main v-if="!isLoaded" class="main-container md:mx-0 mx-16">
      <section class="text-white text-lg mb-96">
        <div class="pt-3 pb-3 mb-10">
          <h1 class="text-2xl uppercase text-center font-medium">
            NFT Gallery
          </h1>
          <p class="px-2 md:px-10 text-center mt-6 font-light">Loading...</p>
        </div>
        <div></div>
      </section>
    </main>
    <div v-if="isLoaded">
      <Particles id="tsparticles" :options="options" style="z-index: 1" />
      <div style="z-index: 2; bottom: 10px; right: 10px; position: absolute">
        <router-link to="/">
          <img src="@/assets/images/logo1.svg" alt class="h-14 md:h-14" />
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
const axios = require("axios").default;

export default {
  name: "ImageGallery",
  data: function () {
    return {
      isLoaded: false,
      options: {
        autoPlay: true,
        background: {
          color: {
            value: "#141A28",
          },
        },
        detectRetina: true,
        fpsLimit: 60,
        fullScreen: {
          enable: true,
          zIndex: 0,
        },
        particles: {
          move: {
            attract: {
              enable: true,
              rotate: {
                x: 600,
                y: 1200,
              },
            },
            decay: 0,
            direction: "bottom",
            distance: 0,
            enable: true,
            gravity: {
              acceleration: 4,
              enable: true,
              maxSpeed: 2,
            },
          },
          number: {
            limit: 0,
            value: 15,
          },
          opacity: {
            random: {
              enable: true,
              minimumValue: 0.1,
              maximumValue: 0.4,
            },
            value: 1,
            animation: {
              count: 0,
              enable: true,
              speed: 1,
              sync: false,
              destroy: "none",
              minimumValue: 0.2,
              startValue: "random",
            },
          },
          reduceDuplicates: true,
          rotate: {
            value: 0,
            animation: {
              enable: true,
              speed: 1,
              sync: false,
              startValue: 0,
            },
            direction: "random",
            path: false,
          },
          shape: {
            options: {
              image: [],
            },
            type: "image",
          },
          size: {
            random: {
              enable: true,
              minimumValue: 30,
            },
            value: 100,
          },
        },
        pauseOnBlur: true,
        pauseOnOutsideViewport: false,
        responsive: [],
        themes: [],
      },
      address: "",
    };
  },
  mounted: async function () {
    this.address = this.$route.params.address;

    let { data } = await axios.get(
      `https://api.opensea.io/api/v1/assets?owner=${this.address}`
    );
    try {
      if (data.assets.length == 50) {
        let data2 = await axios.get(
          `https://api.opensea.io/api/v1/assets?owner=${this.address}&order_direction=desc&offset=50&limit=50`
        );
        data.assets = data.assets.concat(data2.data.assets);
      }
      if (data.assets.length == 100) {
        let data3 = await axios.get(
          `https://api.opensea.io/api/v1/assets?owner=${this.address}&order_direction=desc&offset=100&limit=50`
        );
        data.assets = data.assets.concat(data3.data.assets);
      }
    } catch (error) {}

    let images = [];
    for (const nft of data.assets) {
      if (images.length < 40) {
        images.push(nft.image_url);
      }
    }
    for (const image of images) {
      try {
        const img = new Image();
        img.src = image;
        await img.decode();
        // img is ready to use
        let imgObj = {
          src: image,
          width: img.width,
          height: img.height,
        };
        this.options.particles.shape.options.image.push(imgObj);
      } catch (error) {}
    }
    this.isLoaded = true;
  },
  computed: {
    isConnected: function () {
      return this.$store.state.web3 != null;
    },
  },
  methods: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
