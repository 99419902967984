<template>
  <fieldset
    style="border-color: #b2aea8"
    class="border rounded-2xl pt-4 pb-3 px-5"
  >
    <label
      class="flex block uppercase text-lg mb-6 items-center justify-between"
    >
      <span v-if="isMain"> from</span>
      <span v-else> to</span>
      <span class="float-right text-xs md:text-lg" v-if="isMain"
        >Your Balance:
        <TokenBalance
          :erc20="project.address"
          :account="account"
          symbol=""
          decimals="18" /></span
    ></label>
    <div class="flex justify-between items-center">
      <div class="w-5/12">
        <input
          v-if="
            this.project.address == '0x7a911c71144f4d5a00e4216b1c5b12d9571e9336' //for ahegao smaller letters
          "
          class="text-3xl md:text-3xl w-full text-jet font-light bg-transparent border-0 focus:outline-none"
          type="text"
          placeholder="0"
          :value="selectedNfts"
          disabled
        />
        <input
          v-else
          class="text-3xl md:text-45px w-full text-jet font-light bg-transparent border-0 focus:outline-none"
          type="text"
          placeholder="0"
          :value="selectedNfts"
          disabled
        />
      </div>
      <div class="w-7/12 flex">
        <div class="dropdown py-2 ml-auto inline-block relative">
          <button class="flex w-full items-center focus:outline-none">
            <div class="flex items-center cursor-pointer">
              <i
                style=""
                class="rounded-full h-10 w-10 mr-3 flex items-center justify-center shadow-sm"
              >
                <img
                  class="rounded-full object-cover"
                  :src="project.logo"
                  alt=""
                />
              </i>
              <span class="uppercase text-sm md:text-xl font-bold">{{
                project.symbol
              }}</span>
            </div>
            <!-- <svg
                  class="h-2 ml-2"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 12.814 6.907"
                  enable-background="new 0 0 12.814 6.907"
                  xml:space="preserve"
                >
                  <path
                    fill="#231F20"
                    d="M0.146,0.854c-0.195-0.195-0.195-0.512,0-0.707s0.512-0.195,0.707,0L5.907,5.2l0.499,0.464l0.5-0.464 l5.055-5.054c0.195-0.195,0.512-0.195,0.707,0s0.195,0.512,0,0.707L6.762,6.761C6.715,6.807,6.658,6.844,6.598,6.868 C6.537,6.894,6.473,6.907,6.406,6.907c-0.064,0-0.129-0.014-0.189-0.039C6.154,6.844,6.1,6.807,6.053,6.761L0.146,0.854"
                  />
                </svg> -->
          </button>
        </div>
      </div>
    </div>
  </fieldset>
</template>


<script>
import TokenBalance from "@/components/TokenBalance";
import BigNumber from "bignumber.js";

export default {
  name: "TokenField",
  components: { TokenBalance },

  props: { isMain: Boolean },
  computed: {},
  data: function () {
    return {
      nftValue: 0,
    };
  },
  beforeMount: async function () {},
  methods: {},
  mounted: async function () {
    // if (this.payWithEth) {
    // alert("pay with eth yay");
    // }
  },
  computed: {
    project: function () {
      return this.$store.state.currentProject;
    },
    selectedNfts: function () {
      // if anything was selected

      if (this.$store.state.didSelectNfts) {
        if (this.project.type == 721 && !this.isMain) {
          // can only deposit 1 nft if 721 so token always 100
          if (this.project.removedFees) {
            return (
              this.project.nftValue * this.$store.state.selectedNfts[0].length
            );
          } else {
            return (
              this.project.nftValue *
              0.95 *
              this.$store.state.selectedNfts[0].length
            );
          }
        } else if (this.project.type == 721 && this.isMain) {
          return (
            this.$store.state.selectedNfts[0].length * this.project.nftValue
          );
          // return this.$store.state.selectedNfts[0].length * 100  100;
        } else if (this.project.type == 1155) {
          // could be many so need to do calc of how many tokens would get
          const nftAmount = this.$store.state.selectedNfts[1];
          let nftTotalAmount = 0;

          for (const element of nftAmount) {
            nftTotalAmount += element;
          }

          if (!this.isMain) {
            if (this.project.removedFees) {
              return nftTotalAmount * this.project.nftValue;
            } else {
              return nftTotalAmount * (this.project.nftValue * 0.95);
            }
          } else {
            return nftTotalAmount * this.project.nftValue;
          }
        }
      } else {
        return 0;
      }
    },
    account: function () {
      return this.$store.state.account;
    },
  },
};
</script>