import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import BigNumber from "bignumber.js";

import "./assets/css/index.css";

Vue.config.productionTip = false;

import Web3Modal from "web3modal";

import WalletConnectProvider from "@walletconnect/web3-provider";

import Notifications from "vue-notification";
import vueAwesomeCountdown from "vue-awesome-countdown";
import Pagination from "vue-pagination-2";
import axios from "axios";
import VueAxios from "vue-axios";
import AsyncComputed from "vue-async-computed";
import Particles from "particles.vue";
import Trend from "vuetrend";
import WalletLink from "walletlink";

Vue.use(Notifications);
Vue.use(vueAwesomeCountdown, "vac");
Vue.use(VueAxios, axios);
Vue.use(AsyncComputed);
Vue.use(Particles);
Vue.use(Trend);

// Those are global functions available for all components
Vue.mixin({
  methods: {
    nft20: function() {
      return nft20;
    },
    g_getBuyPrice: async function(tokenAddress, quantity) {
      // to get univ3 pools as we don't know how to calculate yet

      let pool = await axios.get(
        `https://api.nft20.io/pools?perPage=200&network=0&pool=${tokenAddress}`
      );

      pool = pool.data.data[0];

      if (pool.lp_fee != null) {
        return (pool.buy_price_eth * 10 ** 18 * 111) / 100;
      }

      let amount = new BigNumber(quantity);
      amount = amount
        .multipliedBy(100000000000000000000)
        .multipliedBy(1.1)
        .toFixed();
      let result = await this.$store.state.UniRouter.methods
        .getAmountsIn(amount + "", [
          "0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2", //WETH
          tokenAddress,
        ])
        .call();
      return result[0];
    },
    g_getSellPrice: async function(tokenAddress, quantity) {
      let pool = await axios.get(
        `https://api.nft20.io/pools?perPage=200&network=0&pool=${tokenAddress}`
      );

      pool = pool.data.data[0];

      if (pool.lp_fee != null) {
        return (pool.sell_price_eth * 10 ** 18 * 111) / 100;
      }

      let amount = new BigNumber(quantity);
      amount = amount.multipliedBy(100000000000000000000).toFixed();
      let result = await this.$store.state.UniRouter.methods
        .getAmountsOut(amount + "", [
          tokenAddress,
          "0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2", //WETH
        ])
        .call();
      return result[1];
    },
    g_getUserNfts: async function(user, nftContract) {
      let NFTS = [];
      let offset = 0;
      let fetching = true;
      while (fetching) {
        let { data } = await axios.get(
          `https://api.opensea.io/api/v1/assets?owner=${user}&asset_contract_address=${nftContract}`
        );
        NFTS = NFTS.concat(data.assets);
        offset += 50;
        if (data.assets.length == 0 || data.assets.length % 50 != 0) {
          fetching = false;
        }
      }
      let tmpCollections = {};
      for (const nft of NFTS) {
        nft.nft_contract = nft.asset_contract.address;
        nft.nft_image = nft.image_preview_url;
        nft.nft_id = nft.token_id;

        if (tmpCollections[nft.asset_contract.address] == null) {
          let pool = this.$store.state.pairs.find(
            (pool) => pool.nftAddress == nft.asset_contract.address
          );
          tmpCollections[nft.asset_contract.address] = Object.assign(
            nft.collection,
            nft.asset_contract
          );
          tmpCollections[nft.asset_contract.address].twentyPool = pool;
          tmpCollections[nft.asset_contract.address].owned = 1;
        } else {
          tmpCollections[nft.asset_contract.address].owned += 1;
        }
      }
      this.collections = Object.values(tmpCollections).sort(
        (b, a) => a.owned - b.owned
      );
      this.internal_nfts = NFTS;
    },
  },
});

Vue.component("pagination", Pagination);

const providerOptions = {
  walletconnect: {
    package: WalletConnectProvider, // required
    options: {
      rpc: {
        1: "wss://eth-mainnet.g.alchemy.com/v2/7o_A2UJVRVUs9JrNiN5Hnz0u-8QTItj_"
      }
    },
  },
  walletlink: {
    package: WalletLink, // Required
    options: {
      appName: "nft20", // Required
      rpc: "wss://eth-mainnet.g.alchemy.com/v2/7o_A2UJVRVUs9JrNiN5Hnz0u-8QTItj_", // Optional if `infuraId` is provided; otherwise it's required
      chainId: 1, // Optional. It defaults to 1 if not provided
      appLogoUrl:
        "https://github.com/walletlink/walletlink/blob/master/web/src/images/wallets/coinbase-wallet.svg", // Optional. Application logo image URL. favicon is used if unspecified
      darkMode: true, // Optional. Use dark theme, defaults to false
    },
  },
  // "custom-coinbase": {
  //   display: {
  //     logo:
  //       "https://github.com/walletlink/walletlink/blob/master/web/src/images/wallets/coinbase-wallet.svg",
  //     name: "Coinbase",
  //     description: "Scan with WalletLink to connect",
  //   },
  //   options: {
  //     appName: "nft20", // Your app name
  //     networkUrl: `https://eth-mainnet.alchemyapi.io/v2/GBL4FjihpKI6R44D3cUPcR337AD8I2HN`,
  //     chainId: 0,
  //   },
  //   package: WalletLink,
  //   connector: async (_, options) => {
  //     const { appName, networkUrl, chainId } = options;
  //     const walletLink = new WalletLink({
  //       appName,
  //     });
  //     const provider = walletLink.makeWeb3Provider(networkUrl, chainId);
  //     await provider.enable();
  //     return provider;
  //   },
  // },
};

const web3Modal = new Web3Modal({
  network: "mainnet", // optional
  cacheProvider: true, // optional
  theme: "dark",
  providerOptions, // required
});

(async function() {
  Vue.prototype.$web3Modal = web3Modal;
  Vue.config.productionTip = false;

  let app = new Vue({
    router,
    store,
    render: (h) => h(App),
  }).$mount("#app");
})();
