<template>
  <main class="main-container">

  </main>
</template>

<script>
// @ is an alias to /src
import Auctions from "@/components/Auctions.vue";

export default {
  name: "Discover",
  components: { Auctions },
  data: function () {
    return {
   
    };
  },
  methods: {
   
    },
  
  mounted: async function () {},
  beforeMount: async function () {
    this.initialize();
  },
  watch: {
    stateLoaded: function (state, old) {
      if (state) {
        this.initialize();
      }
    },
  },
  computed: {
    projects: function () {
      return this.$store.state.projects;
    },
  },

 
};
</script>
