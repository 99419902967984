<template>
  <div
    class="
      text-center
      md:text-left
      w-full
      md:w-full
      flex
      justify-center
      items-center
      flex-col
      space-y-12
    "
  >
    <div class="w-full">
      <button
        @click="showDetailsModal = true"
        target="_blank"
        class="
          w-full
          text-center
          uppercase
          font-primary font-bold
          leading-none
          text-xl
          inline-block
          py-8
          rounded-xl
          px-5
          text-brown
          hover:text-white
          bg-secondary
          hover:bg-blue-400 hover:bg-opacity-70
          transition
          duration-0
          focus:outline-none
        "
      >
        SWAP
      </button>
    </div>
    <DetailsModal
      v-if="showDetailsModal"
      @confirmSwap="swap()"
      @close="showDetailsModal = false"
      :processingTransaction="miningTx"
    />
  </div>
</template>

<script>
import BigNumber from "bignumber.js";

import Web3EthAbi from "web3-eth-abi";
import DetailsModal from "@/components/cas2/DetailsModal";

export default {
  name: "ExecutaSwap",
  components: { DetailsModal },
  data: function () {
    return {
      showDetailsModal: false,
      miningTx: false,
    };
  },
  beforeMount: async function () {},
  methods: {
    generateEstimateData: function (buyMarket, toNft, toIds, toAmounts) {
      var details = getBuySignature(buyMarket, toNft, toIds, toAmounts);
      return this.$store.state.web3.eth.abi.encodeParameters(
        details.sig,
        details.params
      );
    },
    getSwapParameters: async function (
      buyMarket,
      sellMarket,
      fromNft,
      buyData
    ) {
      // if (isEthBasedMarket(buyMarket)) {
      //   return [
      //     getErc20FromNft(fromNft),
      //     CAS,
      //     "0x" +
      //       parseInt(await getTotalPriceEstimate(buyMarket, buyData)).toString(
      //         16
      //       ),
      //   ];
      // } else {
      var estimateDetails = await getTotalPriceEstimate(buyMarket, buyData);
      var totalEstimate = 0;
      for (var i = 0; i < estimateDetails[0]; i++) {
        totalEstimate += estimateDetails[0][i];
      }
      return [
        getErc20FromNft(fromNft),
        getErc20Address(sellMarket),
        CAS,
        "0x" + totalEstimate.toString(16),
      ];
      // }
    },

    swapNftToNft: async function () {
      // TODO ADD APPROVAL
      //   if (!this.isApprovedForAll) {
      //     await this.setApproalForAll();
      //   }
      let deposit = this.$store.state.casDeposit;
      let withdraw = this.$store.state.casWithdraw;
      if (deposit.schema == 1155) {
        let contract = await this.$store.state.Pair(deposit.pool_address);
        let minetx = await contract.methods
          .swap1155(
            deposit.nfts.ids,
            deposit.nfts.qty,
            withdraw.nfts.ids,
            withdraw.nfts.qty,
            this.$store.state.account
          )
          .send({
            from: this.$store.state.account,
          })
          .on("transactionHash", (hash) => {
            console.log("hash of tx ", hash);
            // pass the hash to notify to track it
            this.$store.state.notify.hash(hash);
          });
      } else if (deposit.schema == 721) {
        let contract = await this.$store.state.Pair(deposit.pool_address);
        let minetx = await contract.methods
          .swap721(deposit.nfts.ids[0], withdraw.nfts.ids[0], this.$store.state.account)
          .send({
            from: this.$store.state.account,
          })
          .on("transactionHash", (hash) => {
            console.log("hash of tx ", hash);
            // pass the hash to notify to track it
            this.$store.state.notify.hash(hash);
          });
      }
    },
    buyNft: async function (amount) {
      // params for call
      const toNft = this.$store.state.casWithdraw.nft_address;
      const ids = this.$store.state.casWithdraw.nfts.ids;
      const ammts = this.$store.state.casWithdraw.nfts.qty;
      const poolObject = this.$store.state.casWithdraw.poolObject;

      // const changeIn = "0x6b175474e89094c44da98b954eedeac495271d0f"; //dai
      const changeIn = "0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE"; //eth
      const price = this.$store.state.casPrice;
      let minetx = await this.$store.state.NFT20Cas.methods
        .ethForNft(
          toNft,
          ids,
          ammts,
          this.$store.state.account,
          poolObject.lp_fee == null ? 0 : poolObject.lp_fee,
          poolObject.lp_fee == null ? false : true
        )
        .send({
          from: this.$store.state.account,
          value: price, ///addd up here if we add fee on buying
        })
        .on("transactionHash", (hash) => {
          console.log("hash of tx ", hash);
          // pass the hash to notify to track it
          this.$store.state.notify.hash(hash);
        });
    },
    sellNft: async function (amount) {
      this.showDetailsModal = true;
      let contract = null;

      const isERC721 =
        this.$store.state.casDeposit.schema == 721 ? true : false;

      const poolObject = this.$store.state.casDeposit.poolObject;

      let minetx = await this.$store.state.NFT20Cas.methods
        .nftForEth(
          this.$store.state.casDeposit.nft_address,
          this.$store.state.casDeposit.nfts.ids,
          this.$store.state.casDeposit.nfts.qty,
          isERC721, //is erc721 or erc1155,
          poolObject.lp_fee == null ? 0 : poolObject.lp_fee,
          poolObject.lp_fee == null ? false : true
        )
        .send({
          from: this.$store.state.account,
        })
        .on("transactionHash", (hash) => {
          console.log("hash of tx ", hash);
          // pass the hash to notify to track it
          this.$store.state.notify.hash(hash);
        });
    },
    swap: async function () {
      this.miningTx = true;

      try {
        const amount = new BigNumber(this.$store.state.casPrice)
          .shiftedBy(-parseInt(18))
          .toFixed(2, 1);
        if (
          !this.$store.state.casDeposit.nfts &&
          !this.$store.state.casWithdraw.nfts
        ) {
          alert("Pick at least 1 sell or buy");
        }
        // from methods is nft
        if (
          this.$store.state.casDeposit.schema != 20 &&
          this.$store.state.casWithdraw.schema != 20
        ) {
          await this.swapNftToNft();
        } else if (this.$store.state.casDeposit.schema == 20) {
          //buy nft
          await this.buyNft(amount);
        } else if (this.$store.state.casWithdraw.schema == 20) {
          //sell nft
          await this.sellNft(amount);
        }

        this.miningTx = false;

        this.$store.commit("setCasPrice", null);
        this.$store.commit("setCasDeposit", {});
        this.$store.commit("setCasWithdraw", {});

        this.showDetailsModal = false;
      } catch (e) {
        console.log(e);
        this.miningTx = false;

        this.$store.commit("setCasPrice", null);
        this.$store.commit("setCasDeposit", {});
        this.$store.commit("setCasWithdraw", {});

        this.showDetailsModal = false;
      }
    },
  },
  computed: {},
  mounted: async function () {},
};
</script>
