<template>
  <div class="">
    <div>
      <!-- <div class="text-white">Transfer NFTs</div> TODO -->

      <div v-if="nfts.length < 1" class="text-2xl text-center text-white">
        Loading NFTs ...
      </div>
      <div>
        <div
          class="mt-10 justify-center md:justify-end text-white flex space-x-6"
          id="sorting"
        >
          <div
            @click="sortedBy = 'id'"
            class="cursor-pointer hover:text-yellow-300"
            :class="{ 'text-yellow-200': sortedBy == 'id' }"
          >
            ID ↑↓
          </div>
          <div
            @click="sortedBy = 'time'"
            class="cursor-pointer hover:text-yellow-300"
            :class="{ 'text-yellow-200': sortedBy == 'time' }"
          >
            New ↑↓
          </div>
        </div>
      </div>
      <div
        v-if="nfts.length >= 1"
        class="
          text-grey-900
          grid grid-cols-1
          md:px-0
          px-12
          md:grid-cols-5
          gap-6
        "
      >
        <div
          class="bg-opacity-0 bg-tertiary rounded-lg p-1"
          v-for="nft in sortedNFT"
          :key="nft.index"
        >
          <NFTListItem
            :nft="nft"
            :owner="owner"
            :collection="getCollection(nft)"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NFTListItem from "@/components/wallet/NFTListItem.vue";

export default {
  name: "NFTList",
  components: {
    NFTListItem,
  },
  data: function () {
    return {
      sortedBy: "time",
    };
  },
  props: {
    nfts: Array,
    collections: Array,
    owner: Boolean,
  },
  computed: {
    sortedNFT: function () {
      let res = this.nfts;
      if (this.sortedBy == "time") {
        res = res.sort(function (a, b) {
          return a.index - b.index;
        });
      }
      if (this.sortedBy == "id") {
        res = res.sort(function (b, a) {
          return parseInt(a.nft_id) - parseInt(b.nft_id);
        });
      }
      console.log(res);
      return res;
    },
  },
  methods: {
    getCollection(nft) {
      return this.collections.find(
        (c) => c.address == nft.asset_contract.address
      );
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
