import Vue from "vue";
import Vuex from "vuex";

import Web3 from "web3";

import Contracts from "@/assets/contracts";

import axios from "axios";

import FactoryABI from "@/assets/abis/Factory";
import AuctionABI from "@/assets/abis/Auction";
import PairABI from "@/assets/abis/Pair";
import Pair1ABI from "@/assets/abis/Pair1";

import ERC721ABI from "@/assets/abis/ERC721";
import ERC1155ABI from "@/assets/abis/ERC1155";
import DuckChefABI from "@/assets/abis/DuckChef";
import MasterChefABI from "@/assets/abis/MasterChef";
import MasterChefNewABI from "@/assets/abis/MasterChefNew";
import WrappedCatsABI from "@/assets/abis/WrappedCats";
import CatsCoreAbi from "@/assets/abis/CatsCore";
import NCTAbi from "@/assets/abis/INCT";
import MuseStakerAbi from "@/assets/abis/MuseStaker";
import MuseStaker2Abi from "@/assets/abis/MuseStaker2";
import UniRouter from "@/assets/abis/UniRouter";

import NFT20Abi from "@/assets/abis/Pair";
import NFT20SwapperAbi from "@/assets/abis/NFT20Swapper";

import CoinGecko from "coingecko-api";

import BigNumber from "bignumber.js";
import NFT20CasAbi from "@/assets/abis/NFT20Cas";

import Notify from "bnc-notify";

import NFT20 from "nft20";

const nft20 = new NFT20(
  "wss://eth-mainnet.alchemyapi.io/v2/GBL4FjihpKI6R44D3cUPcR337AD8I2HN"
);

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    web3: null,
    notify: null,
    isLoaded: false,
    account: null,
    contracts: {},
    auctions: [],
    pairCount: 0,
    pairs: [],
    trendingPairs: [],
    currentProject: null,
    selectedNfts: [],
    selectedNftsIn: [],
    selectedNftsOut: [],
    tokenSelected: "",
    casDeposit: "",
    casWithdraw: "",
    casPrice: 0,
    didSelectNfts: false,
    isLoading: false,
    network: process.env.NETWORK == null ? 0 : parseInt(process.env.NETWORK),
    correctNetwork: null,
    NFT20_INSTANCE: nft20,
  },
  mutations: {
    disconnectWallet: async function(state) {
      let web3 = new Web3(
        new Web3.providers.WebsocketProvider(
          `wss://eth-mainnet.alchemyapi.io/v2/GBL4FjihpKI6R44D3cUPcR337AD8I2HN`
        )
      );
      state.web3 = web3;
      state.account = null;
    },
    setWeb3: async function(state, web3, vueInstance) {
      if (state.web3 != null && state.account != null) {
        //   // state.web3 = null;
        return;
      }
      state.web3 = web3;
      state.account = (await web3.eth.getAccounts())[0];
      state.contracts = Contracts;

      // Blocknative stuff

      state.notify = Notify({
        dappId: "6af111c7-b0b9-48f6-b61e-350f841b0612", // [String] The API key created by step one above
        networkId: 1,
        darkMode: true,
      });

      state.UniEthMuseContract = new web3.eth.Contract(
        PairABI,
        Contracts.UniEthMuse
      );

      state.MasterChef = new web3.eth.Contract(
        MasterChefABI,
        Contracts.MasterChef
      );

      state.UniRouter = new web3.eth.Contract(UniRouter, Contracts.UniRouter);

      //This has to be update with new Masterchef contract
      state.NFT20MasterChef = new web3.eth.Contract(
        MasterChefNewABI,
        Contracts.MasterChefNew
      );
      state.Auction = new web3.eth.Contract(AuctionABI, Contracts.Auction);
      state.DuckChef = new web3.eth.Contract(DuckChefABI, Contracts.DuckChef);
      state.Factory = new web3.eth.Contract(FactoryABI, Contracts.Factory);

      state.NCT = new web3.eth.Contract(NCTAbi, Contracts.NCT);

      state.MuseStaker = new web3.eth.Contract(
        MuseStakerAbi,
        Contracts.MuseStaker
      );

      state.MuseStaker2 = new web3.eth.Contract(
        MuseStaker2Abi,
        Contracts.MuseStaker2
      );
      state.Pair = async (contract) => {
        return new web3.eth.Contract(PairABI, contract);
      };
      state.Pair1 = async (contract) => {
        return new web3.eth.Contract(Pair1ABI, contract);
      };
      state.ERC721 = async (contract) => {
        return new web3.eth.Contract(ERC721ABI, contract);
      };

      state.ERC1155 = async (contract) => {
        return new web3.eth.Contract(ERC1155ABI, contract);
      };

      state.CasContract = new web3.eth.Contract(
        NFT20SwapperAbi,
        Contracts.NFT20Swapper
      );

      state.NFT20Cas = new web3.eth.Contract(NFT20CasAbi, Contracts.NFT20Cas);

      // console.log(state.CasContract);

      state.WrappedCats = new web3.eth.Contract(
        WrappedCatsABI,
        Contracts.WrappedCats
      );

      state.CatsCore = new web3.eth.Contract(CatsCoreAbi, Contracts.CatsCore);

      console.log("NEW ACCOUNT", state.account);
      if (state.isLoaded || state.isLoading) {
        return;
      }
      state.isLoading = true;

      const CoinGeckoClient = new CoinGecko();

      let coingeckoRes;

      try {
        coingeckoRes = await CoinGeckoClient.simple.price({
          ids: ["ethereum"],
          vs_currencies: ["usd"],
        });
        state.ethPrice = coingeckoRes.data.ethereum.usd;
      } catch (error) {
        state.ethPrice = 2000;
      }

      state.musePrice = 48; //$20 cause no coingecko api for now

      /*  Using API instead of web3 */
      let pools = await this.getters.nft20.getPools();
      let gh_assets = [];
      if (state.network == 0) {
        gh_assets = await axios.get(
          "https://raw.githubusercontent.com/verynifty/nft20-assets/master/assets.json"
        );
      } else {
        gh_assets = await axios.get(
          "https://raw.githubusercontent.com/verynifty/nft20-assets/master/assets_matic.json"
        );
      }
      let trendingPairs = [];
      for (const pool of pools) {
        //console.log(pool)
        const result = gh_assets.data.filter(
          (asset) => asset.symbol == pool.symbol
        );
        let uin_v3 = false;
        let totalLiquidity = null;
        let logo = "https://picsum.photos/id/1073/300/300";
        let name = pool.name;
        let uniswap = `https://app.uniswap.org/#/add/v2/ETH/${pool.address}`;
        if (state.network == 1) {
          uniswap = `https://swap.cometh.io/#/add/ETH/${pool.address}`;
        }
        let factoryVersion = 2;
        let hide = false;
        let lpToken = false;
        let removedFees = false;
        let lp_fees = 0;
        let guide = false;

        if (result.length > 0) logo = result[0].logo;
        if (result.length > 0) name = result[0].name;
        if (result.length > 0 && result[0].hide != null && result[0].hide)
          hide = true;
        if (result.length > 0 && result[0].uniswap) uniswap = result[0].uniswap;
        if (result.length > 0 && result[0].uniswap_v3) uin_v3 = true;

        if (result.length > 0 && result[0].lpToken) lpToken = result[0].lpToken;
        if (result.length > 0 && result[0].factory_version)
          factoryVersion = result[0].factory_version;
        if (result.length > 0 && result[0].removedFees)
          removedFees = result[0].removedFees;

        if (result.length > 0 && result[0].guide) guide = result[0].guide;

        if (result.length > 0 && result[0].lp_fees) lp_fees = result[0].lp_fees;

        // this is if univ3 for now
        if (uin_v3) {
          let wethContract = new state.web3.eth.Contract(
            NFT20Abi,
            "0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2"
          );

          let balance = await wethContract.methods.balanceOf(lpToken).call();

          balance = parseFloat(
            new BigNumber(balance).shiftedBy(-parseInt(18)).toFixed(3)
          );
          totalLiquidity = balance * 2 * state.ethPrice;
        }
        // console.log(pool)
        let price_data = null;
        if (pool.price_one_week_ago_usd != null && pool.price_now_usd != null) {
          price_data = {
            price_one_week_ago_usd: parseFloat(pool.price_one_week_ago_usd),
            price_now_usd: parseFloat(pool.price_now_usd),
          };
          price_data.one_week_change = parseInt(
            ((price_data.price_now_usd - price_data.price_one_week_ago_usd) /
              price_data.price_one_week_ago_usd) *
              100
          );
          price_data.trendline = {
            usd: pool.trendline.usd.map(Number),
            eth: pool.trendline.eth.map(Number),
          };
        }

        // workaround for duplicate degenz pool
        if (pool.address == "0x2da4bbb773851b6d47cf748fb438c0bed9509495") {
          totalLiquidity = 0;
        }

        if (!hide) {
          let p = {
            name: pool.name,
            symbol: pool.symbol,
            type: parseInt(pool.nft_type),
            nftAddress: pool.nft,
            address: pool.address,
            supply: parseInt(pool.nft_value) * parseInt(pool.nft_locked),
            nftLocked: parseInt(pool.nft_locked),
            users_today: parseInt(pool.users_today),
            users_weekly: parseInt(pool.users_weekly),
            logo: pool.logo_url,
            uniswap,
            lpToken,
            nftValue: pool.nft_value,
            factoryVersion,
            totalLiquidity:
              uin_v3 ||
              pool.address == "0x2da4bbb773851b6d47cf748fb438c0bed9509495"
                ? totalLiquidity
                : parseFloat(pool.lp_usd_balance),
            removedFees,
            nftBasePrice: parseFloat(pool.nft_usd_price),
            nftBasePriceEth: parseFloat(pool.nft_usd_price) / state.ethPrice,
            nftBuyPriceEth: (pool.buy_price_eth * 10 ** 18 * 111) / 100,
            nftSellPriceEth: (pool.sell_price_eth * 10 ** 18 * 111) / 100,
            uniV3: uin_v3,
            lp_fee: lp_fees,
            index_order: parseInt(pool.index_order),
            guide,
            external_url: pool.external_url,
            telegram_url: pool.telegram_url,
            twitter_username: pool.twitter_username,
            featured_image_url: pool.featured_image_url,
            banner_url: pool.banner_url,
            price_data: price_data,
          };
          trendingPairs.push(p);
          state.pairs.push(p);
        }
      }

      state.pairCount = parseInt(await state.Factory.methods.counter().call());
      console.log("set Web3");

      state.pairs = state.pairs.sort(function(a, b) {
        // if (b.totalLiquidity - a.totalLiquidity != 0)
        return b.totalLiquidity - a.totalLiquidity;
        // else return b.supply - a.supply;
      });

      trendingPairs = trendingPairs.sort(function(a, b) {
        return b.users_weekly - a.users_weekly;
      });
      state.trendingPairs = trendingPairs;
      state.isLoaded = true;
    },
    setCurrentProject(state, payload) {
      state.currentProject = payload;
    },
    setSelectedNfts(state, payload) {
      state.selectedNfts = payload;
    },
    setSelectedNftsIn(state, payload) {
      state.selectedNftsIn = payload;
    },

    setSelectedNftsOut(state, payload) {
      state.selectedNftsOut = payload;
    },
    setDidSelectNfts(state, payload) {
      state.didSelectNfts = payload;
    },
    setTokenSelected(state, payload) {
      state.tokenSelected = payload;
    },
    setCasWithdraw(state, payload) {
      state.casWithdraw = payload;
    },
    setCasDeposit(state, payload) {
      state.casDeposit = payload;
    },
    setCasPrice(state, payload) {
      state.casPrice = payload;
    },
  },
  actions: {
    connectWallet: async function(context, vue) {
      console.log("connecting");

      context.state.web3 = null;
      const provider = await vue.$web3Modal.connect();
      // provider.clearCachedProvider();

      const web3 = new Web3(provider);
      context.commit("setWeb3", web3, vue);

      provider.on("accountsChanged", (accounts) => {
        context.dispatch("connectWallet", vue);
        console.log("CHANGED");
      });

      // Subscribe to chainId change
      provider.on("chainChanged", (chainId) => {
        console.log("CHAIN");

        context.dispatch("connectWallet", vue);
      });

      // Subscribe to provider disconnection
      provider.on("connect", (error) => {
        console.log("CONNECT");
      });

      // Subscribe to provider disconnection
      provider.on("disconnect", (error) => {
        // context.dispatch("connectWallet", vue);
      });
    },
    disconnectWallet: async function(context, vue) {
      await vue.$web3Modal.clearCachedProvider();
      context.commit("disconnectWallet");
    },
    startWeb3: async function(context, vue) {
      let web3 = new Web3(
        new Web3.providers.WebsocketProvider(
          `wss://eth-mainnet.alchemyapi.io/v2/GBL4FjihpKI6R44D3cUPcR337AD8I2HN`
        )
      );
      if (this.state.network == 1) {
        web3 = new Web3(
          new Web3.providers.WebsocketProvider(
            `wss://rpc-mainnet.maticvigil.com/ws` //TODO change with matic RPC
          )
        );
      }
      if (vue.$web3Modal.cachedProvider) {
        //This is case where someone already connected
        const provider = await vue.$web3Modal.connect();
        web3 = new Web3(provider);
      }
      let chainId = await web3.eth.getChainId();
      console.log(chainId);
      if (context.state.network == 1 && chainId != 137) {
        context.state.correctNetwork = "Matic network";
      }
      if (context.state.network == 0 && chainId != 1) {
        context.state.correctNetwork = "Ethereum Mainnet";
      }

      context.commit("setWeb3", web3, vue);
    },
  },
  getters: {
    getCasWithdraw: (state) => {
      return state.casWithdraw;
    },
    getCasDeposit: (state) => {
      return state.casDeposit;
    },
    nft20: (state) => {
      return state.NFT20_INSTANCE;
    },
  },
  modules: {},
});
