<template>
  <div class="mb-8" v-if="collection.banner_image_url">
    <img
      class="w-full object-cover opacity-50 object-center"
      style="height: 130px"
      :src="collection.banner_image_url"
    />
  </div>
</template>


<script>
export default {
  name: "CollectionBanner",
  components: {},

  props: {
    collection: Object,
  },
  computed: {},
  data: function () {
    return {};
  },
  beforeMount: async function () {},
  methods: {},
  mounted: async function () {},
  computed: {},
};
</script>

<style scoped>
</style>