<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper" @click="$emit('close')">
        <div @click.stop class="modal-container text-brown bg-tertiary">
          <div class="modal-header flex justify-between items-center">
            <slot name="header">
              <h1 class="text-2xl font-bold">
                {{ title }}
              </h1>
              <div @click="$emit('close')" class="cursor-pointer text-lg">
                <img
                  src="@/assets/images/x.svg"
                  class="fill-current text-white"
                  alt=""
                />
              </div>
            </slot>
          </div>

          <div class="modal-body">
            <slot name="body">
              <span class="text-xl">
                {{ text }}
              </span>
            </slot>
          </div>

          <div class="modal-footer">
            <slot name="footer">
              <!-- foter -->

              <!-- <button
                @click="$emit('close')"
                class="border-2 border-gray-900 bg-gray-900 bg-opacity-100 hover:text-gray-900 hover:bg-white text-white py-2 px-3 mt-6 text-2xl"
              >
                {{ button }}
              </button> -->
            </slot>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
// add this as button to components
//  <button id="show-modal" @click="showModal = true">
//           Show Modal
//         </button>
export default {
  name: "Modal",
  components: {},
  props: {
    text: String,
    title: String,
    button: String,
  },
  data: function () {
    return {};
  },
  methods: {},
  beforeMount: function () {},
  watch: {},
  computed: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}
.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}
.modal-container {
  /* max-height: calc(100vh - 80px); */
  overflow-y: auto;
  width: 40%;
  margin: 0px auto;
  padding: 30px 30px;
  background-color: #fff;
  border-radius: 20px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
}
.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}
.modal-body {
  margin: 20px 0;
}
.modal-default-button {
  float: right;
}
@media (max-width: 768px) {
  .modal-container {
    /* max-height: calc(100vh - 80px); */
    overflow-y: auto;
    width: 80%;
  }
}
</style>