<template>
  <div v-if="!isConnected" class="">
    <button
      v-on:click="connectWallet()"
      class="border-2 border-gray-900 bg-gray-900 bg-opacity-100 hover:text-gray-900 hover:bg-white text-white py-2 px-3"
    >
      Connect my wallet
    </button>
  </div>
</template>

<script>
export default {
  name: "ConnectWallet",
  data: function () {
    return {};
  },
  computed: {
    isConnected: function () {
      return this.$store.state.web3 != null;
    },
  },
  methods: {
    connectWallet: function () {
      this.$store.dispatch("connectWallet", this);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
