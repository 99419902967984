<template>
  <footer class="footer py-8 lg:py-10">
    <div class="max-w-full mx-auto px-10">
      <div
        class="flex flex-col md:flex-row md:justify-between justify-center space-y-8 md:space-y-0 items-center"
      >
        <div>
          <div class="flex">
            <div
              class="flex space-x-6 items-center md:justify-start justify-center"
            >
              <div class="flex space-x-6">
                <div class="flex space-x-2 items-center">
                  <img
                    src="@/assets/images/Activity_icon.svg"
                    alt=""
                    class="h-3"
                  />
                  <router-link
                    class="text-white text-small hover:text-yellow-300 transition duration-300 underline"
                    to="/activity"
                    >Activity</router-link
                  >
                </div>
              </div>
            </div>
            <div
              class="ml-6 flex space-x-6 items-center md:justify-start justify-center"
            >
              <div class="flex space-x-6">
                <div class="flex space-x-2 items-center">
                  <!-- <img
                  src="@/assets/images/Activity_icon.svg"
                  alt=""
                  class="h-3"
                /> -->
                  <router-link
                    class="text-white text-small hover:text-yellow-300 transition duration-300 underline"
                    to="/leaderboard"
                    >Leaderboard</router-link
                  >
                </div>
              </div>
            </div>
            <div
              class="ml-6 flex space-x-6 items-center md:justify-start justify-center"
            >
              <div class="flex space-x-6">
                <div class="flex space-x-2 items-center">
                  <!-- <img
                  src="@/assets/images/Activity_icon.svg"
                  alt=""
                  class="h-3"
                /> -->
                  <router-link
                    class="text-white text-small hover:text-yellow-300 transition duration-300 underline"
                    to="/fees"
                    >Fees</router-link
                  >
                </div>
              </div>
            </div>
            <div
              class="ml-6 flex space-x-6 items-center md:justify-start justify-center"
            >
              <div class="flex space-x-6">
                <div class="flex space-x-2 items-center">
                  <!-- <img
                  src="@/assets/images/Activity_icon.svg"
                  alt=""
                  class="h-3"
                /> -->
                  <router-link
                    class="text-white text-small hover:text-yellow-300 transition duration-300 underline"
                    to="/gallery"
                    >Gallery</router-link
                  >
                </div>
              </div>
            </div>
            <div
              class="ml-6 flex space-x-6 items-center md:justify-start justify-center"
            >
              <div class="flex space-x-6">
                <div class="flex space-x-2 items-center">
                  <!-- <img
                  src="@/assets/images/Activity_icon.svg"
                  alt=""
                  class="h-3"
                /> -->
                  <a
                    class="text-white text-small hover:text-yellow-300 transition duration-300 underline"
                    href="https://stats.nft20.io/i"
                    >Stats</a
                  >
                </div>
              </div>
            </div>
            <div
              class="ml-6 flex space-x-6 items-center md:justify-start justify-center"
            >
              <div class="flex space-x-6">
                <div class="flex space-x-2 items-center">
                  <!-- <img
                  src="@/assets/images/Activity_icon.svg"
                  alt=""
                  class="h-3"
                /> -->
                  <a
                    class="text-white text-small hover:text-yellow-300 transition duration-300 underline"
                    href="https://forum.musedao.io"
                    >Forum</a
                  >
                </div>
              </div>
            </div>
             <div
              class="ml-6 flex space-x-6 items-center md:justify-start justify-center"
            >
               <div class="flex space-x-6">
                <div class="flex space-x-2 items-center">
                  <!-- <img
                  src="@/assets/images/Activity_icon.svg"
                  alt=""
                  class="h-3"
                /> -->
                  <router-link
                    class="text-white text-small hover:text-yellow-300 transition duration-300 underline"
                    to="/contributors"
                    >Contributors</router-link
                  >
                </div>
              </div>
            </div>
                        </div>

          </div>
          

          <p
            class="flex text-base font-thin text-white text-center md:text-left opacity-100"
          >
            <img
              src="@/assets/images/logo_footer.svg"
              alt=""
              class="h-6 pr-3 -mx-1"
            />2021 NFT20 All rights reserved ©
          </p>
        </div>
        <div class="flex space-x-8 justify-end">
          <!-- <img
            class="sm:ml-auto"
            src="@/assets/images/icon-footer.png"
            alt=""

            
          /> -->
          <a href="https://discord.gg/kfByfux" target="_blank">
            <img
              src="@/assets/images/logos/Discord_icon.svg"
              alt=""
              class="h-8"
            />
          </a>
          <a href="https://t.me/nft20" target="_blank">
            <img
              src="@/assets/images/logos/Telegram_icon.svg"
              alt=""
              class="h-8"
            />
          </a>
          <a href="https://medium.com/nft20" target="_blank">
            <img
              src="@/assets/images/logos/Medium_icon.svg"
              alt=""
              class="h-8"
            />
          </a>
          <a href="https://twitter.com/niftymuseum" target="_blank">
            <img
              src="@/assets/images/logos/Twitter_icon.svg"
              alt=""
              class="h-8"
            />
          </a>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "Footer",
  props: {},
};
</script>