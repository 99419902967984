<template>
  <transition name="fade">
    <div
      v-if="showCheckout"
      class="fixed inset-x-0 bottom-0 z-50 flex justify-center mb-2"
    >
      <div
        class="
          py-6
          px-10
          border-2 border-gray-100
          md:w-10/12
          w-full
          rounded-md
          bg-tertiary
        "
      >
        <div class="mb-8 uppercase text-xl" v-if="action == 'buy'">
          You receive:
        </div>
        <div class="mb-8 uppercase text-xl" v-if="action == 'sell'">
          You sell:
        </div>
        <div class="flex justify-between items-center align-center">
          <div
            class="
              flex flex-row
              -m-6
              flex-wrap
              max-h-64
              overflow-y-auto
              md:w-4/6
              w-full
            "
          >
            <div
              v-for="(nft, i) of action == 'sell'
                ? casDeposit.nfts_raw
                : casWithdraw.nfts_raw"
              :key="i"
              class="
                hover:opacity-25
                cursor-pointer
                p-6
                flex flex-col
                justify-center
                text-center text-xl text-gray-900
                relative
              "
              id="nftCard"
              @click="deselect(nft.nft_id)"
            >
              <div class="mb-2 capitalize" id="rm-text">Remove</div>
              <div class="flex flex-col justify-center mb-4 items-center">
                <img
                  :src="nft.image_thumbnail_url"
                  class="h-24 w-24 object-cover rounded-md"
                  alt=""
                />
              </div>

              <div class="text-sm">
                <span v-if="nft.name">
                  {{ nft.name }}
                </span>
                <span v-else> ID: {{ nft.nft_id }} </span>
              </div>
            </div>
          </div>
          <div class="w-2/6">
            <div class="flex flex-col justify-end" v-if="action == 'buy'">
              <button
                @click="buyNft"
                class="
                  mt-2
                  text-center
                  uppercase
                  font-primary font-bold
                  leading-none
                  md:text-2xl
                  text-xl
                  inline-block
                  rounded-lg
                  py-2
                  px-5
                  text-brown
                  hover:text-white
                  bg-secondary
                  hover:bg-blue-900 hover:bg-opacity-70
                  transition
                  duration-0
                  focus:outline-none
                "
              >
                Buy now {{ parseFloat((price / 1e18).toFixed(4)) }} ETH
              </button>
              <!-- <div
                class="text-sm font-light text-center mt-2"
                v-if="
                  pool.totalLiquidity <= 30000 && pool.nftBasePriceEth > 0.2
                "
              > -->
              <div class="text-sm font-light text-center mt-2">
                <span class="font-bold">Dislcaimer:</span> You might end up with
                a slightly different amount due to Uniswap price impact,
                checkout 100 {{ pool.symbol }} price on Uniswap for exact.
              </div>
            </div>

            <div v-if="action == 'sell'">
              <button
                v-if="!isApprovedForAll"
                @click="setApprovalForAll"
                class="
                  mt-2
                  w-full
                  text-center
                  uppercase
                  font-primary font-bold
                  leading-none
                  text-2xl
                  inline-block
                  rounded-lg
                  py-2
                  px-5
                  text-brown
                  hover:text-white
                  bg-secondary
                  hover:bg-blue-900 hover:bg-opacity-70
                  transition
                  duration-0
                  focus:outline-none
                "
              >
                <span> Approve </span>
              </button>
              <button
                v-if="!isApprovedForAll"
                class="
                  disabled
                  cursor-not-allowed
                  text-black text-opacity-30
                  bg-gray-400
                  mt-2
                  w-full
                  text-center
                  uppercase
                  font-primary font-bold
                  leading-none
                  text-2xl
                  inline-block
                  rounded-lg
                  py-2
                  px-5
                  transition
                  duration-0
                  focus:outline-none
                "
              >
                Sell now {{ parseFloat((price / 1e18).toFixed(4)) }} ETH
              </button>

              <button
                @click="sellNft"
                v-if="isApprovedForAll"
                class="
                  mt-2
                  w-full
                  text-center
                  uppercase
                  font-primary font-bold
                  leading-none
                  md:text-3xl
                  text-xl
                  inline-block
                  rounded-lg
                  py-2
                  px-5
                  text-brown
                  hover:text-white
                  bg-secondary
                  hover:bg-blue-900 hover:bg-opacity-70
                  transition
                  duration-0
                  focus:outline-none
                "
              >
                Sell now {{ parseFloat((price / 1e18).toFixed(4)) }} ETH
              </button>
              <!-- <div
                class="text-sm font-light text-center mt-2 "
                v-if="
                  pool.uniV3 ||
                  (pool.totalLiquidity <= 30000 && pool.nftBasePriceEth > 0.2)
                "
              >
                <span class="font-bold">Disclaimer:</span> You might end up with
                a different amount due to Uniswap price impact, checkout 100
                {{ pool.symbol }} price on Uniswap for exact.
              </div> -->
              <div class="text-sm font-light text-center mt-2">
                <span class="font-bold">Disclaimer:</span> You might end up with
                a slightly different amount due to Uniswap price impact,
                checkout 100 {{ pool.symbol }} price on Uniswap for exact.
              </div>
            </div>
            <!-- <button
              v-if="action == 'sell'"
              @click="sellNft"
              class="
                mt-2
                w-full
                text-center
                uppercase
                font-primary font-bold
                leading-none
                md:text-3xl
                text-xl
                inline-block
                rounded-lg
                py-4
                px-5
                text-brown
                hover:text-white
                bg-secondary
                hover:bg-blue-900 hover:bg-opacity-70
                transition
                duration-0
                focus:outline-none
              "
            >
              Sell now {{ parseFloat((price / 1e18).toFixed(4)) }} ETH
            </button> -->
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "CheckoutCart",
  components: {},
  data: function () {
    return {
      nfts: [],
    };
  },
  props: {
    pool: Object,
  },
  watch: {
    async checkoutBuyData(newValue) {
      if (newValue == 0) {
        this.showCheckout = false;
      } else {
        this.showCheckout = true;
        this.action = "buy";
        this.price = await this.g_getBuyPrice(
          this.$store.state.casWithdraw.pool_address,
          this.$store.state.casWithdraw.totalQty
        );
      }
    },
    async checkoutSellData(newValue) {
      if (newValue == 0) {
        this.showCheckout = false;
      } else {
        this.showCheckout = true;
        this.action = "sell";
        this.price = await this.g_getSellPrice(
          this.$store.state.casDeposit.pool_address,
          this.$store.state.casDeposit.totalQty
        );
      }
    },
  },
  computed: {
    ...mapGetters({
      casWithdraw: "getCasWithdraw",
      casDeposit: "getCasDeposit",
    }),
    checkoutBuyData: function () {
      return this.casWithdraw.totalQty;
    },
    checkoutSellData: function () {
      return this.casDeposit.totalQty;
    },
  },
  data: function () {
    return {
      price: 0,
      showCheckout: false,
      approvalPending: false,
      isApprovedForAll: false,
      action: null,
    };
  },
  beforeMount: async function () {},
  methods: {
    init: async function () {
      if (this.$store.state.account) {
        let nftContract;
        if (this.pool.type == 1155) {
          nftContract = await this.$store.state.ERC1155(this.pool.nftAddress);
        } else if (this.pool.type == 721) {
          nftContract = await this.$store.state.ERC721(this.pool.nftAddress);
        }
        this.isApprovedForAll = await nftContract.methods
          .isApprovedForAll(
            this.$store.state.account,
            this.$store.state.contracts.NFT20Cas
          )
          .call();

        console.log("is approved ", this.isApprovedForAll);
      } else {
        this.isApprovedForAll = false;
      }
    },
    deselect: async function (id) {
      let data =
        this.action == "buy"
          ? this.$store.state.casWithdraw
          : this.$store.state.casDeposit;

      data.nfts.qty = data.nfts.qty.slice(1);

      data.nfts_raw = data.nfts_raw.filter((x) => {
        return x.nft_id != id;
      });

      data.totalQty -= 1;
      data.nfts.ids = data.nfts.ids.filter((x) => {
        return x != id;
      });

      const setWithdrawOrDeposit =
        this.action == "sell" ? "setCasDeposit" : "setCasWithdraw";

      this.$store.commit(setWithdrawOrDeposit, data);
    },
    sellNft: async function () {
      // this.$emit("close"); //not sure

      let isErc721 = true;
      if (this.pool.type !== 721) isErc721 = false;

      const toNft = this.$store.state.casDeposit.nft_address;
      const ids = this.$store.state.casDeposit.nfts.ids;
      const ammts = this.$store.state.casDeposit.nfts.qty;
      const poolObject = this.$store.state.casDeposit.poolObject;

      let minetx = await this.$store.state.NFT20Cas.methods
        .nftForEth(
          toNft,
          ids,
          ammts,
          isErc721,
          this.pool.lp_fee,
          this.pool.uniV3
        )
        .send({
          from: this.$store.state.account,
        })
        .on("transactionHash", (hash) => {
          console.log("hash of tx ", hash);
          // pass the hash to notify to track it
          this.$store.state.notify.hash(hash);
        });
    },
    setApprovalForAll: async function () {
      let contract;
      if (this.pool.type == 1155) {
        contract = await this.$store.state.ERC1155(this.pool.nftAddress);
      } else if (this.pool.type == 721) {
        contract = await this.$store.state.ERC721(this.pool.nftAddress);
      }

      this.approvalPending = true;
      await contract.methods
        .setApprovalForAll(this.$store.state.contracts.NFT20Cas, true)
        .send({
          from: this.$store.state.account,
        })
        .on("transactionHash", (hash) => {
          console.log("hash of tx ", hash);
          // pass the hash to notify to track it
          this.$store.state.notify.hash(hash);
        });

      this.approvalPending = false;

      this.init();
    },
    buyNft: async function (amount) {
      // params for call
      const toNft = this.$store.state.casWithdraw.nft_address;
      const ids = this.$store.state.casWithdraw.nfts.ids;
      const ammts = this.$store.state.casWithdraw.nfts.qty;
      const poolObject = this.$store.state.casWithdraw.poolObject;

      // const changeIn = "0x6b175474e89094c44da98b954eedeac495271d0f"; //dai
      const changeIn = "0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE"; //eth
      let price = await this.g_getBuyPrice(
        this.$store.state.casWithdraw.pool_address,
        this.$store.state.casWithdraw.totalQty
      );

      let minetx = await this.$store.state.NFT20Cas.methods
        .ethForNft(
          toNft,
          ids,
          ammts,
          this.$store.state.account,
          this.pool.lp_fee,
          this.pool.uniV3
        )
        .send({
          from: this.$store.state.account,
          value: price, ///addd up here if we add fee on buying
        })
        .on("transactionHash", (hash) => {
          console.log("hash of tx ", hash);
          this.showCheckout = false;

          // pass the hash to notify to track it
          this.$store.state.notify.hash(hash);
        });
    },
  },
  mounted: async function () {
    await this.init();
  },
  destroyed: async function () {
    this.showCheckout = false;
  },
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

#nftCard:hover + #rm-text {
  display: block;
}

#rm-text {
  display: none;
}
</style>