<template>
  <div class="mb-96">
    <section
      v-if="projects == null || projects.length == 0"
      class="mx-auto pb-10 flex m-9"
    >
      <img
        src="@/assets/images/logo1.svg"
        alt
        class="h-15 md:h-16 animate-pulse mx-auto"
      />
    </section>

    <div v-if="projects != null && projects.length > 0">
      <div class="fixed z-30 bottom-0 right-0 mb-6 mr-12">
        <a class="" id="back2Top" href="#">
          <div
            class="
              flex
              text-white
              w-16
              h-16
              justify-center
              rounded-xl
              bg-gray-700
              hover:bg-gray-600
              hover:cursor-pointer
              shadow-md
            "
          >
            <img class="h-6 my-auto" src="@/assets/images/up_arrow.svg" alt />
          </div>
        </a>
      </div>
      <div
        class="
          flex
          justify-center
          md:space-y-0
          flex-col-reverse
          md:flex-row
          md:justify-between
          items-center
          text-white
        "
      >
        <div
          class="
            text-2xl
            uppercase
            flex
            items-center
            space-x-2
            cursor-pointer
            hover:text-yellow-300
          "
          v-on:click="onAddAssetClicked()"
        >
          <span class="font-bold text-lg md:text-4xl">+</span>
          <div class="text-lg md:text-2xl">Add asset</div>
        </div>

        <div class="flex relative items-center md:mb-0 mb-6">
          <input
            class="
              rounded-xl
              bg-gray-700 bg-opacity-50
              focus:bg-opacity-70
              text-white
              focus:outline-none
              px-4
              py-4
              w-80
            "
            type="text"
            style
            v-model="search"
            placeholder="Search ..."
          />
          <span class="absolute right-0 pr-4">
            <img class="h-6" src="@/assets/images/search_icon.svg" alt />
          </span>
        </div>
      </div>
      <div
        class="mt-10 justify-center md:justify-end text-white flex space-x-6"
        id="sorting"
      >
        <div
          @click="filter('nfts')"
          class="cursor-pointer hover:text-yellow-300"
        >
          NFTs Locked ↑↓
        </div>
        <div
          @click="filter('tvl')"
          class="cursor-pointer hover:text-yellow-300"
        >
          Liquidity ↑↓
        </div>
          <div
          @click="filter('index_order')"
          class="cursor-pointer hover:text-yellow-300"
        >
          New ↑↓
        </div>
      </div>

      <div
        class="mt-4 grid grid-cols-1 md:px-0 px-12 gap-3 md:gap-2 text-grey-900"
      >
        <div
          class="
            bg-opacity-100 bg-tertiary
            rounded-2xl
            p-5
            cursor-pointer
            shadow-md
          "
          v-for="project in filteredList"
          :key="project.address"
          v-on:click="onAssetClick(project.address)"
        >
          <AssetCard :project="project" class="" />
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
::placeholder {
  color: white;
  opacity: 0.7;
}
</style>
<script>
// @ is an alias to /src
import ProjectView from "@/components/ProjectView.vue";
import AssetCard from "@/components/AssetCard.vue";
import ConnectButton from "@/components/wallet/ConnectButton.vue";

export default {
  name: "Home",
  components: {
    ProjectView,
    AssetCard,
    ConnectButton,
  },
  data: function () {
    return {
      search: "",
    };
  },
  mounted: async function () {},
  methods: {
    onAssetClick: function (contract) {
      this.$router.push({ name: "Asset", params: { pair: contract } });
    },
    onAddAssetClicked: function () {
      this.$router.push({ name: "Create", params: {} });
    },
    filter: async function (what) {
      if (what == "nfts") {
        this.$store.state.pairs.sort(function (a, b) {
          return b.supply - a.supply;
        });
      } else if (what == "tvl") {
        this.$store.state.pairs.sort(function (a, b) {
          return b.totalLiquidity - a.totalLiquidity;
        });
      } else if (what == "index_order") {
        this.$store.state.pairs.sort(function (a, b) {
          return b.index_order - a.index_order;
        });
      }
    },
  },
  computed: {
    projects: function () {
      return this.$store.state.pairs;
    },
    filteredList() {
      return this.$store.state.pairs.filter((asset) => {
        return asset.name.toLowerCase().includes(this.search.toLowerCase());
      });
    },
  },
};

// const back2Top = document.querySelector('#back2Top');
// window.addEventListener("scroll", (e) => {
//   if (window.scrollY >= 400) {

//     back2Top.classList.add("hidden");
//   } else {

//     back2Top.classList.remove("hidden");
//   }
// });
</script>
