<template>
  <div style="width: 100%">
    <div ref="chart" style="width: 100%; height: 400px" />
  </div>
</template>

<script>
const axios = require("axios").default;

import * as echarts from "echarts";

export default {
  name: "ChartView",
  props: { nft: String },
  data: function () {
    return {
      rawData: null,
    };
  },
  methods: {
    init: async function () {
      let res = await axios.get(
        "https://api.nft20.io/price/hour?address=" + this.nft
      );
      let data = [];
      let dates = [];
      for (const tick of res.data) {
        data.push([tick.o_usd, tick.c_usd, tick.l_usd, tick.h_usd]);
        dates.push(tick.time);
      }

      console.log(res);
      var myChart = echarts.init(this.$refs.chart);
      // Draw the chart
      myChart.setOption({
         xAxis: {
        type: 'category',
        data: dates,
        axisLine: { lineStyle: { color: '#8392A5' } }
    },
    yAxis: {
        scale: true,
        axisLine: { lineStyle: { color: '#8392A5' } },
        splitLine: { show: false }
    },
    grid: {
        bottom: 80
    },
    dataZoom: [{
        textStyle: {
            color: '#8392A5'
        },
        handleIcon: 'path://M10.7,11.9v-1.3H9.3v1.3c-4.9,0.3-8.8,4.4-8.8,9.4c0,5,3.9,9.1,8.8,9.4v1.3h1.3v-1.3c4.9-0.3,8.8-4.4,8.8-9.4C19.5,16.3,15.6,12.2,10.7,11.9z M13.3,24.4H6.7V23h6.6V24.4z M13.3,19.6H6.7v-1.4h6.6V19.6z',
        dataBackground: {
            areaStyle: {
                color: '#8392A5'
            },
            lineStyle: {
                opacity: 0.8,
                color: '#8392A5'
            }
        },
        brushSelect: true
    }, {
        type: 'inside'
    }],
        series: [
          {
            type: "candlestick",
            name: "USD Price",
            data: data,
          
          },
        ],
      });
    },
  },
  computed: {},
  mounted: async function () {
    this.init();
  },
};
</script>
