import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Asset from "../views/Asset.vue";
import Create from "../views/Create.vue";
import Farm from "../views/Farm.vue";
import About from "../views/About.vue";
import Faqs from "../views/Faqs.vue";
import Artists from "../views/Artists.vue";
import Activity from "../views/Activity.vue";
import Discover from "../views/Discover.vue";
import Leaderboard from "../views/Leaderboard.vue";
import Gallery from "../views/Gallery.vue";
import Trade from "../views/Trade.vue";
import NFT from "../views/NFT.vue";
import Account from "../views/Account.vue";
import Fees from "../views/Fees.vue";
import UserGallerySelection from "../components/gallery/UserGallerySelection.vue";
import UserGallery from "../components/gallery/UserGallery.vue";
import Flash from "../views/Flash.vue";
import Cas from "../views/Cas.vue";
import Wallet from "../views/Wallet.vue";
import Contributors from "../views/Contributors.vue";

import Tweet from "../views/Tweet.vue";

import CryptoCats from "../views/CryptoCats.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/gallery/:address",
    name: "UserGallery",
    component: UserGallery,
  },
  {
    path: "/cas",
    name: "Cas",
    component: Cas,
  },
  {
    path: "/gallery",
    name: "UserGallerySelection",
    component: UserGallerySelection,
  },
  {
    path: "/assets",
    name: "Home",
    component: Home,
  },
  {
    path: "/create",
    name: "Create",
    component: Create,
  },
  {
    path: "/farm",
    name: "Farm",
    component: Farm,
  },
  {
    path: "/faqs",
    name: "Faqs",
    component: Faqs,
  },
  {
    path: "/hashmaskflash",
    name: "Flash",
    component: Flash,
  },
  {
    path: "/fees",
    name: "Fees",
    component: Fees,
  },
  {
    path: "/discover",
    name: "Discover",
    component: Discover,
  },
  {
    path: "/trade",
    name: "Trade",
    component: Trade,
  },
  {
    path: "/artists",
    name: "Artists",
    component: Artists,
  },
  {
    path: "/",
    name: "About",
    component: About,
  },
  {
    path: "/activity",
    name: "Activity",
    component: Activity,
  },
  {
    path: "/leaderboard",
    name: "Leaderboard",
    component: Leaderboard,
  },
  {
    path: "/nfts",
    name: "Gallery",
    component: Gallery,
  },
  {
    path: "/asset/:pair",
    name: "Asset",
    component: Asset,
  },
  {
    path: "/nft/:address/:nftId",
    name: "NFT",
    component: NFT,
  },
  {
    path: "/account/:address",
    name: "Account",
    component: Account,
  },
  {
    path: "/cryptocats",
    name: "CryptoCats",
    component: CryptoCats,
  },
  {
    path: "/wallet",
    name: "Wallet",
    component: Wallet,
  },
  {
    path: "/contributors",
    name: "Contributors",
    component: Contributors,
  },
  {
    path: "/tweet",
    name: "Tweet",
    component: Tweet,
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
