<template>
  <div>
    <GalleryItemDetailModal
      v-if="showDetail"
      @close="showDetail = false"
      :nft="nft"
      :pool="collection.twentyPool"
      :isAction="'sell'"
    />
    <article class="overflow-hidden">
      <div
        class="
          relative
          text-white
          rounded
          text-2xl
          font-bold
          overflow-hidden
          cursor-pointer
        "
      >
        <img
          alt="Placeholder"
          class="block h-64 w-full rounded-sm object-fit"
          :src="nft.image_preview_url"
        />
        <div
          v-if="parseInt(nft.availabe_quantity) > 1"
          class="ribbon bg-red-500 text-sm whitespace-no-wrap pt-1"
        >
          x {{ nft.availabe_quantity }}
        </div>
      </div>
      <h1 class="text-white text-center text-base font-bold pt-4">
        {{ nft.name }}
      </h1>

      <!-- <h1
        v-if="collection.twentyPool"
        class="text-white text-center text-base font-bold pt-4"
      >
        {{ collection.twentyPool.nftBasePrice }} $ Here is the NFT20 pool
        information
      </h1> -->
      <button
        v-if="
          collection.twentyPool && collection.twentyPool.totalLiquidity > 400 && owner
        "
        @click="showDetail = true"
        class="
          text-white text-center
          rounded
          border
          w-full
          mt-2
          hover:bg-gray-100
          py-2
          hover:text-gray-900
          outline-none
          focus:outline-none
        "
      >
        Sell in 1 click
      </button>
    </article>
  </div>
</template>


<script>
import GalleryItemDetailModal from "@/components/GalleryItemDetailModal.vue";

export default {
  name: "GalleryItem",
  components: { GalleryItemDetailModal },

  props: {
    nft: Object,
    collection: Object,
    owner: Boolean
  },
  computed: {},
  data: function () {
    return {
      showDetail: false,
    };
  },
  beforeMount: async function () {},
  methods: {
    // transformNftObj: async function () {
    //   // this is to make it work with the modal..
    //   this.nft.nft_image = this.nft.image_preview_url;
    //   this.nft.nft_id = this.nft.token_id;
    //   this.nft.nft_contract = this.nft.asset_contract.address;
    // },
  },
  mounted: async function () {
    // console.log("pool ", this.collection.twentyPool.totalLiquidity);
  },
  computed: {},
};
</script>

<style scoped>
.ribbon {
  position: absolute;
  top: 0;
  right: 0;
  width: 150px;
  height: 22px;
  margin-right: -50px;
  margin-top: 15px;
  text-align: center;
  -ms-transform: rotate(45deg); /* IE 9 */
  -webkit-transform: rotate(45deg); /* Safari prior 9.0 */
  transform: rotate(45deg); /* Standard syntax */
}
</style>