<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper" @click="$emit('close')">
        <div
          class="modal-container bg-tertiary rounded-4xl text-gray-900"
          @click.stop
        >
          <div
            class="
              modal-header
              flex
              items-center
              justify-between
              uppercase
              text-base
              tracking-normal
              font-normal
            "
          >
            <slot name="header " class="text-xl">Transaction Details </slot>
            <div class="text-xl cursor-pointer" @click="$emit('close')">X</div>
          </div>

          <div class="modal-body">
            <slot name="body">
              <div class="text-xl font-semibbold" v-if="processingTransaction">
                Processing transaction ...
              </div>
              <div
                class="text-xl nfts overflow-auto font-semibbold"
                v-if="!processingTransaction"
              >
                <!-- <div>Summary</div> -->
                <div class="my-6 font-bold">You deposit</div>
                <div class="">
                  <div v-if="depositData">
                    <div
                      v-for="(data, i) of depositData"
                      :key="i"
                      class="flex flex-col items-center justify-center"
                    >
                      <div class="mb-2">
                        <img
                          :src="data.image_thumbnail_url"
                          class="rounded-lg h-24 object-cover"
                          alt=""
                        />
                      </div>
                      <div>ID #{{ data.token_id }}</div>
                    </div>
                  </div>
                  <div v-else class="text-2xl text-center">
                    <!-- {{ $store.state.casDepsoit.nfts.ids }} -->
                    {{ ($store.state.casPrice / 10 ** 18).toFixed(6) }} ETH
                  </div>
                </div>
                <div class="mt-12 mb-6 font-bold">You withdraw</div>
                <div class="">
                  <div
                    v-if="withdrawData"
                    class="flex space-x-8 justify-center items-center"
                  >
                    <div
                      v-for="(data, i) of withdrawData"
                      :key="i"
                      class="flex flex-col items-center justify-center"
                    >
                      <div>
                        <img
                          :src="data.image_thumbnail_url"
                          class="rounded-lg h-24 object-cover"
                          alt=""
                        />
                      </div>
                      <div>ID #{{ data.token_id }}</div>
                    </div>
                  </div>
                  <div v-else class="text-2xl text-center">
                    <!-- {{ $store.state.casDepsoit.nfts.ids }} -->
                    {{ ($store.state.casPrice / 10 ** 18).toFixed(6) }} ETH
                  </div>
                </div>
              </div>
            </slot>
          </div>
          <div class="modal-footer">
            <slot name="footer">
              <div class="flex space-x-4 mt-10">
                <button
                  v-if="!isApprovedForAll"
                  target="_blank"
                  class="
                    flex
                    items-center
                    w-full
                    mt-12
                    text-center
                    uppercase
                    font-primary font-bold
                    leading-none
                    text-xl
                    py-6
                    rounded-xl
                    px-5
                    text-brown
                    hover:text-white
                    bg-secondary
                    hover:bg-blue-400 hover:bg-opacity-70
                    transition
                    duration-0
                    focus:outline-none
                  "
                  :class="{
                    'justify-between': approvalPending,
                    'justify-center': !approvalPending,
                  }"
                  @click="setApproalForAll"
                >
                  <div>Approve</div>
                  <div class="loader" v-if="approvalPending"></div>
                </button>
                <button
                  v-if="isApprovedForAll && !processingTransaction"
                  @click="$emit('confirmSwap')"
                  class="
                    mt-12
                    w-full
                    text-center
                    uppercase
                    font-primary font-bold
                    leading-none
                    text-xl
                    inline-block
                    py-6
                    rounded-xl
                    px-5
                    text-brown
                    hover:text-white
                    bg-secondary
                    hover:bg-blue-400 hover:bg-opacity-70
                    transition
                    duration-0
                    focus:outline-none
                  "
                >
                  CONFIRM
                </button>
              </div>
              <div class="mt-10 text-md">
                <span class="font-bold">Disclaimer</span>: the final price might
                be different due to price impact and liquidity.
                <span class="text-red-600">For exact price check Uniswap.</span>
              </div>
            </slot>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
const axios = require("axios").default;

export default {
  name: "DetailsModal",
  components: {},
  props: { processingTransaction: Boolean },
  data: function () {
    return {
      depositData: null,
      withdrawData: null,
      isApprovedForAll: false,
      approvalPending: false,
    };
  },
  methods: {
    parseNftData: async function (arr1, arr2) {
      var props = ["token_id", "image_thumbnail_url"];

      var result = arr1
        .filter(function (o1) {
          // filter out (!) items in result2
          return arr2.some(function (o2) {
            return o1.token_id === o2; // assumes unique id
          });
        })
        .map(function (o) {
          // use reduce to make objects with only the required properties
          // and map to apply this to the filtered array as a whole
          return props.reduce(function (newo, name) {
            newo[name] = o[name];
            return newo;
          }, {});
        });

      return result;
    },

    getData: async function () {
      if (this.$store.state.casDeposit.nfts_raw) {
        const arr1 = this.$store.state.casDeposit.nfts_raw;
        const arr2 = this.$store.state.casDeposit.nfts.ids;

        this.depositData = await this.parseNftData(arr1, arr2);
      }

      if (this.$store.state.casWithdraw.nfts_raw) {
        const arr1 = this.$store.state.casWithdraw.nfts_raw;
        const arr2 = this.$store.state.casWithdraw.nfts.ids;

        this.withdrawData = await this.parseNftData(arr1, arr2);
      }
    },
    setApproalForAll: async function () {
      console.log("deposit ", this.$store.state.casDeposit);
      let contract;
      if (this.$store.state.casDeposit.schema == 1155) {
        contract = await this.$store.state.ERC1155(
          this.$store.state.casDeposit.nft_address
        );
      } else if (this.$store.state.casDeposit.schema == 721) {
        contract = await this.$store.state.ERC721(
          this.$store.state.casDeposit.nft_address
        );
      }

      this.approvalPending = true;
      await contract.methods
        .setApprovalForAll(
          this.$store.state.casWithdraw.schema == 1155 ||
            this.$store.state.casWithdraw.schema == 721
            ? this.$store.state.casDeposit.pool_address
            : this.$store.state.contracts.NFT20Cas,
          true
        )
        .send({
          from: this.$store.state.account,
        })
        .on("transactionHash", (hash) => {
          console.log("hash of tx ", hash);
          // pass the hash to notify to track it
          this.$store.state.notify.hash(hash);
        });
      this.approvalPending = false;

      this.init();
    },
    init: async function () {
      // this means is a sell so we add approval ,etc
      console.log(this.$store.state.casDeposit.schema);
      if (this.$store.state.casDeposit.schema != 20) {
        let nftContract;
        if (this.$store.state.casDeposit.schema == 1155) {
          nftContract = await this.$store.state.ERC1155(
            this.$store.state.casDeposit.nft_address
          );
        } else if (this.$store.state.casDeposit.schema == 721) {
          nftContract = await this.$store.state.ERC721(
            this.$store.state.casDeposit.nft_address
          );
        }
        if (this.$store.state.account) {
          console.log(this.$store.state.casDeposit);
          this.isApprovedForAll = await nftContract.methods
            .isApprovedForAll(
              this.$store.state.account,
              this.$store.state.casWithdraw.schema == 1155 ||
                this.$store.state.casWithdraw.schema == 721
                ? this.$store.state.casDeposit.pool_address
                : this.$store.state.contracts.NFT20Cas
            )
            .call();

          console.log("is approved ", this.isApprovedForAll);
        } else {
          this.isApprovedForAll = false;
        }
      } else {
        this.isApprovedForAll = true;
      }
    },
  },
  beforeMount: async function () {},
  mounted: async function () {
    console.log("monted");
    this.getData();
    this.init();
  },
  watch: {},
  computed: {},
  created: async function () {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
::placeholder {
  /* padding: 0px 1rem; */
  /* maybe remove this */
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Hide the browser's default checkbox */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
}
/* On mouse-over, add a grey background color */
.container:hover input ~ .checkmark {
  background-color: #ccc;
}
/* When the checkbox is checked, add a blue background */
.container input:checked ~ .checkmark {
  background-color: #2196f3;
}
/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}
/* Show the checkmark when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}
/* Style the checkmark/indicator */
.container .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.input {
  background-color: #afaca2;
  cursor: pointer;
}
/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
  background-color: #afaca2;
  color: white;
  border-radius: 6px;
  text-align: center;
}
input[type="number"]:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
}
.nfts {
  max-height: calc(440px);
}
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}
.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}
.modal-container {
  /* max-height: calc(100vh - 80px); */
  /* overflow-y: hidden; */
  width: 40%;
  margin: 0px auto;
  padding: 30px 30px;
  /* background-color: #fff; */
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
}
.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}
.modal-body {
  margin: 20px 0;
}
.modal-default-button {
  float: right;
}
@media (max-width: 768px) {
  .modal-container {
    /* max-height: calc(100vh - 80px); */
    /* overflow-y: auto; */
    width: 80%;
  }
}

.loader {
  border: 3px solid #f3f3f3; /* Light grey */
  border-top: 3px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>