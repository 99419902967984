<template>
  <div
    class="
      grid grid-flow-col
      md:grid-flow-row
      grid-cols-1
      md:px-0
      gap-4
      md:gap-2
      text-grey-900
    "
  >
    <div
      class=""
      v-for="collection in collections"
      :key="collection.address"
      v-on:click="onClick(collection)"
    >
      <div
        class="
          bg-opacity-100 bg-tertiary
          rounded-2xl
          p-1
          cursor-pointer
          shadow-md
          flex flex-col
          md:flex-row
        "
        v-bind:class="{ selected: selected == collection }"
      >
        <div class="md:flex md:flex-col w-100">
          <h2 class="flex text-grey-900 items-center text-s mb-0">
            <i
              class="
                bg-grey-500
                rounded-full
                bg-white bg-opacity-30
                h-10
                md:h-10
                w-10
                md:w-10
                mr-4
                flex
                items-center
                justify-center
                shadow-sm
              "
            >
              <!-- <img src="@/assets/images/icon-meme.png" alt="" /> -->
              <img
                class="rounded-full object-cover shadow-xl"
                v-if="collection.image_url"
                :src="collection.image_url"
                alt
              />
            </i>
            <span>{{ collection.name }} ({{ collection.owned }})</span>
          </h2>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CollectionList",
  components: {},
  data: function () {
    return {
      selected: null,
    };
  },
  props: {
    collections: Array,
  },
  computed: {},
  methods: {
    onClick: function (collection) {
      if (this.selected == collection) {
        this.selected = null;
      } else {
        this.selected = collection;
      }
      this.$emit("selected-collection", this.selected);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.selected {
  background-color: #edb855 !important;
}
.ribbon {
  position: absolute;
  top: 0;
  right: 0;
  width: 150px;
  height: 22px;
  margin-right: -50px;
  margin-top: 15px;
  text-align: center;
  -ms-transform: rotate(45deg); /* IE 9 */
  -webkit-transform: rotate(45deg); /* Safari prior 9.0 */
  transform: rotate(45deg); /* Standard syntax */
}
</style>