<template>
  <div class="relative">
    <div
      v-if="isMouseOver && traits.length > 0"
      class="
        hidden
        md:block
        absolute
        z-50
        rounded
        inset-y-0
        left-0
        -ml-64
        bg-gray-800
        p-6
        h-auto
      "
      style="opacity: 0.96"
    >
      <div class="text-2xl mb-4 text-white">
        {{ nft.nft_title }}
      </div>
      <ul class="text-l f">
        <li v-for="trait in traits" :key="trait.trait_type" class="mb-2">
          {{ trait.trait_type }}: {{ trait.value }}
        </li>
      </ul>
    </div>
    <GalleryItemDetailModal
      v-if="showDetail"
      @close="showDetail = false"
      :nft="nft"
      :pool="pool"
      :isAction="'buy'"
    />
    <article class="overflow-hidden">
      <div
        class="
          relative
          text-white
          rounded
          text-2xl
          font-bold
          overflow-hidden
          cursor-pointer
        "
      >
        <!-- CATS,SANDBOX and a few other <img alt="Placeholder" class="block h-40 w-auto rounded-sm object-cover mx-auto" :src="nft.nft_image" /> -->
        <!-- most items <img alt="Placeholder" class="block h-auto w-full rounded-sm object-cover" :src="nft.nft_image" /> -->
        <div
          class="block"
          @mouseover="mouseOver"
          @mouseleave="isMouseOver = false"
        >
          <img
            v-if="!nft.nft_image.endsWith('.mp4')"
            alt="Placeholder"
            class="block rounded-sm object-cover h-56 w-56 m-auto"
            :src="nft.nft_image"
          />
          <video
            v-if="nft.nft_image.endsWith('.mp4')"
            alt="Placeholder"
            class="block rounded-sm object-cover h-64 w-full m-auto"
            muted
            autoplay
            loop
          >
            <source :src="nft.nft_image" type="video/mp4" />
          </video>
        </div>

        <div
          v-if="parseInt(nft.availabe_quantity) > 1"
          class="ribbon bg-red-500 text-sm whitespace-no-wrap pt-1"
        >
          x{{ nft.availabe_quantity }}
        </div>
      </div>
      <header class="tracking-wide cursor-pointer">
        <h1 class="text-white text-center text-base font-bold pt-4">
          <span v-if="nft.nft_title"> {{ nft.nft_title }}</span>
          <span v-else>ID {{ nft.nft_id }}</span>
        </h1>
      </header>

      <button
        v-if="
          pool.address != '0x7a911c71144f4d5a00e4216b1c5b12d9571e9336' &&
          pool.lpToken &&
          (pool.totalLiquidity >= 1000 ||
            (pool.nftBasePrice < 100 && pool.totalLiquidity >= 200)) &&
          !pool.uniV3
        "
        @click="select(nft.nft_image, nft.nft_id)"
        class="
          mt-2
          w-full
          text-center
          uppercase
          font-primary font-bold
          leading-none
          text-xl
          inline-block
          rounded-lg
          py-2
          px-5
          text-brown
          hover:text-white
          bg-secondary
          hover:bg-blue-900 hover:bg-opacity-70
          transition
          duration-0
          focus:outline-none
        "
      >
        <span v-if="$store.state.casWithdraw.totalQty == 0 && !sell"
          >Buy Now</span
        >
        <span v-if="!sell && $store.state.casWithdraw.totalQty > 0"
          >Add to Cart</span
        >
        <span v-if="sell">Sell NFT</span>
      </button>
    </article>
  </div>
</template>


<script>
import GalleryItemDetailModal from "@/components/GalleryItemDetailModal.vue";
import { mapGetters } from "vuex";

import axios from "axios";

export default {
  name: "GalleryItem",
  components: { GalleryItemDetailModal },

  props: {
    nft: Object,
    pool: Object,
    sell: Boolean,
  },
  computed: {},
  data: function () {
    return {
      showDetail: false,
      isSelected: false,
      isMouseOver: false,
      traits: [],
    };
  },
  beforeMount: async function () {},

  methods: {
    select: async function (img, id) {
      this.isSelected = !this.isSelected;

      // here if wedine if to set "sell" (deposit nfts), or "buy" (withdraw nfts)
      let data = this.sell
        ? this.$store.state.casDeposit
        : this.$store.state.casWithdraw;

      if (this.isSelected) {
        data.nfts.ids.push(this.nft.nft_id);
        data.nfts.qty.push(1);

        data.totalQty += 1;

        // adjust object so it fits data from opensea for "casDeposit"
        this.nft.asset_contract = {};
        this.nft.asset_contract.address = this.nft["nft_contract"];

        if (
          this.nft.nft_contract == "0xe4605d46fd0b3f8329d936a8b258d69276cba264"
        ) {
          this.nft.asset_contract.schema_name = "ERC1155";
        } else {
          this.nft.asset_contract.schema_name = "ERC721";
        }
        this.nft.name = this.nft["nft_title"];
        this.nft.image_thumbnail_url = this.nft["nft_image"];
        this.nft.token_id = this.nft["nft_id"];

        data.nfts_raw.push(this.nft);
      } else {
        // here we deselect this

        data.nfts.qty = data.nfts.qty.slice(1);

        data.nfts_raw = data.nfts_raw.filter((x) => {
          return x.nft_id != this.nft.nft_id;
        });

        data.nfts.ids = data.nfts.ids.filter((x) => {
          return x != this.nft.nft_id;
        });

        if (data.totalQty == 0) {
          data.totalQty = 0;
        } else {
          data.totalQty -= 1;
        }
      }

      console.log(data);
      const setWithdrawOrDeposit = this.sell
        ? "setCasDeposit"
        : "setCasWithdraw";

      console.log(setWithdrawOrDeposit);

      this.$store.commit(setWithdrawOrDeposit, data);
    },
    mouseOver: async function () {
      this.isMouseOver = true;

      const url = `https://api.nft20.io/nft/${this.nft.nft_contract}/${this.nft.nft_id}`;

      const { data } = await axios.get(url);

      this.traits = data.nft.nft_trait;
    },
  },
  mounted: async function () {},
};
</script>

<style scoped>
.ribbon {
  position: absolute;
  top: 0;
  right: 0;
  width: 150px;
  height: 22px;
  margin-right: -50px;
  margin-top: 15px;
  text-align: center;
  -ms-transform: rotate(45deg); /* IE 9 */
  -webkit-transform: rotate(45deg); /* Safari prior 9.0 */
  transform: rotate(45deg); /* Standard syntax */
}
</style>