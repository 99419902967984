<template>
  <div class="flex flex-col md:flex-row">
    <div class="md:flex md:flex-col w-100 md:w-1/4 pb-8 md:pb-0">
      <h2
        class="flex text-grey-900 items-center uppercase text-sm font-bold mb-0"
      >
        <i
          class="
            bg-grey-500
            rounded-full
            bg-white bg-opacity-30
            h-16
            md:h-14
            w-16
            md:w-14
            mr-4
            flex
            items-center
            justify-center
            shadow-sm
          "
        >
          <!-- <img src="@/assets/images/icon-meme.png" alt="" /> -->
          <img
            class="rounded-full object-cover shadow-xl"
            v-if="project.logo"
            :src="project.logo"
            alt
          />
        </i>
        <li class="flex justify-between md:flex-col uppercase">
          <div class="font-semibold text-sm md:text-sm">{{ project.name }}</div>
          <div class="font-medium text-base">{{ project.symbol }}</div>
        </li>
      </h2>
    </div>
    <div class="md:flex md:flex-col md:w-3/4 md:pt-2">
      <ul class="md:mb-0 md:max-w-full md:flex md:flex-row md:justify-between">
        <!-- <li class="flex justify-between">
          <div>Total Value locked:</div>
          <div class="font-semibold">$3000</div>
        </li> -->
        <li class="flex justify-between md:flex-col md:w-1/5 uppercase">
          <div class="font-medium text-sm md:text-sm">NFTs in Pool:</div>

          <div
            class="font-semibold text-base"
            v-if="
              project.address == `0x7a911C71144f4d5a00E4216b1c5b12D9571E9336`
            "
          >
            {{ Math.round((project.nftLocked * 100) / project.nftValue) }}
          </div>

          <div class="font-semibold text-base" v-else>
            {{ project.nftLocked }}
          </div>
        </li>

        <!-- <li class="flex justify-between md:flex-col md:w-1/5 uppercase">
          <div class="font-medium text-sm md:text-sm">
            ${{ project.symbol }} Supply:
          </div>
          <div class="font-semibold text-base">{{ project.supply }}</div>
        </li> -->

        <li class="flex justify-between md:flex-col md:w-1/5 uppercase">
          <div class="font-medium text-sm md:text-sm">
            Trade ${{ project.symbol }}:
          </div>
          <div
            class="
              font-semibold
              text-base
              hover:text-primary
              transition
              duration-300
            "
            @click.stop
          >
            <a target="_blank" :href="project.uniswap">Uniswap</a>
          </div>
        </li>

        <li class="flex justify-between md:flex-col md:w-1/5 uppercase">
          <div class="font-medium text-sm md:text-sm">Liquidity:</div>
          <div class="font-semibold text-base">
            ${{ project.totalLiquidity.toFixed(2) }}
          </div>
        </li>
        <li class="flex justify-between md:flex-col md:w-1/5 uppercase">
          <div class="font-medium text-sm md:text-sm">Floor Price:</div>
          <div class="font-semibold text-base">
            <span v-if="project.nftBasePrice > 0" class=""
              >${{ project.nftBasePrice.toFixed(0) }} ({{
                project.nftBasePriceEth.toFixed(2)
              }}ETH)</span
            >
            <span v-if="project.nftBasePrice == 0" class="">TBD</span>
          </div>
        </li>
        <li
          class="
            flex
            justify-between
            md:flex-col
            md:w-1/5
            uppercase
            text-center
          "
        >
          <div
            class="font-medium text-sm md:text-sm"
            v-if="project.price_data != null"
          >
            7 Days change
          </div>
          <div class="font-semibold text-base text-center">
            <span
              v-if="project.price_data != null"
              class=""
              v-bind:class="{
                'text-green-700': project.price_data.one_week_change > 0,
                'text-red-700': project.price_data.one_week_change > 0,
              }"
            >
              <span v-if="project.price_data.one_week_change > 0">+</span
              >{{ project.price_data.one_week_change }}%</span
            >
            <span v-if="project.nftBasePrice == 0" class="">TBD</span>
          </div>
        </li>
        <li class="flex justify-between md:flex-col md:w-1/5 uppercase">
          <trend
            v-if="project.price_data != null"
            style="height: 100%"
            :data="project.price_data.trendline.usd"
            :gradient="[
              project.price_data.one_week_change > 0 ? '#008000' : '#FF0000',
            ]"
            smooth
            strokeWidth="10"
          />
        </li>
      </ul>
    </div>
  </div>
</template>



<script>
import BigNumber from "bignumber.js";
import NFT20Abi from "@/assets/abis/Pair";

export default {
  name: "AssetCard",
  props: { project: Object },
  data: function () {
    return {
      infos: {},
      tokenSupply: 0,
      wetContract: null,
    };
  },
  methods: {},
  mounted: async function () {
    console.log(this.project.price_data);
  },
  beforeMount: async function () {},
};
</script>
<style scoped>
button:hover {
  /*filter: drop-shadow(0.707px 0.707px 1.5px rgba(0, 0, 0, 0.25));*/
  /*background-image: linear-gradient(-90deg, #4b82de 0%, #60a5fa 100%);*/
}
</style>