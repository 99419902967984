<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper" @click="$emit('close')">
        <div
          class="modal-container bg-tertiary rounded-4xl text-gray-900"
          @click.stop
        >
          <div class="modal-header flex items-center justify-between uppercase">
            <slot name="header ">
              <div>Select {{ project.name }} NFTs</div>
              <div @click="$emit('close')" class="cursor-pointer text-lg">
                <img src="@/assets/images/x.svg" alt />
              </div>
            </slot>
          </div>

          <div class="modal-body">
            <slot name="body">
              <span class="text-xl w-full">
                <input
                  type="text"
                  style="border-color: #b2aea8"
                  class="
                    w-full
                    py-3
                    px-3
                    rounded-lg
                    bg-gray-200
                    border-l border-t
                    rounded
                    focus:outline-none
                  "
                  placeholder="Search by token ID"
                  v-model="search"
                />

                <div
                  class="
                    flex
                    uppercase
                    justify-between
                    items-center
                    mt-10
                    mr-14
                    text-sm
                  "
                >
                  <div>NFT</div>
                  <div v-if="project.type == 1155">
                    <!-- <svg
                      class="w-6 h-4"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="3"
                        d="M16 17l-4 4m0 0l-4-4m4 4V3"
                      ></path>
                    </svg>-->
                    QTY
                  </div>
                </div>
                <div class="mt-4 nfts overflow-auto z-0" style>
                  <div v-if="filteredNfts.length == 0" class="text-center">
                    No {{ project.name }} NFTs found
                  </div>
                  <div
                    v-else
                    v-for="nft in filteredNfts"
                    :key="nft.id"
                    class="
                      flex
                      justify-between
                      items-center
                      py-0
                      my-6
                      cursor-pointer
                      hover:border
                    "
                  >
                    <div>
                      <a
                        :href="`https://opensea.io/assets/${project.nftAddress}/${nft.token_id}`"
                        target="_blank"
                      >
                        <video
                          class="object-cover w-32 sm:w-40"
                          controls
                          muted
                          autoplay
                          v-if="nft.animation_url && !nft.image_thumbnail_url"
                          :src="nft.animation_url"
                        ></video>
                        <img
                          v-else
                          :src="nft.image_thumbnail_url"
                          alt
                          class="object-cover w-32 sm:w-40"
                        />
                      </a>
                    </div>
                    <div class="text-base font-normal">
                      <div class="font-bold">
                        NFT #{{ nft.token_id.slice(0, 8) }}
                      </div>
                      <div class="-mt-2 mb-1">
                        <span class="text-sm" v-if="project.type == 1155"
                          >{{ erc1155Availablevalues[nft.token_id] }} Available
                        </span>
                      </div>
                      <div class="cursor-pointer hover:underline">
                        <a
                          :href="`https://opensea.io/assets/${project.nftAddress}/${nft.token_id}`"
                          target="_blank"
                        >
                          View details
                        </a>
                      </div>
                    </div>
                    <div
                      class="flex input px-2 rounded-lg mr-4 py-2"
                      v-if="project.type == 1155"
                    >
                      <button
                        class="focus:outline-none"
                        type="button"
                        @click="
                          move(
                            'minus',
                            nft.token_id,
                            erc1155Availablevalues[nft.token_id]
                          )
                        "
                      >
                        <img
                          src="@/assets/images/left_arrow.svg"
                          class="h-3"
                          alt
                        />
                      </button>
                      <input
                        type="number"
                        min="0"
                        :max="erc1155Availablevalues[nft.token_id]"
                        v-model.number="erc1155values[nft.token_id]"
                        class="w-10 erc1155"
                      />
                      <button
                        class="focus:outline-none"
                        type="button"
                        @click="
                          move(
                            'plus',
                            nft.token_id,
                            erc1155Availablevalues[nft.token_id]
                          )
                        "
                      >
                        <img
                          src="@/assets/images/right_arrow.svg"
                          class="h-3"
                          alt
                        />
                      </button>
                    </div>
                    <div v-else-if="project.type == 721">
                      <input
                        v-if="!swap && project.factoryVersion !== 1"
                        class="mr-6"
                        type="checkbox"
                        :name="nft.token_id"
                        :value="nft.token_id"
                        v-model="checkedNfts"
                      />

                      <input
                        v-else
                        class="mr-6"
                        type="radio"
                        :id="nft.token_id"
                        :name="nft.token_id"
                        :value="nft.token_id"
                        v-model="checkedNfts"
                      />
                    </div>
                  </div>
                  <hr class />
                </div>
                <!-- <div>{{ text }}</div> -->
              </span>
            </slot>
          </div>

          <div class="modal-footer z-50">
            <slot name="footer">
              <div class>
                <button
                  @click="select"
                  class="
                    w-full
                    text-center
                    uppercase
                    font-primary font-bold
                    leading-none
                    text-2xl
                    inline-block
                    rounded-lg
                    py-4
                    px-5
                    text-brown
                    hover:text-white
                    bg-secondary
                    hover:bg-blue-900 hover:bg-opacity-70
                    transition
                    duration-0
                    focus:outline-none
                  "
                >
                  Select {{ qtySelected }}
                </button>
              </div>
            </slot>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
const axios = require("axios").default;
export default {
  name: "SelectNFT",
  components: {},
  props: {
    text: String,
    title: String,
    button: String,
    mainAsset: Boolean,
    swap: Boolean,
    in: Boolean,
  },
  data: function () {
    return {
      nfts: [],
      checkedNfts: [],
      erc1155values: {},
      erc1155Availablevalues: {},
      search: "",
    };
  },
  methods: {
    select: async function () {
      if (this.project.type == 721) {
        let qty = [];
        console.log("Before");

        const toNumbers = (arr) => arr.map(Number);
        if (this.project.factoryVersion == 1) {
          this.checkedNfts = [this.checkedNfts];
        }

        for (var index in this.checkedNfts) {
          console.log(qty, this.checkedNfts);
          qty.push(1);
        }

        if (this.swap) {
          if (this.in) {
            this.$store.commit("setSelectedNftsIn", [this.checkedNfts, 1]);
          } else {
            this.$store.commit("setSelectedNftsOut", [this.checkedNfts, 1]);
          }
          this.$store.commit("setSelectedNfts", [this.checkedNfts, [1]]);
        } else {
          if (this.mainAsset && this.project.type == 721) {
            if (this.project.factoryVersion == 1) {
              this.$store.commit("setSelectedNfts", [[this.checkedNfts], [1]]);
            } else {
              this.$store.commit("setSelectedNfts", [this.checkedNfts, [1]]);
            }
          } else {
            // alert(this.checkedNfts);
            // console.log("adasdasd ", this.checkedNfts);
            this.$store.commit("setSelectedNfts", [this.checkedNfts, qty]);
          }
        }
      } else if (this.project.type == 1155) {
        var ids = [],
          qty = [];
        for (var property in this.erc1155values) {
          if (!this.erc1155values.hasOwnProperty(property)) {
            continue;
          }
          if (this.erc1155values[property] > 0) {
            ids.push(property);
            qty.push(parseInt(this.erc1155values[property]));
          }
        }
        if (this.swap) {
          if (this.in) {
            this.$store.commit("setSelectedNftsIn", [ids, qty]);
          } else {
            this.$store.commit("setSelectedNftsOut", [ids, qty]);
          }
          this.$store.commit("setSelectedNfts", [ids, qty]);
        } else {
          this.$store.commit("setSelectedNfts", [ids, qty]);
        }
      }
      this.$store.commit("setDidSelectNfts", true);
      this.$emit("close");
    },
    move: async function (what, key, max) {
      if (what == "plus") {
        if (this.erc1155values[key] < max) {
          this.erc1155values[key]++;
        }
      } else {
        if (this.erc1155values[key] > 0) {
          this.erc1155values[key]--;
        }
      }
    },
    pupulateNfts: async function () {
      if (this.in) {
        // if main asset is true it menas he is depositing nfts
        const account = this.$store.state.account;

        console.log("DEPOSITINGGG");
        let test_addy = "0xc3b5284b2c0cfa1871a6ac63b6d6ee43c08bdc79";
        // this.$store.state.account
        let { data } = await axios.get(
          `https://eth-mainnet.g.alchemy.com/nft/v3/7o_A2UJVRVUs9JrNiN5Hnz0u-8QTItj_/getNFTsForOwner?owner=${account}&contractAddresses[]=${this.project.nftAddress}&withMetadata=true&pageSize=100`
        );
        console.log(data);
        this.nfts = data.ownedNfts;
        for (let index = 0; index < this.nfts.length; index++) {
          this.nfts[index].token_id = this.nfts[index].tokenId
          this.nfts[index].image_thumbnail_url = this.nfts[index].image.thumbnailUrl
          this.nfts[index].asset_contract = {}
          this.nfts[index].asset_contract.address = this.nfts[index].contract.address
          this.nfts[index].asset_contract.schema_name = this.nfts[index].contract.tokenType

        }
      
        if (this.project.type == 1155) {
          let contract = await this.$store.state.ERC1155(
            this.project.nftAddress
          );
          let ids = [];
          let addresses = [];
          for (const nft of this.nfts) {
            addresses.push(account);
            ids.push(nft.token_id);
          }
          let res = await contract.methods
            .balanceOfBatch(addresses, ids)
            .call({ from: this.$store.state.account });
          for (let index = 0; index < res.length; index++) {
            this.erc1155Availablevalues[ids[index]] = parseInt(res[index]);
          }
        }
      } else if (!this.in) {
        // here if user is depositing token (mainAsset = false)
        let ns = await axios.get(
          "https://api.nft20.io/nfts?perPage=50000&page=1&pool=" +
            this.project.address
        );
        let nfts = [];
        for (const n of ns.data.data) {
          nfts.push({
            image_thumbnail_url: n.nft_image,
            token_id: n.nft_id,
            quantity: n.available_quantity,
          });
        }
        this.nfts = nfts;
        if (this.project.type == 1155) {
          let contract = await this.$store.state.ERC1155(
            this.project.nftAddress
          );
          let ids = [];
          let addresses = [];
          for (const nft of this.nfts) {
            addresses.push(this.project.address);
            ids.push(nft.token_id);
          }
          let res = await contract.methods
            .balanceOfBatch(addresses, ids)
            .call({ from: this.$store.state.account });
          for (let index = 0; index < res.length; index++) {
            this.erc1155Availablevalues[ids[index]] = parseInt(res[index]);
          }
        }
      }
    },
  },
  beforeMount: async function () {},
  mounted: async function () {
    await this.pupulateNfts();
    const erc1155values = {};
    this.nfts.forEach((nft) => {
      erc1155values[nft.token_id] = 0;
    });
    this.erc1155values = erc1155values;
  },
  watch: {},
  computed: {
    project: function () {
      // for testing
      // this.$store.state.currentProject.type = 1155;
      return this.$store.state.currentProject;
    },
    qtySelected: function () {
      if (this.checkedNfts.length == 0) {
        return;
      }
      // else {
      // return this.checkedNfts.length;
      // }
    },
    getErc1155Values() {
      return Object.keys(this.erc1155values).reduce((acc, itemKey) => {
        let row = [itemKey, this.erc1155values[itemKey]];
        acc.push(row);
        return acc;
      }, []);
    },
    filteredNfts() {
      return this.nfts.filter((asset) => {
        return asset.token_id.toLowerCase().includes(this.search.toLowerCase());
      });
    },
  },
  created: async function () {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
::placeholder {
  /* padding: 0px 1rem; */
  /* maybe remove this */
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Hide the browser's default checkbox */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
}
/* On mouse-over, add a grey background color */
.container:hover input ~ .checkmark {
  background-color: #ccc;
}
/* When the checkbox is checked, add a blue background */
.container input:checked ~ .checkmark {
  background-color: #2196f3;
}
/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}
/* Show the checkmark when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}
/* Style the checkmark/indicator */
.container .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.input {
  background-color: #afaca2;
  cursor: pointer;
}
/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
  background-color: #afaca2;
  color: white;
  border-radius: 6px;
  text-align: center;
}
input[type="number"]:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
}
.nfts {
  max-height: calc(340px);
}
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}
.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}
.modal-container {
  /* max-height: calc(100vh - 80px); */
  /* overflow-y: hidden; */
  width: 30%;
  margin: 0px auto;
  padding: 30px 30px;
  /* background-color: #fff; */
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
}
.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}
.modal-body {
  margin: 20px 0;
}
.modal-default-button {
  float: right;
}
@media (max-width: 768px) {
  .modal-container {
    /* max-height: calc(100vh - 80px); */
    /* overflow-y: auto; */
    width: 80%;
  }
}
</style>