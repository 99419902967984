<template>
  <span class="address"
    ><a
      target="_blank"
      :href="link"
      class="text-white lg:text-white uppercase font-primary font-bold leading-none text-base block rounded-full py-3 lg:py-5 lg:px-8 hover:text-yellow-300 transition duration-300"
      >{{ display }}</a
    ></span
  >
</template>

<script>
export default {
  name: "Address",
  components: {},
  props: {
    address: String,
  },
  data: function () {
    return {};
  },
  methods: {},
  beforeMount: function () {},
  watch: {},
  computed: {
    display: function () {
      if (this.address == null) {
        return "";
      }
      return this.address.substring(0, 8) + "...";
    },
    link: function () {
      if (this.address == null) {
        return "";
      }
      return "https://etherscan.io/address/" + this.address;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>