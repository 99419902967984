<template>
  <div
    class="
      w-11/12
      lg:w-3/4
      rounded-3xl
      p-6
      bg-tertiary
      py-8
      text-grey-900
      uppercase
      text-xl
      font-bold
    "
    :class="{ 'not-expanded': !this.expanded }"
  >
    <div class="flex justify-between items-center">
      <div class="flex space-x-0 items-center justify-between w-full">
        <h2
          class="
            flex
            text-grey-900
            items-center
            uppercase
            text-xl
            font-bold
            w-full
            md:w-5/12
          "
        >
          <i
            class="
              bg-grey-500
              rounded-full
              bg-white bg-opacity-30
              h-16
              w-16
              mr-4
              flex
              items-center
              justify-center
              shadow-sm
            "
          >
            <img
              class="rounded-full object-cover"
              src="@/assets/images/logos/muse.svg"
              alt=""
            />
          </i>
          <span>MUSE/ETH LP</span>
        </h2>
        <div class="hidden md:flex flex-col items-start pl-4 w-4/12">
          <span class="text-gray-500 text-xs">Your wallet:</span>
          <span v-if="account">
            <TokenBalance
              :erc20="UniEthMuseToken"
              :account="account"
              symbol="$ULP"
              decimals="18"
          /></span>
          <span v-else> 0</span>
        </div>
        <div class="hidden md:flex flex-col items-start pl-4 w-5/12">
          <span class="text-gray-500 text-xs">Total Staked in $MUSE-ETH:</span>
          <div>
            $18000
            <span>MUSE-ETH</span>
          </div>
        </div>
      </div>
      <div
        class="cursor-pointer"
        @click="toggleCardState()"
        :class="{ chevron: this.expanded }"
      >
        <svg
          class="w-6 h-6"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M19 9l-7 7-7-7"
          ></path>
        </svg>
      </div>
    </div>

    <div class="flex flex-col py-10 card-content">
      <div class="content">
        <slot>
          <div class="flex mb-4 md:hidden flex-col items-start">
            <span class="text-gray-500 text-xs">Your wallet::</span>
            <span v-if="account">
              <TokenBalance
                :erc20="UniEthMuseToken"
                :account="account"
                symbol="$ULP"
                decimals="18"
            /></span>
            <span v-else> 0 </span>
          </div>
          <div class="flex md:hidden flex-col items-start mb-8">
            <span class="text-gray-500 text-xs">Total Value Locked:</span>
            <span>$18000 MUSE-ETH</span>
          </div>
          <div
            class="
              flex
              md:flex-row
              flex-col
              justify-center
              md:space-x-24
              space-y-10
              md:space-y-0
            "
          >
            <!-- <div class="flex flex-col items-start space-y-4">
              <div>
                <span class="text-gray-500 text-xs">You Staked:</span>
              </div>
              <div
                style="border-color: #b2aea8"
                class="border rounded-lg pt-4 pb-3 px-5 text-2xl w-full text-jet font-light bg-transparent focus:outline-none"
                placeholder="0"
                type="text"
              >
                1000 $LP
              </div>
            </div> -->
            <div class="flex flex-col items-start space-y-4">
              <div>
                <span class="text-gray-500 text-xs">Earned points:</span>
              </div>
              <div
                style="border-color: #b2aea8"
                class="
                  border
                  rounded-lg
                  pt-4
                  pb-3
                  px-5
                  text-2xl
                  w-full
                  text-jet
                  font-light
                  bg-transparent
                  focus:outline-none
                "
                placeholder="0"
                type="text"
              >
                <Number decimals="18" :number="pendingPoints + ''" />
              </div>
              <button
                @click="withdraw"
                class="
                  text-center
                  uppercase
                  font-primary font-bold
                  leading-none
                  text-sm
                  inline-block
                  rounded-lg
                  py-4
                  px-5
                  text-brown
                  hover:text-white
                  bg-secondary
                  hover:bg-blue-900 hover:bg-opacity-70
                  transition
                  duration-0
                  focus:outline-none
                "
              >
                Withdraw Stake
              </button>
            </div>
          </div>
        </slot>
      </div>
    </div>
  </div>
</template>

<script>
import TokenBalance from "@/components/TokenBalance.vue";
import Number from "@/components/Number.vue";

export default {
  name: "FarmCard",
  components: { TokenBalance, Number },
  props: {
    expandAll: Boolean,
    // img: String,
    // timeLeft: String,
    // price: String,
  },
  data() {
    return {
      expanded: false,
      hasTokenApproval: false,
      staked: 0,
      pendingPoints: 0,
      userLpBalance: -1,
    };
  },
  methods: {
    toggleCardState: async function () {
      this.expanded = !this.expanded;
    },
    // registerStake: async function () {
    //   let minetx = await this.$store.state.DuckChef.methods
    //     .register(0, 1)
    //     .send({
    //       from: this.$store.state.account,
    //     });
    // },
    // withdraw: async function () {
    //   let minetx = await this.$store.state.DuckChef.methods
    //     .wtihdraw(0, 1)
    //     .send({
    //       from: this.$store.state.account,
    //     });
    // },
    init: async function () {
      // console.log(infos);
      if (this.userLpBalance != -1 || this.$store.state.pairCount == 0) {
        return;
      }
      this.hasTokenApproval =
        (
          await this.$store.state.UniEthMuseContract.methods
            .allowance(
              this.$store.state.account,
              this.$store.state.contracts.MasterChef
            )
            .call()
        ).length >= 18;

      const staked = await this.$store.state.MasterChef.methods
        .userInfo(0, this.$store.state.account)
        .call();
      this.staked = staked.amount;
      this.pendingPoints = await this.$store.state.MasterChef.methods
        .pendingPoints(0, this.$store.state.account)
        .call();

      const userLpBalance = await this.$store.state.UniEthMuseContract.methods
        .balanceOf(this.$store.state.account)
        .call();
      this.userLpBalance = userLpBalance;
      // alert(this.userLpBalance)
    },
    withdraw: async function () {
      alert(
        "If you withdraw your points will reset to 0 but you'll get reward in few days."
      );
      try {
        if (!this.hasTokenApproval) {
          await this.approve();
        }
        let minetx = await this.$store.state.MasterChef.methods
          .withdraw(0)
          .send({
            from: this.$store.state.account,
          })
          .on("transactionHash", (hash) => {
            console.log("hash of tx ", hash);
            // pass the hash to notify to track it
            this.$store.state.notify.hash(hash);
          });
      } catch (e) {
        console.log(e);
      }
    },
    stake: async function () {
      try {
        if (!this.hasTokenApproval) {
          await this.approve();
        }
        const userLpBalance = await this.$store.state.UniEthMuseContract.methods
          .balanceOf(this.$store.state.account)
          .call();
        this.userLpBalance = userLpBalance;
        let minetx = await this.$store.state.MasterChef.methods
          .deposit(0, this.userLpBalance)
          .send({
            from: this.$store.state.account,
          })
          .on("transactionHash", (hash) => {
            console.log("hash of tx ", hash);
            // pass the hash to notify to track it
            this.$store.state.notify.hash(hash);
          });
      } catch (e) {
        console.log(e);
      }
    },
    approve: async function () {
      let max =
        "115792089237316195423570985008687907853269984665640564039457584007913129639935";
      let remove = "0";
      let approve = await this.$store.state.UniEthMuseContract.methods
        .approve(this.$store.state.contracts.MasterChef, max)
        .send({
          from: this.$store.state.account,
        })
        .on("transactionHash", (hash) => {
          console.log("hash of tx ", hash);
          // pass the hash to notify to track it
          this.$store.state.notify.hash(hash);
        });

      this.init();
    },
  },
  mounted: async function () {
    this.init();
  },
  watch: {
    isConnected: function () {
      this.init();
    },
  },
  computed: {
    UniEthMuseToken: function () {
      return this.$store.state.contracts.UniEthMuse;
    },
    account: function () {
      return this.$store.state.account;
    },
    isConnected: function () {
      return this.$store.state.pairCount > 0;
    },
  },
};
</script>

<style scoped>
.not-expanded .card-content {
  transform: scaleY(0);
  transform-origin: top;
  opacity: 0;
  height: 0;
  padding: 0;
}
.card-content {
  transition: all 150ms ease;
}

.chevron {
  transform: rotate(180deg);
  transition: transform 150ms ease-out;
}
</style>