<template>
  <div class="mt-20 text-white" id="nfts">
        <div><PepeScore :show="pepe" :project="project" /></div>

    <div
      class="
        flex
        md:justify-between
        items-center
        flex-col
        md:flex-row
        justify-center
      "
    >
      <h2
        class="
          flex
          text-white
          items-center
          uppercase
          text-3xl
          font-bold
          px-6
          md:px-0
          mb-8
          md:mb-0
        "
      >
        <i
          class="
            bg-blue-500
            rounded-full
            bg-white
            h-16
            w-16
            mr-4
            flex
            items-center
            justify-center
            shadow-sm
          "
        >
          <img
            class="rounded-full object-cover"
            v-if="project.logo"
            :src="project.logo"
            alt
          />
        </i>
        <span v-if="pool.lpToken && !sell">NFTs Available to Purchase</span>
        <span v-if="pool.lpToken && sell">NFTs In Your Wallet</span>

        <span v-if="!pool.lpToken">NFTs in the Pool</span>
      </h2>

      <div>
        <div class="switch-button text-sm text-gray-900" @click="toggleAction">
          <input class="switch-button-checkbox" type="checkbox" />
          <label class="switch-button-label" for="">
            <span class="switch-button-label-span">Buy From Pool</span>
          </label>
        </div>
      </div>
    </div>
    <div>
        <div class="text-xl font-bold mt-4 cursor-pointer" v-on:click="toggleFilter">Filter</div>
<div class="
        rounded-lg
        bg-gray-800
        py-4
        px-4
        opacity-100" v-if="filter">
  <div class="mb-3" v-for="(value, type) in traits" :key="type">
    <div class="font-semibold ">{{type}}:</div>
    <span class="" v-for="(trait_value, trait_type) in traits[type]" :key="trait_type">
    <input type="checkbox" class="cursor-pointer" :id="type + '____D____' + trait_type" :value="type + '____D____' + trait_type" v-model="filteredTraits">
    <label class="ml-1 mr-4 cursor-pointer" :for="type + '____D____' + trait_type">{{trait_type}} ({{trait_value}})</label>
    </span>
  </div>
</div>
    </div>

    <div>
      <div v-if="filteredNFTList.length < 1 && !sell" class="text-2xl text-center text-white mt-10">
        NO NFTs in the Pool yet
      </div>
        <div v-if="filteredNFTList.length < 1 && sell && isConnected" class="text-2xl text-center text-white mt-10">
        NO NFTs in your wallet
      </div>
      <div v-if="sell && !isConnected" class="text-2xl text-center text-white mt-10">
        <div>Please connect your wallet</div>
      </div>
      <div
        v-if="filteredNFTList.length >= 1 && (!sell || (sell && isConnected))"
        class="
          text-grey-900
          mt-16
          grid grid-cols-1
          md:px-0
          px-12
          md:grid-cols-4
          gap-4
        "
      >
        <div  
          class="bg-opacity-0 bg-tertiary rounded-lg p-1"
          v-for="nft in filteredNFTList"
          :key="nft.nft_id"
          
        >
          <GalleryItem :nft="nft" :pool="pool" :sell="sell" />
        </div>
      </div>
    </div>
    </div>
  </div>
</template>


<script>
const axios = require("axios").default;
import { mapGetters } from "vuex";
import GalleryItem from "@/components/GalleryItem.vue";
import PepeScore from "@/components/PepeScore.vue";
export default {
  name: "GalleryView",
  components: { GalleryItem, PepeScore },
  props: {
    pool: Object,
  },
  computed: {},
  data: function () {
    return {
      nfts: [],
      sell: false,
      // showCheckout: false,
      pepe: true,
      traits: {},
      filteredTraits: [],
      filter: false,
    };
  },
  beforeMount: async function () {
    this.getNfts();
  },
  methods: {
    toggleFilter: function () {
      this.filter = !this.filter;
      this.filteredTraits = [];
    },
    toggleAction: async function () {
      this.sell = !this.sell;
    },
    getNfts: async function () {
      let traits = {};
      this.traits = {};
      this.filteredTraits = [];
      if (!this.sell) {
        let nfts = await axios.get(
          "https://api.nft20.io/nfts?perPage=500000&page=1&pool=" +
            this.pool.address
        );
        this.nfts = nfts.data.data;
        console.log(this.nfts);
      } else if (this.sell && this.isConnected) {
        console.log("Load from wallet");
        let NFTS = [];
        let offset = 0;
        let fetching = true;

         const account = this.$store.state.account;

        console.log("DEPOSITINGGG");
        let test_addy = "0xc3b5284b2c0cfa1871a6ac63b6d6ee43c08bdc79";
        // this.$store.state.account
        let { data } = await axios.get(
          `https://api.opensea.io/api/v1/assets?owner=${account}&asset_contract_address=${this.pool.nftAddress}`
        );
        NFTS = data.assets;
        let n = data.next
        while (n != null) {
          
          let additional = await axios.get(
            `https://api.opensea.io/api/v1/assets?owner=${account}&asset_contract_address=${this.pool.nftAddress}&cursor=${n}`
          );
          additional = additional.data
          console.log(additional)
          n = additional.next
          NFTS = NFTS.concat(additional.assets)
        }


        for (const nft of NFTS) {
          nft.nft_contract = nft.asset_contract.address;
          nft.nft_image = nft.image_preview_url;
          nft.nft_id = nft.token_id;
          nft.nft_trait = nft.traits;
        }
        console.log(NFTS);
        this.nfts = NFTS;
      } else {
        this.nfts = [];
      }
      for (const nft of this.nfts) {
        if (nft.nft_trait != null) {
          for (const trait of nft.nft_trait) {
            if (!traits[trait.trait_type]) {
              traits[trait.trait_type] = {};
            }
            if (!traits[trait.trait_type][trait.value]) {
              traits[trait.trait_type][trait.value] = 0;
            }
            traits[trait.trait_type][trait.value] += 1;
          }
        }
      }
      this.traits = traits;
      console.log(this.traits);
    },
    setData: async function () {
      // here we set the empty object of withdraw
      const withdrawData = {
        schema: this.pool.type,
        action: "withdraw",
        nft_address: this.pool.nftAddress,
        pool_address: this.pool.address,
        poolObject: this.pool,
        nfts: {
          ids: [],
          qty: [],
        },
        nfts_raw: [],
        totalQty: 0,
      };
      this.$store.commit("setCasWithdraw", withdrawData);
      const depositData = {
        schema: this.pool.type,
        action: "deposit",
        nft_address: this.pool.nftAddress,
        pool_address: this.pool.address,
        poolObject: this.pool,
        nfts: {
          ids: [],
          qty: [],
        },
        nfts_raw: [],
        totalQty: 0,
      };
      this.$store.commit("setCasDeposit", depositData);
    },
  },
  mounted: async function () {
    this.setData();
  },
  watch: {
    sell: function (newVal) {
      this.setData();
      this.getNfts();
    },
    isConnected: function () {
      this.setData();
      this.getNfts();
    },
  },
  computed: {
    project: function () {
      return this.$store.state.currentProject;
    },
    isConnected: function () {
      return this.$store.state.account;
    },
    filteredNFTList: function () {
      let hasFilter = false;
      let result = [];

      for (const tr of this.filteredTraits) {
        hasFilter = true;
        const trait_type = tr.split("____D____")[0];
        const trait_value = tr.split("____D____")[1];
        console.log(trait_type, trait_value)
        let toInsert = this.nfts.filter((nft) =>
          nft.nft_trait && nft.nft_trait.find(
            (t) => t.trait_type == trait_type && t.value == trait_value
          )
        );
        for (const i of toInsert) {
          if (!result.find((n) => n.nft_id == i.nft_id)) {
            result.push(i);
          }
        }
        console.log(toInsert);
      }

      console.log("Computed", result);
      /*  console.log(this.filteredTraits)
       */
      if (!hasFilter) {
        return this.nfts;
      } else {
        return result;
      }
    },
  },
};
</script>

<style scoped>
.switch-button {
  background: rgba(255, 255, 255, 0.56);
  border-radius: 30px;
  overflow: hidden;
  width: 240px;
  text-align: center;
  letter-spacing: 1px;
  position: relative;
  padding-right: 120px;
  position: relative;
}
.switch-button:before {
  content: "Sell From Wallet";
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  width: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 3;
  pointer-events: none;
}
.switch-button-checkbox {
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 2;
}
.switch-button-checkbox:checked + .switch-button-label:before {
  transform: translateX(120px);
  transition: transform 300ms linear;
}
.switch-button-checkbox + .switch-button-label {
  position: relative;
  padding: 15px 0;
  display: block;
  user-select: none;
  pointer-events: none;
}
.switch-button-checkbox + .switch-button-label:before {
  content: "";
  background: #fff;
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  border-radius: 30px;
  transform: translateX(0);
  transition: transform 300ms;
}
.switch-button-checkbox + .switch-button-label .switch-button-label-span {
  position: relative;
}
</style>